import React, { useState } from 'react';
import { HiOutlineXCircle } from 'react-icons/hi'; // Importing a close icon from react-icons
import Logo from '../cfcp_dashboard/images/Avtar.svg'
import CancelIcon from '@mui/icons-material/Cancel';


function ConsultantProvider() {
    const [info, setInfo] = useState(false)

    const handleInfo = () => {
        setInfo(!info)
    }

    const heading = [
        { text: "Order" },
        { text: "Name" },
        { text: "ID" },
        { text: "Status" },
        { text: "Pending Requests" },
        { text: "Request" },
        { text: "Info" },
    ];

    const data = [
        { Order: "1", name: "Sachin", ID: "1D01", Status: "Active", PendingRequest: "01", Request: "x", info: "details", },
        { Order: "2", name: "Dravid", ID: "1D02", Status: "Active", PendingRequest: "01", Request: "x", info: "details", },
        { Order: "3", name: "Dhoni", ID: "1D03", Status: "Active", PendingRequest: "01", Request: "x", info: "details", },
        { Order: "4", name: "Kumble", ID: "1D04", Status: "Active", PendingRequest: "01", Request: "x", info: "details", },
        { Order: "5", name: "Zaheer", ID: "1D05", Status: "Active", PendingRequest: "01", Request: "x", info: "details", },
        { Order: "6", name: "Yuvraj", ID: "1D06", Status: "Active", PendingRequest: "01", Request: "x", info: "details", },
        { Order: "7", name: "Rohit", ID: "1D06", Status: "Active", PendingRequest: "01", Request: "x", info: "details", },
        { Order: "8", name: "Kohli", ID: "1D06", Status: "Active", PendingRequest: "01", Request: "x", info: "details", },

    ];

    const statusOptions = ["Active", "Inactive", "Pending"];

    return (
        <div className={`md:max-h-full h-full max-w-64 md:max-w-full  mt-10 p-4 flex flex-col relative ${info ? 'bg-gray-300 bg-opacity-20 backdrop-blur-md' : 'bg-gray-300'}`}>
            {/* Heading */}
            <h1 className='font-display   text-gray-700 font-bold text-xl underline decoration-1 decoration-gray-500 mb-4'>
                Consultant Provider
            </h1>


            {/* Main card */}
            <div className={`bg-white  rounded-md shadow-md p-4  w-full max-w-2xl ${info ? 'opacity-15 pointer-events-none' : 'opacity-100'}`}>
                <div className='flex bg-gray-200 p-4 rounded-md mb-4'>
                    <div className='flex flex-col m-2 flex-1'>
                        <label htmlFor="search" className='font-semibold mb-1'>Search</label>
                        <input
                            type="text"
                            id="search"
                            placeholder='Search by Name...'
                            className='rounded-md h-9 w-full max-w-xs text-center border border-gray-400'
                        />
                    </div>
                    <div className='flex flex-col m-2 flex-1'>
                        <label htmlFor="status" className='font-semibold mb-1'>Status</label>
                        <select
                            id="status"
                            className='rounded-md h-9 w-full max-w-xs text-center border border-gray-400'
                        >
                            {statusOptions.map((status, index) => (
                                <option key={index} value={status}>{status}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className=' overflow-x-auto overflow-y-auto max-h-64'> {/* Adjust max-h-60 as needed */}
                    <table className="border-collapse w-full text-center bg-white">
                        <thead className="bg-gray-100">
                            <tr>
                                {heading.map((item, index) => (
                                    <th key={index} className='p-2'>{item.text}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td className='p-2'>{item.Order}</td>
                                    <td className='p-2'>{item.name}</td>
                                    <td className='p-2'>{item.ID}</td>
                                    <td className='p-2'>{item.Status}</td>
                                    <td className='p-2'>{item.PendingRequest}</td>
                                    <td className='p-2'>
                                        <button className='bg-blue-500 text-white py-1 px-3 rounded'>{item.Request}</button>
                                    </td>
                                    <td className='p-2'>
                                        <button onClick={handleInfo} className="text-blue-500 underline">{item.info}</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>

            {/* Info Card with Cancel Icon */}
            <div className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center ${info ? 'visible' : 'hidden'}`}>
                <div className={`relative max-w-xl bg-white shadow-lg rounded-md p-4 ${window.innerWidth < 768 ? 'max-h-fit p-2' : 'max-h-[90vh]'}`}>
                    {/* Cancel icon */}
                    <button onClick={handleInfo} className="absolute top-2 right-2 text-gray-500 hover:text-red-500">
                        <HiOutlineXCircle size={25} /> {/* Reduced icon size */}
                    </button>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2"> {/* Reduced gap */}
                        {/* Profile Section */}
                        <div className="flex flex-col items-center">
                            <div className={`text-md text-[#574C9D] font-mono font-semibold mb-1 ${window.innerWidth < 768 ? 'text-sm' : ''}`}>Profile</div>
                            <div className="mb-1">
                                <img src={Logo} alt="Profile Logo" className={`w-16 h-16 rounded-full object-cover ${window.innerWidth < 768 ? 'w-12 h-12' : ''}`} /> {/* Reduced logo size */}
                            </div>
                            <div className="text-center">
                                <span className={`block font-semibold text-gray-700 ${window.innerWidth < 768 ? 'text-xs' : 'text-sm'}`}>Dhoomketu</span>
                                <span className={`block text-xs text-gray-500 font-semibold ${window.innerWidth < 768 ? 'text-xs' : 'text-sm'}`}>MBBS</span>
                                <span className={`block text-xs text-gray-500 font-semibold ${window.innerWidth < 768 ? 'text-xs' : 'text-sm'}`}>Apollo Hospital, Kolkata</span>
                                <span className={`block text-xs text-gray-500 font-semibold ${window.innerWidth < 768 ? 'text-xs' : 'text-sm'}`}>WB</span>
                            </div>
                            <div className="mt-1">
                                <span className={`block text-[#574C9D] font-mono font-semibold ${window.innerWidth < 768 ? 'text-sm' : ''}`}>Experience</span>
                                <span className={`block text-xs text-gray-500 font-semibold ${window.innerWidth < 768 ? 'text-xs' : 'text-sm'}`}>20+ years</span>
                            </div>
                        </div>

                        {/* Speciality and Availability Section */}
                        <div className="flex flex-col">
                            <div>
                                <div className={`text-md text-[#574C9D] font-mono font-semibold mb-1 ${window.innerWidth < 768 ? 'text-sm' : ''}`}>Speciality</div>
                                <div className="flex text-xs text-gray-600 space-x-2"> {/* Reduced space */}
                                    <div className='border border-gray-300 rounded-xl bg-gray-300 h-5 w-24 text-center font-semibold hover:bg-green-600 hover:text-white'>Cardiology</div>
                                    <div className='border border-gray-300 rounded-xl bg-gray-300 h-5 w-24 text-center font-semibold hover:bg-green-600 hover:text-white'>Neurology</div>
                                    <div className='border border-gray-300 rounded-xl bg-gray-300 h-5 w-24 text-center font-semibold hover:bg-green-600 hover:text-white'>Psychology</div>
                                </div>
                            </div>

                            <div className="mt-2">
                                <div className={`text-md text-[#574C9D] font-mono font-semibold mb-1 ${window.innerWidth < 768 ? 'text-sm' : ''}`}>Availability</div>
                                <div className="text-xs text-gray-500 grid grid-cols-2 gap-1"> {/* Reduced gap */}
                                    <div className='hover:bg-red-900 hover:border-0 hover:text-white border-2 border-gray-300 rounded-md font-semibold h-6 w-24 text-center flex justify-center items-center m-1'>
                                        Today, 2pm
                                    </div>
                                    <div className='hover:bg-red-900 hover:border-0 hover:text-white border-2 border-gray-300 rounded-md font-semibold h-6 w-24 text-center flex justify-center items-center m-1'>
                                        Today, 4pm
                                    </div>
                                    <div className='hover:bg-red-900 hover:border-0 hover:text-white border-2 border-gray-300 rounded-md font-semibold h-6 w-24 text-center flex justify-center items-center m-1'>
                                        Tomorrow, 10am
                                    </div>
                                    <div className='hover:bg-red-900 hover:border-0 hover:text-white border-2 border-gray-300 rounded-md font-semibold h-6 w-24 text-center flex justify-center items-center m-1'>
                                        Today, 12am
                                    </div>
                                    <div className='hover:bg-red-900 hover:border-0 hover:text-white border-2 border-gray-300 rounded-md font-semibold h-6 w-24 text-center flex justify-center items-center m-1'>
                                        Tomorrow, 6pm
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ConsultantProvider;
