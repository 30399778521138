import React, { useState } from "react"
import RoleSelector from "../EmployeeComponents/RolesPopupState"
import "../../Admin_CSS/EmployeeForm.css"
import uploadIcon from "../../../../assets/Astha web app/upload icon.svg"
import profileIcon from "../../../../assets/Astha web app/Rectangle 24.svg"
import { errorToast, successToast } from "../../../../utils/toastConfig"
import axios from "axios"
import CPAccordion from "../EmployeeComponents/CPAccordion"
import Sidebar from "../../../../layout/Sidebar"
import ZoneAccordion from "../EmployeeComponents/ZoneAccordion"
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../../utils/urlConfig"


// Define the initial state outside of the component
const initialEmployeeState = {
  firstName: "",
  middleName: "",
  lastName: "",
  gender: "",
  dob: "",
  email: "",
  contact: "",
  photo: "",
  address: {
    line1: "",
    line2: "",
    city: "",
    district: "",
    state: "",
    zipcode: "",
  },
  idProof: {
    type: "",
    number: "",
    document: null,
  },
  assignedCPs: [],
  assignedZones: [],
}

const CFCP = () => {
  // State for the employee form
  const [employee, setEmployee] = useState(initialEmployeeState)
  const [profilePicture, setProfilePicture] = useState(profileIcon)
  const [proofDocument, setProofDocument] = useState(null)
  const [assignedCPs, setAssignedCPs] = useState([])
  const [assignedZones, setAssignedZone] = useState([])

  const handleCPSelectionChange = (selectedCPs) => {
    setAssignedCPs(selectedCPs)
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      assignedCPs: selectedCPs,
    }))
  }
  const handleZoneSelectionChange = (selectedZones) => {
    setAssignedZone(selectedZones)
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      assignedZones: selectedZones,
    }))
    console.log(selectedZones)
  }
  // Handle change in input fields
  const handleChange = (e) => {
    const { name: inputName, value } = e.target

    if (inputName.startsWith("address.")) {
      const fieldName = inputName.split(".")[1]
      setEmployee((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [fieldName]: value,
        },
      }))
    } else if (inputName.startsWith("idProof.")) {
      const fieldName = inputName.split(".")[1]
      setEmployee((prev) => ({
        ...prev,
        idProof: {
          ...prev.idProof,
          [fieldName]: value,
        },
      }))
    } else {
      setEmployee((prev) => ({
        ...prev,
        [inputName]: value,
      }))
    }
  }

  // Handle form submission
  const handleProofDocumentChange = (e) => {
    const file = e.target.files[0]
    if (file && file.type === "application/pdf") {
      setProofDocument(file) // Update state with the selected PDF document
      setEmployee((prev) => ({
        ...prev,
        idProof: {
          ...prev.idProof,
          document: file, // Assign file to the proof document
        },
      }))
    } else {
      alert("Please upload a PDF document")
    }
  }

  const uploadFiles = async (files) => {
    const formData = new FormData()

    // Append files to FormData
    if (files.photo) {
      formData.append("photo", files.photo)
    }
    if (files.document) {
      formData.append("document", files.document)
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/api/upload-files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      console.log(response.data)
      return response.data // This should contain the URLs of the uploaded files
    } catch (error) {
      console.error(
        "Error uploading files:",
        error.response?.data || error.message
      )
      throw error
    }
  }

  const token =
  useSelector((state) => state.auth.token) || localStorage.getItem("token");
  const handleSubmit = async (e) => {
    e.preventDefault()

    if (
      !employee.firstName ||
      !employee.lastName ||
      !employee.gender ||
      !employee.address.line1 ||
      !employee.address.city ||
      !employee.address.district ||
      !employee.address.zipcode ||
      !employee.contact ||
      !employee.dob ||
      !employee.email ||
      !employee.photo ||
      !employee.idProof.type ||
      !employee.idProof.document
    ) {
      errorToast("Please Enter the required fields")
      return
    } else {
      const files = {
        photo: employee.photo,
        document: proofDocument,
      }

      try {
        // Step 2: Upload files and get URLs
        const fileResponse = await uploadFiles(files)
        const { photoUrl, documentUrls } = fileResponse

        // Step 3: Prepare and send JSON data with URLs
        const jsonData = {
          ...employee,
          photo: photoUrl, // Assign photoUrl to photo
          idProof: {
            ...employee.idProof,
            document: documentUrls, // Assign documentUrls to proof.document
          },
          assignedCPs: assignedCPs.map((cp) => ({ id: cp })),
          assignedZones: assignedZones.map((zone) => ({ id: zone })),
        }
        console.log(jsonData)

        // Step 4: Send JSON data
        await axios.post(`${BASE_URL}/api/add-cfcp`, jsonData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,

          },
        })

        // Step 5: Reset form and state
        handleReset()

        // Step 6: Show success toast
        successToast("Form Submitted Successfully!")
      } catch (error) {
        errorToast("An error occurred while submitting the form.")
        console.error("Error:", error.response?.data || error.message)
      }
    }
  }

  const handleReset = () => {
    setEmployee(initialEmployeeState)
    setProfilePicture(profileIcon)
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        setProfilePicture(reader.result)
        setEmployee((prev) => ({
          ...prev,
          photo: file,
        }))
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <>
      <div>
        <Sidebar />
      </div>
      <div
        className="containers"
        style={{ margin: "0 18% 5%" }}
      >
        <h1 className="ah1">
          Add CF-CP
          <RoleSelector />
        </h1>

        <form
          className="consultant-form"
          onSubmit={handleSubmit}
        >
          <div
            className="profile-section"
            style={{ gridTemplateColumns: "13em 14em 12em 8em" }}
          >
            <div id="profile">
              <h2>Profile</h2>
            </div>
            <div className="form-group">
              <label htmlFor="first-name">First Name</label>
              <input
                type="text"
                id="first-name"
                name="firstName"
                value={employee.firstName}
                onChange={handleChange}
                placeholder="First Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="middle-name">Middle Name</label>
              <input
                type="text"
                id="middle-name"
                name="middleName"
                value={employee.middleName}
                onChange={handleChange}
                placeholder="Middle Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="last-name">Last Name</label>
              <input
                type="text"
                id="last-name"
                name="lastName"
                value={employee.lastName}
                onChange={handleChange}
                placeholder="Last Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="gender">Gender</label>
              <select
                id="gender"
                name="gender"
                value={employee.gender}
                onChange={handleChange}
              >
                <option value="">Select</option>
                <option value="M">Male</option>
                <option value="F">Female</option>
                <option value="Others">Other</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="dob">Date of Birth</label>
              <input
                type="date"
                id="dob"
                name="dob"
                value={employee.dob}
                onChange={handleChange}
                placeholder="DD-MM-YYYY"
              />
            </div>
            <div className="form-group">
              <label htmlFor="proof-type">ID Proof</label>
              <div>
                <select
                  id="proof-type1"
                  name="idProof.type"
                  value={employee.idProof.type}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="1">Aadhar</option>
                  <option value="2">ABHA</option>
                  <option value="3">Voter Id</option>
                  <option value="4">Driving License</option>
                  <option value="5">Ration Card</option>
                  <option value="6">Drug License</option>
                  <option value="7">Nabl License</option>
                </select>
                <span id="span">
                  <img
                    type="file"
                    style={{
                      filter: proofDocument
                        ? "invert(36%) sepia(76%) saturate(2696%) hue-rotate(81deg) brightness(95%) contrast(92%)"
                        : "none",
                      cursor: "pointer",
                    }}
                    name="idProof.document"
                    value="idProof.document"
                    src={uploadIcon}
                    alt=""
                    onClick={() =>
                      document.getElementById("proof-document").click()
                    }
                  />
                  <input
                    type="file"
                    style={{ display: "none" }}
                    id="proof-document"
                    accept="application/pdf"
                    onChange={handleProofDocumentChange} // Updated to handle PDF uploads
                  />
                </span>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={employee.email}
                onChange={handleChange}
                placeholder="abc@xyz.com"
              />
            </div>
            <div className="form-group">
              <label htmlFor="personal-mobile">Contact</label>
              <input
                type="text"
                id="personal-mobile"
                name="contact"
                value={employee.contact}
                onChange={handleChange}
                placeholder="Mobile no."
              />
            </div>
            <div id="profile-picture">
              <div>
                <img
                  src={profilePicture || uploadIcon}
                  alt="Profile"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    document.getElementById("profile-picture-input").click()
                  }
                />
                <span>Profile Picture</span>
                <input
                  type="file"
                  id="profile-picture-input"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>

          <div className="contact-address-section">
            <div id="contact-address">
              <h2>Contact Address</h2>
            </div>
            <div className="form-group">
              <label htmlFor="address-line1">Address Line 1</label>
              <input
                type="text"
                id="address-line1"
                name="address.line1"
                value={employee.address.line1}
                onChange={handleChange}
                placeholder="Address Line 1"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-line2">Address Line 2</label>
              <input
                type="text"
                id="address-line2"
                name="address.line2"
                value={employee.address.line2}
                onChange={handleChange}
                placeholder="Address Line 2"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-city">City</label>
              <input
                type="text"
                id="address-city"
                name="address.city"
                value={employee.address.city}
                onChange={handleChange}
                placeholder="City"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-district">District</label>
              <input
                type="text"
                id="address-district"
                name="address.district"
                value={employee.address.district}
                onChange={handleChange}
                placeholder="District"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-state">State</label>
              <input
                type="text"
                id="address-state"
                name="address.state"
                value={employee.address.state}
                onChange={handleChange}
                placeholder="State"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-zipcode">Zipcode</label>
              <input
                type="text"
                id="address-zipcode"
                name="address.zipcode"
                value={employee.address.zipcode}
                onChange={handleChange}
                placeholder="Zipcode"
              />
            </div>
          </div>

          <div
            className="consultant-details-section"
            id="accordion-container"
          >
            <div className="accordion-div1">
              <CPAccordion onCPSelectionChange={handleCPSelectionChange} />
            </div>
            <div className="accordion-div2">
              <ZoneAccordion
                onZoneSelectionChange={handleZoneSelectionChange}
              />
            </div>
          </div>

          <div className="form-actions">
            <button
              type="submit"
              className="save-btn"
            >
              Save
            </button>
            <button
              type="button"
              className="cancel-btn"
              onClick={handleReset}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default CFCP