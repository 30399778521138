import { useRef, useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import SearchIcon from "@mui/icons-material/Search";
import { useInView } from "react-intersection-observer";
import CrossIcon from "@mui/icons-material/Close";
import axios from "axios";
import { errorToast } from "../../../utils/toastConfig";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/urlConfig"

const Existing = ({
  setShowexisting,
  setExistingPatients,
  setShowExistingPatientList,
}) => {
  const [select, setSelect] = useState("name");
  const [search, setSearch] = useState();

  // const existingPatient = useRef(null);

  // const handleExisting = (event) => {
  //   if (
  //     existingPatient.current &&
  //     !existingPatient.current.contains(event.target)
  //   ) {
  //     setShowexisting(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleExisting);
  //   return () => {
  //     document.removeEventListener("mousedown", handleExisting);
  //   };
  // }, []);
  // const setCombinedRefs = (node) => {
  //   // ref(node);
  //   existingPatient.current = node;
  // };
  const token =
  useSelector((state) => state.auth.token) || localStorage.getItem("token");

  const handleSearch = () => {
    if (select && search) {
      console.log(select);
      console.log(search);
      axios
        .get(`${BASE_URL}/api/patient?${select}=${search}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `${token}`,
  
            },
          }
        )
        .then((res) => {
          setExistingPatients(res);
          console.log(res);
          setShowexisting(false);
          setShowExistingPatientList(true);
        })
        .catch((error) => {
          errorToast("An error occurred while finding the user!.");

          console.log(error);
        });
    } else {
      errorToast("Please Enter the required fields");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <>
      <div
        
        className="w-[20rem] left-10 sm:w-[28rem] sm:left-24 sm:top-28 md:w-[26rem] lg:w-[44rem] lg:left-80 xl:left-[450px] md:top-32 md:left-80 xl:w-[50rem] absolute top-48   motion-box h-[26rem] bg-[#ECECEC] mx-auto rounded-xl"
      >
        <CrossIcon
          className="absolute top-1 right-1 cursor-pointer"
          onClick={() => setShowexisting(false)}
        />
        <div
          className="w-[16rem] md:w-[23rem] sm:w-[22rem] lg:w-[41rem]  xl:w-[46rem] bg-white h-[22rem] mx-auto relative top-8 rounded-2xl shadow-xl
       "
        >
          <input
            type="text"
            placeholder="Search..."
            className="shadow-xl font-mono text-lg px-12 w-full h-[2.8rem] underline decoration-1 decoration-gray-500 rounded-3xl bg-white"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <SearchIcon
            className="absolute left-3 top-6 transform -translate-y-1/2 text-gray-500 
          "
          />

          <select
            name="patient"
            id="patient"
            className="w-[4rem] text-[8px] px-1 cursor-pointer  md:w-[120px] md:text-[15px]  md:text-nowrap absolute  right-4 h-9 top-1 rounded-full text-white underline bg-[#0E044A]"
            value={select}
            onChange={(e) => setSelect(e.target.value)}
          >
            <option value="name" className="">
              Patient Name
            </option>
            <option value="patientId" className="">
              Patient ID
            </option>
            <option value="mobile" className="">
              Phone No
            </option>
            <option value="id" className="">
              Consultant ID
            </option>
          </select>
        </div>
      </div>
    </>
  );
};

export default Existing;
