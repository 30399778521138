import React, { useState } from 'react';
import PatientIcon from './images/Vector (1).svg';
import StethoscopeIcon from './images/Ellipse 5.svg';
import CpAvailableIcon from './images/2.svg';
import { MdCall, MdVideoCall } from 'react-icons/md'; // Importing icons from react-icons
import Avatar from '../cfcp_dashboard/images/Avtar.svg'
import CancelIcon from '@mui/icons-material/Cancel';
import ConsultantProvider from '../consultantprovider/ConsultantProvider';
import CloseIcon from '@mui/icons-material/Close';


function ConsultationQueue() {
    const [info, setInfo] = useState(false)
    const [show, setShow] = useState(false)

    const handleInfo = () => {
        setInfo(!info)
    }

    const showConsultantProvider = () => {
        setShow(!show);
    }

    const heading = [
        { text: "#" },
        { text: "Parent ID" },
        { text: "Consultation ID" },
        { text: "Documents" },
        { text: "CF Assigned" },
        { text: "CP Assigned" },
        { text: "Consultation Date" },
        { text: "Status" },
        { text: "Info" },
    ];

    const sampleRows = [
        { id: 1, parentId: 'P001', consultationId: 'C001', Documents: 'insert', cfAssigned: 'CF001', cpAssigned: 'CP001', date: '2024-09-19', status: 'Pending', info: 'Details' },
        { id: 2, parentId: 'P002', consultationId: 'C002', Documents: 'insert', cfAssigned: 'CF002', cpAssigned: 'CP002', date: '2024-09-20', status: 'Completed', info: 'Details' },
        { id: 3, parentId: 'P001', consultationId: 'C001', Documents: 'insert', cfAssigned: 'CF001', cpAssigned: 'CP001', date: '2024-09-19', status: 'Pending', info: 'Details' },
        { id: 4, parentId: 'P002', consultationId: 'C002', Documents: 'insert', cfAssigned: 'CF002', cpAssigned: 'CP002', date: '2024-09-20', status: 'Completed', info: 'Details' },
        { id: 5, parentId: 'P001', consultationId: 'C001', Documents: 'insert', cfAssigned: 'CF001', cpAssigned: 'CP001', date: '2024-09-19', status: 'Pending', info: 'Details' },
        { id: 6, parentId: 'P002', consultationId: 'C002', Documents: 'insert', cfAssigned: 'CF002', cpAssigned: 'CP002', date: '2024-09-20', status: 'Completed', info: 'Details' },
        { id: 7, parentId: 'P001', consultationId: 'C001', Documents: 'insert', cfAssigned: 'CF001', cpAssigned: 'CP001', date: '2024-09-19', status: 'Pending', info: 'Details' },
        { id: 8, parentId: 'P002', consultationId: 'C002', Documents: 'insert', cfAssigned: 'CF002', cpAssigned: 'CP002', date: '2024-09-20', status: 'Completed', info: 'Details' },
        { id: 9, parentId: 'P001', consultationId: 'C001', Documents: 'insert', cfAssigned: 'CF001', cpAssigned: 'CP001', date: '2024-09-19', status: 'Pending', info: 'Details' },
        { id: 10, parentId: 'P002', consultationId: 'C002', Documents: 'insert', cfAssigned: 'CF002', cpAssigned: 'CP002', date: '2024-09-20', status: 'Completed', info: 'Details' },
        { id: 11, parentId: 'P001', consultationId: 'C001', Documents: 'insert', cfAssigned: 'CF001', cpAssigned: 'CP001', date: '2024-09-19', status: 'Pending', info: 'Details' },
        { id: 12, parentId: 'P002', consultationId: 'C002', Documents: 'insert', cfAssigned: 'CF002', cpAssigned: 'CP002', date: '2024-09-20', status: 'Completed', info: 'Details' },
        { id: 13, parentId: 'P001', consultationId: 'C001', Documents: 'insert', cfAssigned: 'CF001', cpAssigned: 'CP001', date: '2024-09-19', status: 'Pending', info: 'Details' },
        { id: 14, parentId: 'P002', consultationId: 'C002', Documents: 'insert', cfAssigned: 'CF002', cpAssigned: 'CP002', date: '2024-09-20', status: 'Completed', info: 'Details' },
        { id: 15, parentId: 'P001', consultationId: 'C001', Documents: 'insert', cfAssigned: 'CF001', cpAssigned: 'CP001', date: '2024-09-19', status: 'Pending', info: 'Details' },
        { id: 16, parentId: 'P002', consultationId: 'C002', Documents: 'insert', cfAssigned: 'CF002', cpAssigned: 'CP002', date: '2024-09-20', status: 'Completed', info: 'Details' },

    ];

    return (
        <>
            {/* Main Container */}
            <div className={` pr-4 max-w-screen-xl mx-auto overflow-x-hidden ${info ? 'bg-gray-300 bg-opacity-20 backdrop-blur-md' : 'bg-gray-300'}`}>
                <h1 className="text-[#454545] font-semibold text-lg font-display underline decoration-1 mb-4">
                    Consultation Queue
                </h1>

                {/* Cards Container */}
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mt-8">
                    <div className="bg-gray-100 flex flex-col sm:flex-row items-center justify-center border border-gray-400 h-auto p-4 rounded-xl">
                        <img
                            src={PatientIcon}
                            alt="Patient Icon"
                            className="h-10 w-10 sm:h-12 sm:w-12 mb-2 sm:mb-0 sm:mr-4 flex-shrink-0"
                        />
                        <div className="text-center sm:text-left flex-1 max-w-full break-words">
                            <p className="text-[14px] sm:text-base lg:text-lg font-semibold underline decoration-1 decoration-slate-600 text-[#0E0A4A]">
                                Patients
                            </p>
                            <p className="text-sm sm:text-base lg:text-lg underline decoration-1 decoration-slate-600 text-red-600 font-semibold">
                                143
                            </p>
                        </div>
                    </div>
                    <div className="bg-gray-100 flex flex-col sm:flex-row items-center justify-center border border-gray-400 h-auto p-4 rounded-xl">
                        <img
                            src={StethoscopeIcon}
                            alt="Stethoscope Icon"
                            className="h-10 w-10 sm:h-12 sm:w-12 mb-2 sm:mb-0 sm:mr-4 flex-shrink-0"
                        />
                        <div className="text-center sm:text-left flex-1 max-w-full break-words">
                            <p className="text-[14px] sm:text-base lg:text-lg font-semibold underline decoration-1 decoration-slate-600 text-[#0E0A4A]">
                                Unassigned Patients
                            </p>
                            <p className="text-sm sm:text-base lg:text-lg underline decoration-1 decoration-slate-600 text-red-600 font-semibold">
                                35
                            </p>
                        </div>
                    </div>
                    <div className="bg-gray-100 flex flex-col sm:flex-row items-center justify-center border border-gray-400 h-auto p-4 rounded-xl">
                        <img
                            src={CpAvailableIcon}
                            alt="CP Available Icon"
                            className="h-10 w-10 sm:h-12 sm:w-12 mb-2 sm:mb-0 sm:mr-4 flex-shrink-0"
                        />
                        <div className="text-center sm:text-left flex-1 max-w-full break-words">
                            <p className="text-[14px] sm:text-base lg:text-lg font-semibold underline decoration-1 decoration-slate-600 text-[#0E0A4A]">
                                CP Available
                            </p>
                            <p className="text-sm sm:text-base lg:text-lg underline decoration-1 decoration-slate-600 text-red-600 font-semibold">
                                20
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Button Section */}
            <div className="mt-4 bg-white p-4 max-w-screen-xl mx-auto rounded-lg shadow-lg">
                {/* Button Group and Table in one card */}
                <div className="flex flex-col w-full space-y-4">
                    {/* Button Group */}
                    <div className="flex flex-col sm:flex-row justify-center sm:justify-between space-y-2 sm:space-y-0 sm:space-x-4">
                        {/* Queue Buttons */}
                        <div className="flex flex-row sm:flex-row justify-center sm:justify-start space-y-2 sm:space-y-0 sm:space-x-4 w-full sm:w-auto">
                            <span className="mt-2 mx-2 sm:mt-0 sm:mx-0 h-8 font-semibold font-sans border border-gray-500 px-4 py-1 rounded-lg hover:bg-[#0E044A] hover:text-white text-center whitespace-nowrap cursor-pointer text-xs sm:text-sm">
                                Common Queue
                            </span>
                            <span className="h-8 font-semibold font-sans border border-gray-500 px-4 py-1 rounded-lg hover:bg-[#0E044A] hover:text-white text-center whitespace-nowrap cursor-pointer text-xs sm:text-sm">
                                Assigned Queue
                            </span>
                        </div>

                        {/* Self Assign Button */}
                        <div className="flex justify-center w-full sm:w-auto">
                            <span className="h-8 font-semibold font-sans border border-gray-500 px-4 py-1 rounded-lg hover:bg-[#0E044A] hover:text-white text-center whitespace-nowrap cursor-pointer text-xs sm:text-sm">
                                <button onClick={showConsultantProvider}>+Self Assign</button>
                            </span>
                        </div>
                    </div>


                    {/* Table */}
                    <div className="overflow-x-auto">
                        <div className="min-w-full">
                            <table className="min-w-full border-collapse">
                                <thead>
                                    <tr className="bg-gray-200">
                                        {heading.map((item, index) => (
                                            <th key={index} className="border p-2 text-left font-semibold text-gray-700">
                                                {item.text}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {sampleRows.map((row, index) => (
                                        <tr key={index} className="border-b hover:bg-gray-50">
                                            <td className="border p-2">{row.id}</td>
                                            <td className={`border p-2`} onClick={handleInfo}>{row.parentId}</td>
                                            <td className="border p-2">{row.consultationId}</td>
                                            <td className="border p-2">{row.Documents}</td>
                                            <td className="border p-2">{row.cfAssigned}</td>
                                            <td className="border p-2">{row.cpAssigned}</td>
                                            <td className="border p-2">{row.date}</td>
                                            <td className="border p-2">{row.status}</td>
                                            <td className="border p-2">{row.info}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


            <div className={`fixed inset-0 z-50 p-4 flex items-center justify-center transition-opacity duration-300 ${info ? 'opacity-100 visible' : 'opacity-0 invisible'}`}>
                {/* Background Overlay */}
                <div className="fixed inset-0 bg-black opacity-50" onClick={() => setInfo(false)}></div>

                {/* Card */}
                <div className="relative bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl transition-transform transform duration-500 ease-out scale-100">
                    {/* Cancel Button */}
                    <button className="absolute top-6 right-4 text-[#0E044A]" onClick={() => setInfo(false)}>
                        <CancelIcon />
                    </button>

                    {/* Audio Call Icon below Cancel Icon */}
                    <div className=" absolute bg-[#0E044A] w-8 h-6 top-6 right-16 text-white rounded">
                        <MdCall className='absolute left-2 top-1' />
                    </div>

                    <h2 className="text-lg font-bold mb-4">Patient Details</h2>

                    {/* Patient Description and Consultation Type */}
                    <div className="flex flex-col md:flex-row md:gap-4">
                        <div className="border-2 border-gray-500 bg-gray-100 rounded-xl p-4 flex items-center mb-3 md:mb-0">
                            <img src={Avatar} alt="Patient Avatar" className="h-20 w-20 md:h-24 md:w-24 rounded-full mb-2" />
                            <div className="text-center text-sm md:text-base">
                                <div><span className="font-semibold">Name:</span> <span>Lisa Ann</span></div>
                                <div><span className="font-semibold">Patient ID:</span> <span>2347362</span></div>
                                <div><span className="font-semibold">Mobile No:</span> <span>1720131214</span></div>
                                <p className='md:hidden'><span className="font-semibold">Age:</span> <span>43</span></p>
                                <p className='md:hidden'><span className="font-semibold">Sex:</span> <span>Female</span></p>
                            </div>
                            <div className='hidden md:ml-4 md:block'>
                                <p><span className="font-semibold">Age:</span> <span>43</span></p>
                                <p><span className="font-semibold">Sex:</span> <span>Female</span></p>
                            </div>
                        </div>

                        <div className="flex flex-col justify-center items-center md:relative md:left-12">
                            <p className="font-semibold mb-1">Choose Consultation Type</p>
                            <div className="flex justify-center space-x-1">
                                <button className="rounded-lg border-gray-400 border-2 hover:bg-[#0E044A] hover:text-white hover:border-0 my-1 h-8 w-20 text-xs md:w-24">Instant</button>
                                <button className="rounded-lg border-gray-400 border-2 hover:bg-[#0E044A] hover:text-white hover:border-0 my-1 h-8 w-20 text-xs md:w-24">Schedule</button>
                            </div>
                        </div>
                    </div>

                    {/* Chief Complaint and Supporting Documents */}
                    <div className="mt-4 flex flex-col md:flex-row md:gap-4">
                        <div className="flex flex-col mb-3 w-full md:w-1/2">
                            <label className="font-semibold mb-1 text-sm">Chief Complaint</label>
                            <textarea rows="3" className="border border-gray-400 rounded w-full p-2 resize-none text-sm" placeholder="Enter details..."></textarea>
                        </div>

                        <div className="flex flex-col mb-3 w-full md:w-1/2 relative md:left-28">
                            <div className="font-semibold mb-1 text-sm">
                                Supporting Documents
                                <span className="text-blue-500 cursor-pointer mb-1 ml-2">Upload</span>
                            </div>
                            <p className="text-xs">Document 1</p>
                            <p className="text-xs">Document 2</p>
                            <p className="text-xs">Document 3</p>
                        </div>
                    </div>

                    {/* Audio and Video Call Options */}
                    <div className="mt-4 flex flex-wrap space-x-1 justify-center">
                        <span className="m-2 flex items-center justify-evenly hover:bg-[#0E044A] hover:text-white border-2 hover:border-0 border-gray-700 h-8 w-24 inline-block p-2 rounded-lg mb-1 text-md md:h-10 md:w-28 transition duration-200 ease-in-out hover:bg-opacity-90">
                            <MdCall className="mr-1" />
                            <span className="font-semibold">Audio</span>
                        </span>
                        <span className="m-2 flex items-center justify-evenly hover:bg-[#0E044A] hover:text-white border-2 border-gray-700 hover:border-0 h-8 w-24 inline-block p-2 rounded-lg mb-1 text-md md:h-10 md:w-28 transition duration-200 ease-in-out hover:bg-opacity-90">
                            <MdVideoCall className="mr-1" />
                            <span className="font-semibold">Video</span>
                        </span>
                    </div>
                </div>
            </div>

            {/* Consultatnt Provider */}
            <div className={`fixed  top-20 inset-0 flex justify-center items-center bg-black bg-opacity-30  ${show ? 'visible ' : 'hidden'}`}>
                <div className=''>
                    <button className="absolute  md:top-3 top-4 right-0 md:right-4 text-[#0E044A]" onClick={() => setShow(false)}>
                        <CloseIcon style={{ fontSize: window.innerWidth < 768 ? '30px' : '50px' }} />
                    </button>
                </div>

                <ConsultantProvider />
            </div>


        </>
    );
}

export default ConsultationQueue;
