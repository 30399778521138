import React, { useState } from "react";
import { TextField, Button, Box } from "@mui/material";
// import logo from "./../../assets/images/astha-brand-logo.png"
import logo from "../images/Logo.svg";
import axios from "axios"
// Reusing the same styles as LoginForm
import { successToast, errorToast } from "../../../utils/toastConfig";
import { BASE_URL } from "../../../utils/urlConfig"


const NDHReset = ({ email, setReset }) => {
  const [currentPassword, setCurrentPassword] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        if (newPassword === confirmPassword) {
            console.log("Password changed successfully!");
          } else {
            alert("Passwords do not match!");
          }
      
          const jsonData = {
            email: email,
            oldPassword: currentPassword,
            newPassword: newPassword,
          };
          const res=await axios.post(`${BASE_URL}/auth/change-password`, jsonData, {
            headers: {
              "Content-Type": "application/json",
            },
          });

          console.log(res)
          setReset(false)

          successToast("Password changed Successfully!");
    } catch (error) {
      errorToast("An error occurred while submitting the form.");
      console.error("Error:", error.response?.data || error.message);
        
    }


  };
  const handlleCancle =()=>{
    setReset(false)

  }
  return (
    <Box className="login-container absolute top-48">
      <Box className="login-form bg-[#ECECEC]">
        <div className="logo text-xl text-black">
          Change Password
        </div>
        <form onSubmit={handleSubmit}>
          <TextField
            className="textarea"
            placeholder="Enter Current Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <TextField
            className="textarea"
            placeholder="Enter New Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            className="textarea"
            placeholder="Confirm New Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            className="login-button"
          >
            Change Password
          </Button>
          <p className="back-to-login cursor-pointer text-center text-black mt-2" onClick={handlleCancle}>
            Cancel
          </p>
        </form>
      </Box>
    </Box>
  );
};

export default NDHReset;
