import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { BASE_URL } from "../../../utils/urlConfig"

function Room() {
  const { roomID, name } = useParams();
  const navigate = useNavigate();

  const myMeeting = async (element) => {
    const appID = 698881446;
    const serverSecret = "f186d437029879e945f68e1f914b8941";
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      roomID,
      Date.now().toString(),
      name
    );
    
    const zc = ZegoUIKitPrebuilt.create(kitToken);
    
    zc.joinRoom({
      container: element,
      sharedLinks: [{
        name: 'COPY LINK',
        url: `${BASE_URL}/room/${roomID}`,
      }],
      scenario: {
        mode: ZegoUIKitPrebuilt.OneONoneCall,
      },
      turnOnCameraWhenJoining: false,
      turnOnMicrophoneWhenJoining: false,
      onLeaveRoom: () => {
        navigate("/consulteclinic");
      },
    });
  };

  return (
    <div className="overflow-hidden">
      <div ref={myMeeting} className="h-[650px]"/>
    </div>
  );
}

export default Room;