import React, { useRef, useState, useEffect } from "react";
import { ReactMediaRecorder } from "react-media-recorder";
import axios from "axios";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/urlConfig";

const Video = ({ setVideoRecord, setVideourl }) => {
  const videoRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [mediaBlob, setMediaBlob] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  const startCamera = async () => {
    try {
      const userStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      setStream(userStream);
    } catch (error) {
      console.error("Error accessing camera or microphone:", error.message);
      alert("Please allow access to your camera and microphone.");
    }
  };

  const stopCamera = () => {
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      setStream(null);
    }
  };

  const uploadFiles = async (file) => {
    const formData = new FormData();
    formData.append("video", file);
  
    try {
      setIsUploading(true);
      const response = await axios.post(`${BASE_URL}/api/upload-files`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
  
      let videoUrl = response.data.videoUrls;
  
      if (Array.isArray(videoUrl)) {
        videoUrl = videoUrl[0];
      }
  
      if (typeof videoUrl === 'string') {
        videoUrl = videoUrl.replace('/tempvideo', '');
      }
  
      console.log("Uploaded Video URL:", videoUrl);
      setVideourl(videoUrl);
    } catch (error) {
      console.error(
        "Error uploading files:",
        error.response ? error.response.data : error.message
      );
      alert("Failed to upload video. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };
  
  

  const handleStartRecording = async () => {
    if (!stream) {
      await startCamera();
    }
    setIsRecording(true);
  };

  const handleStopRecording = async (blob) => {
    if (!blob) {
      console.error("No media blob available for upload");
      return;
    }
    const videoFile = new File([blob], "recorded-video.mp4", {
      type: "video/mp4",
    });
    console.log("Media Blob:", blob); // Debug: Log blob details
    console.log("Video File:", videoFile); // Debug: Log file details
    await uploadFiles(videoFile);
    stopCamera();
  };

  return (
    <div>
      <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
        <ReactMediaRecorder
          video
          onStop={(blobUrl, blob) => {
            setMediaBlob(blob);
            console.log("Recording stopped. Blob URL:", blobUrl); // Debug: Blob URL
          }}
          render={({ status, startRecording, stopRecording, mediaBlobUrl }) => {
            return (
              <div className="flex flex-col items-center justify-center space-y-4">
                {stream ? (
                  <video ref={videoRef} autoPlay controls />
                ) : (
                  <video src={mediaBlobUrl} autoPlay loop controls />
                )}

                <div className="grid grid-cols-2">
                  <button
                    onClick={() => {
                      handleStartRecording();
                      startRecording();
                    }}
                    className="m-2 border-2 border-gray-400 p-1 bg-[#0E044A] text-white rounded-lg"
                  >
                    Start Recording
                  </button>
                  <button
                    onClick={() => {
                      stopRecording();
                      handleStopRecording(mediaBlob);
                    }}
                    className="m-2 border-2 border-gray-400 p-1 bg-[#0E044A] text-white rounded-lg"
                    disabled={!isRecording}
                  >
                    Stop & Upload
                  </button>
                  <button
                    className="m-2 text-center border-2 border-gray-400 p-1 bg-[#0E044A] text-white rounded-lg"
                    onClick={() => {
                      setVideoRecord(false);
                      stopCamera();
                    }}
                  >
                    Cancel
                  </button>
                  <button className="m-2 text-center border-2 border-gray-400 p-1 bg-[#0E044A] text-white rounded-lg">
                    Status: {status}
                  </button>
                </div>
                {isUploading && <p className="text-blue-500">Uploading...</p>}
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Video;
