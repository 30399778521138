import React, { useState } from "react";
import "../../Admin_CSS/EmployeeForm.css"
import profileIcon from "../../../../assets/Astha web app/Rectangle 24.svg";
import StoreSelector from "../storeComponents/StoreSelector";
import { errorToast, successToast } from "../../../../utils/toastConfig";
import axios from "axios";
import Sidebar from "../../../../layout/Sidebar";
import { BASE_URL } from "../../../../utils/urlConfig"

// Define the initial state outside of the component
const initialStoreState = {
  elinic_name: "",
  email: "",
  mobile: "",
  gst_no: "",
  photo:"",
  address: {
    line1: "",
    line2: "",
    city: "",
    district: "",
    state: "",
    zipcode: "",
  },
  zone_id:"",
  
};
const AddEclinic = () => {
  // State for the Store form
  const [store, setStore] = useState(initialStoreState);
  const [profilePicture, setProfilePicture ] =useState(profileIcon)

  // Handle change in input fields
  const handleChange = (e) => {
    const { name: inputName, value } = e.target;

    if (inputName.startsWith("address.")) {
      const fieldName = inputName.split(".")[1];
      setStore((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [fieldName]: value,
        },
      }));
    } else {
      setStore((prev) => ({
        ...prev,
        [inputName]: value,
      }));
    }
  };

  

  // Upload files to server
  const uploadFiles = async (files) => {
    const formData = new FormData()
    if (files.photo) {
      formData.append("photo", files.photo)
    }
    console.log(formData)

    try {
      const response = await axios.post(
        `${BASE_URL}/api/upload-files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      console.log(response.data)
      return response.data // Return the URLs of the uploaded files
    } catch (error) {
      console.error(
        "Error uploading files:",
        error.response?.data || error.message
      )
      throw error
    }
  }

  // Handle form submission
  const handleSubmit = async (e) => {
    
    e.preventDefault();

    if (
      !store.elinic_name ||
      !store.email ||
      !store.mobile ||
      !store.gst_no ||
      !store.address.line1 ||
      !store.address.state ||
      !store.address.city ||
      !store.address.zipcode ||
      !store.address.district ||
      !store.zone_id ||
      !store.photo
    ) {
      errorToast("Please enter the required fields")
      return
    } else {

      const files = {
        photo: store.photo,
       
      }
  
      try {
        const { photoUrl } = await uploadFiles(files);
      
        const jsonData = {
          ...store,
          photo: photoUrl, // Ensure this is correct
          zone_id: parseInt(store.zone_id, 10), // Convert zone_id to a number
        };
      
        console.log(jsonData);
      
        await axios.post(`${BASE_URL}/api/eclinic/`, jsonData, {
          headers: { "Content-Type": "application/json" },
        });
      
        handleReset();
        console.log("Form submitted successfully!");
        successToast("Form Submitted Successfully!");
      } catch (error) {
        // Log detailed error information
        console.error(
          "Error submitting form:",
          error.response?.data || error.message
        );
      
        // Check if the server responded with more details
        if (error.response) {
          console.log("Server response:", error.response.data);
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error setting up request:", error.message);
        }
      
        errorToast("An error occurred while submitting the form.");
      }
      }
    }
    // console.log(store);
    // setStore(initialStoreState);
    // setProfilePicture(profileIcon)
    // handleReset()
    // successToast("Eclinic Added Successfully!")
  

  // Handle form reset
  const handleReset = () => {
    setStore(initialStoreState);
    setProfilePicture(profileIcon)
  };

  const handleFileChange = (e) => {
    const file = e.target.files ? e.target.files[0] : null // Ensure the file exists

    if (file && file.type.startsWith("image/")) {
      // Validate it's an image file
      const reader = new FileReader()
      reader.onload = () => {
        setProfilePicture(reader.result) // Set the image preview
        setStore((prev) => ({
          ...prev,
          photo: file, // Set the file in the state
        }))
      }
      reader.readAsDataURL(file) // Read the file as a data URL
    } else {
      console.error("Please select a valid image file.")
    }
  }

  return (
    <>
      <div>
        <Sidebar />
      </div>
      <div className="containers" style={{ margin: "0 18% 5%" }}>
        <h1 className="ah1">
          Add E-Clinic
          <StoreSelector />
        </h1>

        <form className="consultant-form" onSubmit={handleSubmit}>
          <div className="profile-section" style={{gridTemplateColumns:"14em 14em 12em 8em"}}>
            <div id="profile">
              <h2>Profile</h2>
            </div>
            <div className="form-group">
              <label htmlFor="first-name">Eclinic Name</label>
              <input
                type="text"
                id="first-name"
                name="elinic_name"
                value={store.elinic_name}
                onChange={handleChange}
                placeholder="Eclinic Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="personal-mobile">Contact</label>
              <input
                type="number"
                id="personal-mobile"
                name="mobile"
                value={store.mobile}
                onChange={handleChange}
                placeholder="Mobile No."
              />
            </div>
            

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={store.email}
                onChange={handleChange}
                placeholder="abc@xyz.com"
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="GST-No">GST No.</label>
              <input
                type="text"
                id="GST-No"
                name="gst_no"
                value={store.gst_no}
                onChange={handleChange}
                placeholder="GST-No."
              />
            </div>

            <div className="form-group">
              <label htmlFor="Zone-Id">Zone Id</label>
              <input
                type="text"
                id="Zone-Id"
                name="zone_id"
                value={store.zone_id}
                onChange={handleChange}
                placeholder="Zone Id"
              />
            </div>
            <div id="profile-picture">
            <div>
              <img
                src={profilePicture || profileIcon}
                alt="Profile"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
                onClick={() =>
                  document.getElementById("profile-picture-input").click()
                }
              />
              <span>Profile Picture</span>
              <input
                type="file"
                id="profile-picture-input"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </div>
          </div>
          </div>

          <div className="contact-address-section">
            <div id="contact-address">
              <h2>Store Address</h2>
            </div>
            <div className="form-group">
              <label htmlFor="address-line1">Address Line1</label>
              <input
                type="text"
                id="address-line1"
                name="address.line1"
                value={store.address.line1}
                onChange={handleChange}
                placeholder="Address Line1"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-line2">Address Line2</label>
              <input
                type="text"
                id="address-line2"
                name="address.line2"
                value={store.address.line2}
                onChange={handleChange}
                placeholder="Address Line2"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-city">City</label>
              <input
                type="text"
                id="address-city"
                name="address.city"
                value={store.address.city}
                onChange={handleChange}
                placeholder="City"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-district">District</label>
              <input
                type="text"
                id="address-district"
                name="address.district"
                value={store.address.district}
                onChange={handleChange}
                placeholder="District"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-state">State</label>
              <input
                type="text"
                id="address-state"
                name="address.state"
                value={store.address.state}
                onChange={handleChange}
                placeholder="State"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-zipcode">Zipcode</label>
              <input
                type="text"
                id="address-zipcode"
                name="address.zipcode"
                value={store.address.zipcode}
                onChange={handleChange}
                placeholder="Zipcode"
              />
            </div>
          </div>

          <div className="consultant-details-section" >
            <div id="present-address">
              <h2>Assigned CF-Eclinic</h2>
            </div>
            <div className="form-group">
            <div id="profile-picture">
              <div>
                <img src={profileIcon} alt="Profile-Picture" />
                <span>Profile Picture</span>
            </div>
              </div>
            </div>
          </div>



          <div className="form-actions">
            <button type="submit" className="save-btn">
              Save
            </button>
            <button type="button" className="cancel-btn" onClick={handleReset}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddEclinic;
