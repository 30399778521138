import { Grid } from "@mui/material";

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Dashboard() {
  return (
    <Box sx={{position:"fixed"}}>
    <Box sx={{margin:"4% 2%", fontWeight:"700", fontSize:"larger" , color:"#454545"}}><h2>Dashboard</h2></Box>
    <Box sx={{ height:"100%", width: '54%', marginLeft:"10vh" }}>
    
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2, lg:2}}>
        <Grid item xs={12} lg={6} >
          <Item sx={{ borderRadius:"14px", fontFamily:"Monda", fontWeight:"500", fontSize:"large", color:"#ffffff", backgroundColor:"rgba(80, 139, 202, 0.57)", position:"relative", width:"270px", height:"140px"}}><Box sx={{position:"absolute", top:"0", left:"0", padding:"15px"}}>Registered Consultation</Box><Box sx={{position:"absolute", bottom:"0",right:"0", padding:"20px"}}>20</Box></Item>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Item sx={{ borderRadius:"14px", fontFamily:"Monda", fontWeight:"500", fontSize:"large", color:"#ffffff", backgroundColor:"#365268", position:"relative", width:"270px", height:"140px"}}><Box sx={{position:"absolute", top:"0", left:"0", padding:"15px"}}>Active Teleconsultation</Box><Box sx={{position:"absolute", bottom:"0",right:"0", padding:"20px"}}>20</Box></Item>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Item sx={{ borderRadius:"14px", fontFamily:"Monda", fontWeight:"500", fontSize:"large", color:"#ffffff",backgroundColor:"#365268", position:"relative", width:"270px", height:"140px" }}><Box sx={{position:"absolute", top:"0", left:"0", padding:"15px"}}>Active Consultant</Box> <Box sx={{position:"absolute", bottom:"0",right:"0", padding:"20px"}}>20</Box></Item>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Item sx={{ borderRadius:"14px", fontFamily:"Monda", fontWeight:"500", fontSize:"large", color:"#ffffff", backgroundColor:"rgba(80, 139, 202, 0.57)", position:"relative", width:"270px", height:"140px"}}><Box sx={{position:"absolute", top:"0", left:"0", padding:"15px"}}>Consultation Report</Box><Box sx={{position:"absolute", bottom:"0",right:"0", padding:"20px"}}>0/0</Box></Item>
        </Grid>
      </Grid>
    </Box>
    </Box>
  );
}

