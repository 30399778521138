import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import DescriptionIcon from "@mui/icons-material/Description";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import InfoIcon from '@mui/icons-material/Info';
const PendingOreders = ({ handleInfo }) => {
  const allOrdersData = [
    {
      id: 1,
      parentId: "P001",
      consultationId: "C001",
      Documents: "insert",
      cfAssigned: "CF001",
      status: 0,
    },
    {
      id: 2,
      parentId: "P002",
      consultationId: "C002",
      Documents: "insert",
      cfAssigned: "CF002",
      status: 1,
    },
  ];

  return (
    <>
      <div className="mt-4 bg-white p-4 max-w-screen-xl mx-auto rounded-lg shadow-lg">
        <div className="flex flex-col w-full space-y-4">
          <div className="flex flex-col sm:flex-row justify-center sm:justify-between space-y-2 sm:space-y-0 sm:space-x-4">
            <div className=" sm:mr-auto flex gap-5">
              <span>
              <p className="ml-9">Search</p>

                <div>

                <SearchIcon
                  className="relative left-9 "
                  style={{ fontSize: "30px", color: "gray" }}
                  />
                <input
                  type="text"
                  placeholder="PendingOreders"
                  className="p-1 pl-9 rounded-full 420px:w-[50vh] text-center border-2 border-gray-400 320px:w-[40vh]"
                  />
                  </div>
              </span>
              <span>
              <p>Sort by</p>
                <input
                  type="text"
                  placeholder="PendingOreders"
                  className="p-1 pl-9 rounded-full 420px:w-[50vh] text-center border-2 border-gray-400 320px:w-[40vh]"
                />
              </span>
            </div>
           
          </div>
          

          <div className="overflow-x-auto">
            <div className="min-w-full">
              <table className="min-w-full border-collapse">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="px-4 py-2 text-center">Order No</th>
                    <th className="px-4 py-2 text-center">Request Date</th>
                    <th className="px-4 py-2 text-center">Consultation ID</th>
                    <th className="px-4 py-2 text-center">Drug Requested</th>
                    <th className="px-4 py-2 text-center">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {allOrdersData.map((row, index) => (
                    <tr key={index} className="border-b hover:bg-gray-50">
                      <td className="border p-2 text-center">{row.id}</td>
                      <td
                        className={`border p-2 text-center`}
                        onClick={handleInfo}
                      >
                        {row.parentId}
                      </td>
                      <td className="border p-2 text-center">
                        {row.consultationId}
                      </td>
                      <td className="px-4 py-2 text-center">
                        <DescriptionIcon className="mr-2 cursor-pointer" />
                      </td>
                      <td className="border p-2 text-center">
                        <div className="flex items-center">

                        <p
                          className={`border-2 px-[3px] w-[80px] rounded-sm block mx-auto ${
                            row.status === 1
                              ? "text-green-500 border-green-500"
                              : row.status === 2
                              ? "text-gray-500 border-gray-500"
                              : "text-yellow-500 border-yellow-500"
                          }`}
                        >
                          {row.status === 1
                            ? "Packed"
                            : row.status === 2
                            ? "Ready"
                            : "Delevered"}
                        </p>{" "}
                        <InfoIcon />
                            </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Stack spacing={2} className="flex items-end mt-4">
        <Pagination
          // count={Math.ceil(filteredPatients.length / patientsPerPage)}
          // page={page}
          // onChange={handleChangePage}
          color="primary"
        />
      </Stack>
    </>
  );
};

export default PendingOreders;


