import { useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import CrossIcon from "@mui/icons-material/Close";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import DatePicker from "react-datepicker";

const RegisterSuccess = ({ patientData, setShowSuccessCard, id }) => {
  return (
    <>
      <div className="w-[20rem] left-10 sm:w-[28rem] sm:left-24 sm:top-28 md:w-[26rem] lg:w-[44rem] lg:left-80 xl:left-[450px] md:top-32 md:left-80 xl:w-[50rem] absolute top-48   motion-box h-[26rem] mx-auto rounded-xl">
        <div className=" bg-[#0E044AEB] rounded-t-lg text-center mx-auto text-white  ">
          <div className="text-end">
            <CrossIcon onClick={() => setShowSuccessCard(false)} />
          </div>
          <div className="text-nowrap text-2xl underline font-semibold">
            Success !!
          </div>
          <div className="text-nowrap ">
            <CheckCircleOutlineRoundedIcon
              className=""
              style={{ fontSize: "45px" }}
            />
          </div>
          <div className="text-nowrap text-lg  font-semibold">
            Patient has been registered
          </div>
          <div className="text-nowrap text-lg p-1 font-semibold bg-[#0E044AEB] rounded-lg">
            {/* ID69SheenaRyder */}
            {id}
          </div>
        </div>
        <div className=" shadow-2xl mx-auto bg-white">
          <div className="grid grid-cols-3">
            <div className="flex-col mr-2 ml-2 ">
              <div className="mt-3">
                Patient Name
                <p className="w-full p-1 rounded-md">{patientData.name}</p>
              </div>
              <div className="mt-3">
                DOB
                <div className="w-full p-1 rounded-lg">
                  <p>{patientData.dob}</p>
                </div>
              </div>
              <div className="mt-3">
                Gender
                <p className=" p-1 rounded-md text-gray-400">
                  {patientData.gender}
                </p>
              </div>
              <div className="mt-3">
                Contact Number{" "}
                <p className="w-full p-1 rounded-md">{patientData.mobile}</p>
              </div>
            </div>

            <div className="flex-col">
              <div className="mt-2">Address</div>
              <div className="w-full p-1 rounded-md">
                <p>Line1: {patientData.address.line1}</p>
              </div>
              <div className="w-full p-1 rounded-md mt-2">
                <p>Line2: {patientData.address.line2}</p>
              </div>
              <div className="w-full p-1 rounded-md mt-2">
                <p>City: {patientData.address.city}</p>
              </div>
              <div className="w-full p-1 rounded-md mt-2">
                <p>District: {patientData.address.district}</p>
              </div>
              <div className="w-full p-1 rounded-md mt-2">
                <p>State: {patientData.address.state}</p>
              </div>
              <div className="w-full p-1 rounded-md mt-2">
                <p>Zipcode: {patientData.address.zipcode}</p>
              </div>
            </div>

            <div className="ml-2">
              <div className="">ID Proof</div>
              <div className="grid grid-rows">
                <div className="">
                  Type
                  {/* <select
                    name=""
                    id=""
                    className="w-full p-1 rounded-md  text-gray-400"
                    style={{ fontSize: "0.7rem" }}
                  >
                    <option value="1">AADHAR</option>
                    <option value="2">ABHA</option>
                    <option value="3">VOTER ID</option>
                  </select> */}
                  <p className="w-full p-1 rounded-md  text-gray-400">
                    {patientData.idProof.type == 1
                      ? "Aadhar"
                      : patientData.idProof.type == 2
                      ? "PAN"
                      : patientData.idProof.type == 3
                      ? "Voter ID"
                      : "Unknown"}
                  </p>
                </div>
                <div className="">
                  Number
                  {/* <input
                    type="number"
                    name=""
                    id=""
                    placeholder="Enter ID"
                    className="w-full p-1 rounded-md"
                  /> */}
                  <p className="w-full p-1 rounded-md">
                    {patientData.idProof.number}
                  </p>
                </div>
              </div>
              {/* <div className="flex flex-col mb-4 items-center">
                <span className="border-2 border-dotted rounded border-blue-900 p-3 mt-2 flex flex-col justify-center items-center">
                  <DescriptionIcon />
                  <label
                    htmlFor="document-upload"
                    className="text-center  cursor-pointer"
                  >
                    ID Proof
                  </label>
                  <input type="file" id="document-upload" className="hidden" />
                </span>
                <button className="upload bg-[#0E044A] text-white rounded max-w-20 mt-2 p-1 mx-1">
                  Upload
                </button>
              </div> */}
              {/* <div className="text-end mr-1">
                <button
                  className="border p-1 rounded-lg text-white bg-green-500 mb-1 sm:mb-0 "
                  onClick={() => {
                    setShowSuccess(!showSuccess);
                    // setShowConsultation(!showConsultation);
                  }}
                >
                  Submit
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterSuccess;
