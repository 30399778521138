import React, { useState, useEffect } from "react"
import axios from "axios"
import Sidebar from "../../../layout/Sidebar"
import LabTestModal from "./LabTestModal"
import EditLabTestModal from "./EditLabTestModal"
import { BASE_URL } from "../../../utils/urlConfig"

import {
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Box,
} from "@mui/material"

const LabTestDetails = () => {
  const [tests, setTests] = useState([])
  const [categories, setCategories] = useState([])
  const [filterCategory, setFilterCategory] = useState("")
  const [filterPriceList, setFilterPriceList] = useState("")
  const [searchQuery, setSearchQuery] = useState("")
  const [page, setPage] = useState(1)
  const [rowsPerPage] = useState(5)
  const [selectedTest, setSelectedTest] = useState(null) //state for the selected test
  const [openModal, setOpenModal] = useState(false); // State for modal visibility

  const fetchTests = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/pathology-tests/`
      )
      console.log(response.data.pathologyTests)
      setTests(response.data.pathologyTests)
    } catch (error) {
      console.error("Error Fetching Lab Tests", error)
    }
  }

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/test-categories`
      )
      console.log(response.data) // Check the response structure
      setCategories(response.data)
    } catch (error) {
      console.error("Error Fetching Categories", error)
    }
  }

  useEffect(() => {
    fetchTests()
    fetchCategories()
  }, [])

  // Handle refresh after data is fetched from database
  const handleTestUpdate = () => {
    fetchTests()
  }

  const filteredTests = tests.filter(
    (test) =>
      (filterCategory === "" || test.category_id === filterCategory) &&
      (filterPriceList === "" ||
        (test.price >= filterPriceList.min &&
          test.price <= filterPriceList.max)) &&
      (searchQuery === "" ||
        test.name.toLowerCase().includes(searchQuery.toLowerCase()))
  )
  

  const indexOfLastTest = page * rowsPerPage
  const indexOfFirstTest = indexOfLastTest - rowsPerPage
  const currentTests = filteredTests.slice(indexOfFirstTest, indexOfLastTest)

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  const handleRowClick = (drug) => {
    setSelectedTest(drug); // Set the selected test data
    setOpenModal(true); // Open the modal
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal
    setSelectedTest(null); // Clear the selected test
  };

  return (
    <>
      <div>
        <Sidebar />
      </div>
      <Box sx={{ padding: "0 2%", margin: "0 20%" }}>
        <Box
          sx={{
            fontWeight: "600",
            color: "#454545",
            fontSize: "larger",
            marginLeft: "-2%",
          }}
        >
          Lab Test Details
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "3%",
            marginBottom: "20px",
            "& .MuiOutlinedInput-input": {
              backgroundColor: "#f5f5f5",
              height: "16px",
              fontWeight: "medium",
            },
            "& .MuiSelect-select ": {
              padding: "14px 32px 14px 14px",
            },
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ minWidth: 315, marginRight: 2 }}
          />

          <FormControl
            variant="outlined"
            sx={{ minWidth: 200, marginRight: 2 }}
          >
            <InputLabel>Category</InputLabel>
            <Select
              value={filterCategory}
              onChange={(e) => setFilterCategory(e.target.value)}
              label="Category"
            >
              <MenuItem value="">All Categories</MenuItem>
              {categories.map((category) => (
                <MenuItem
                  key={category.id}
                  value={category.id}
                >
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            sx={{ minWidth: 200, marginRight: 2 }}
          >
            <InputLabel>Price List</InputLabel>
            <Select
              value={filterPriceList}
              onChange={(e) => setFilterPriceList(e.target.value)}
              label="Price List"
            >
              <MenuItem value="">All Price Ranges</MenuItem>
              <MenuItem value={{ min: 0, max: 100 }}>Up to 100</MenuItem>
              <MenuItem value={{ min: 101, max: 500 }}>101 - 500</MenuItem>
              <MenuItem value={{ min: 501, max: 1000 }}>501 - 1000</MenuItem>
              {/* Add more price ranges as necessary */}
            </Select>
          </FormControl>

          <Box>

            {/* Pass handleTestUpdate to LabTestModal */}
            <LabTestModal onTestUpdate={handleTestUpdate}/>
          </Box>
        </Box>

        <TableContainer
          component={Paper}
          sx={{ marginTop: "20px", minWidth: "70vw" }}
        >
          <Table>
            <TableHead
              sx={{
                border: "1px",
                backgroundColor: "#5A79C9",
                color: "rgba(11 96 96)",
              }}
            >
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    color: "#ffffff",
                    fontWeight: "550",
                  },
                }}
              >
                <TableCell>Code</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Book Code</TableCell>
                <TableCell>Market Price</TableCell>
                <TableCell>Billing Rate</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentTests.map((test) => (
                <TableRow key={test.code} onClick={()=> handleRowClick(test)} sx={{cursor:"pointer"}}>
                  <TableCell>{test.code}</TableCell>
                  <TableCell>{test.name}</TableCell>
                  <TableCell>{test.category_name}</TableCell>
                  <TableCell>{test.book_code}</TableCell>
                  <TableCell>{`${test.price_min} - ${test.price_max}`}</TableCell>
                  <TableCell>{test.price}</TableCell>
                  <TableCell>{test.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack
          spacing={2}
          style={{
            marginTop: "4vh",
            marginLeft: "50vw",
            marginRight: "-17vw",
            alignItems: "center",
          }}
        >
          <Pagination
            count={Math.ceil(filteredTests.length / rowsPerPage)}
            page={page}
            onChange={handlePageChange}
            sx={{
              "& .MuiPaginationItem-root": {
                borderRadius: "50%",
                marginBottom: "50px",
                color: "#0E044A",
                "&.Mui-selected": {
                  backgroundColor: "#0E044A",
                  color: "#fff",
                },
                "&:hover": {
                  backgroundColor: "#0E044A20",
                },
              },
            }}
          />
        </Stack>
      </Box>
      {selectedTest && (
        <EditLabTestModal
          open={openModal}
          onClose={handleCloseModal}
          test={selectedTest} // Pass the selected drug
          onTestUpdate={handleTestUpdate}
        />
      )}
    </>
  )
}

export default LabTestDetails