import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import "../../Admin_CSS/EmployeeForm.css"
import uploadIcon from "../../../../assets/Astha web app/upload icon.svg"
import profileIcon from "../../../../assets/Astha web app/Rectangle 24.svg"
import { errorToast, successToast } from "../../../../utils/toastConfig"
import axios from "axios"
import CPAccordion from "../EmployeeComponents/CPAccordion"
import Sidebar from "../../../../layout/Sidebar"
import ZoneAccordion from "../EmployeeComponents/ZoneAccordion"
import { useNavigate } from "react-router-dom"
import { BASE_URL } from "../../../../utils/urlConfig"

// Define the initial state outside of the component
const initialEmployeeState = {
  firstName: "",
  middleName: "",
  lastName: "",
  gender: "",
  dob: "",
  email: "",
  contact: "",
  photo: "",
  address: {
    line1: "",
    line2: "",
    city: "",
    district: "",
    state: "",
    zipcode: "",
  },
  idProof: {
    type: "",
    number: "",
    document: null,
  },
  assignedCPs: [],
  assignedZones: [],
}

const EditCFCP = () => {
  // State for the employee form
  const { employeeId } = useParams()
  const navigate = useNavigate()
  const [viewMode, setViewMode] = useState(true) // Start in view mode
  const [employee, setEmployee] = useState(initialEmployeeState)
  const [profilePicture, setProfilePicture] = useState(profileIcon)
  const [proofDocument, setProofDocument] = useState(null)
  const [assignedCPs, setAssignedCPs] = useState([])
  const [assignedZones, setAssignedZone] = useState([])

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/cfcp/${employeeId}`
        )
        const data = response.data

        setEmployee({
          firstName: data.first_name,
          middleName: data.middle_name,
          lastName: data.last_name,
          gender: data.gender,
          dob: new Date(data.dob).toISOString().split("T")[0],
          email: data.email,
          contact: data.contact,
          photo: data.photo,
          address: {
            line1: data.address.line1,
            line2: data.address.line2,
            city: data.address.city,
            state: data.address.state,
            zipcode: data.address.zipcode,
            district: data.address.district,
          },
          idProof: {
            type: data.idProof.proof_type,
            number: data.idProof.proof_no,
            document: data.idProof.proof,
          },
          assignedCPs: data.assignedCPs.map((cp) => cp.id), // Convert to array of IDs
          assignedZones: data.assignedZones.map((zone) => zone.id), // Convert to array of IDs
        })

        // Set other states like profile picture and proof document
        setProfilePicture(
          data.photo
            ? `${BASE_URL}/api/image/${
                data.photo.startsWith("/") ? data.photo.slice(1) : data.photo
              }`
            : profileIcon
        )

        setProofDocument(
          data.idProof?.proof
            ? `${BASE_URL}/api/pdf/${
                data.idProof.proof.startsWith("/")
                  ? data.idProof.proof.slice(1)
                  : data.idProof.proof
              }`
            : null
        )

        // Set assigned CPs and Zones separately for form
        setAssignedCPs(data.assignedCPs.map((cp) => cp.id)) // Set IDs array
        setAssignedZone(data.assignedZones.map((zone) => zone.id)) // Set IDs array
      } catch (error) {
        console.error("Error fetching employee details:", error)
      }
    }

    fetchEmployeeDetails()
  }, [employeeId])

  console.log(assignedCPs)
  console.log(assignedZones)

  console.log(employee)
  console.log(profilePicture)
  console.log(proofDocument)

  const handleCPSelectionChange = (selectedCPs) => {
    setAssignedCPs(selectedCPs)
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      assignedCPs: selectedCPs,
    }))
    console.log(selectedCPs)
  }
  const handleZoneSelectionChange = (selectedZones) => {
    setAssignedZone(selectedZones)
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      assignedZones: selectedZones,
    }))
    console.log(selectedZones)
  }
  // Handle change in input fields
  const handleChange = (e) => {
    const { name: inputName, value } = e.target

    if (inputName.startsWith("address.")) {
      const fieldName = inputName.split(".")[1]
      setEmployee((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [fieldName]: value,
        },
      }))
    } else if (inputName.startsWith("idProof.")) {
      const fieldName = inputName.split(".")[1]
      setEmployee((prev) => ({
        ...prev,
        idProof: {
          ...prev.idProof,
          [fieldName]: value,
        },
      }))
    } else {
      setEmployee((prev) => ({
        ...prev,
        [inputName]: value,
      }))
    }
  }

  // Handle form submission
  const handleProofDocumentChange = (e) => {
    const file = e.target.files[0]
    if (file && file.type === "application/pdf") {
      setProofDocument(file) // Update state with the selected PDF document
      setEmployee((prev) => ({
        ...prev,
        idProof: {
          ...prev.idProof,
          document: file, // Assign file to the proof document
        },
      }))
    } else {
      errorToast("Please upload a PDF document")
    }
  }

  const uploadFiles = async (files) => {
    const formData = new FormData()

    // Append files to FormData
    if (files.photo) {
      formData.append("photo", files.photo)
    }
    if (files.document) {
      formData.append("document", files.document)
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/api/upload-files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      console.log(response.data)
      return response.data // This should contain the URLs of the uploaded files
    } catch (error) {
      console.error(
        "Error uploading files:",
        error.response?.data || error.message
      )
      throw error
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (
      !employee.firstName ||
      !employee.lastName ||
      !employee.gender ||
      !employee.address.line1 ||
      !employee.address.city ||
      !employee.address.district ||
      !employee.address.zipcode ||
      !employee.contact ||
      !employee.dob ||
      !employee.email ||
      !employee.photo ||
      !employee.idProof.type ||
      !employee.idProof.document
    ) {
      errorToast("Please Enter the required fields")
      return
    } else {
      // Start with existing proof document if available
      let proof =
        employee.idProof.document.length > 0 ? employee.idProof.document : null

      const files = {
        photo: employee.photo || null,
        document: proofDocument || null, // New document if uploaded
      }

      try {
        let fileResponse = null

        // Only upload if there's a new document or photo
        if (files.photo || files.document) {
          fileResponse = await uploadFiles(files)
        }

        // Update proof document if a new one is uploaded and URLs are available
        if (
          fileResponse &&
          fileResponse.documentUrls &&
          fileResponse.documentUrls.length > 0
        ) {
          proof = fileResponse.documentUrls
        } else {
          console.log(
            "No new document uploaded, retaining existing proof:",
            proof
          )
        }

        // Check if proof is still empty after uploads
        if (!proof || proof.length === 0) {
          errorToast("Please upload or select a proof document.")
          return
        }

        const jsonData = {
          ...employee,
          photo: fileResponse?.photoUrl || employee.photo, // Use new photo URL if uploaded
          idProof: {
            ...employee.idProof,
            document: proof, // Updated or existing proof document
          },
          assignedCPs: assignedCPs.map((cp) => ({ id: cp })),
          assignedZones: assignedZones.map((zone) => ({ id: zone })),
        }
        // Perform the API update request
        await axios.patch(
          `${BASE_URL}/api/cfcp/${employeeId}`,
          jsonData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )

        successToast("Employee details updated successfully!")
      } catch (error) {
        errorToast("An error occurred while updating the employee details.")
        console.error("Error:", error.response?.data || error.message)
      }
    }
  }

  const handleBackButtonClick = () => {
    navigate(-1) // Go back to the previous page
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        setProfilePicture(reader.result)
        setEmployee((prev) => ({
          ...prev,
          photo: file,
        }))
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <>
      <div>
        <Sidebar />
      </div>
      <div
        className="containers"
        style={{ margin: "0 18% 5%" }}
      >
        <h1 className="ah1">{employeeId ? "Edit CF-CP" : "Add CF-CP"}</h1>
        {/* <button
          type="button"
          className="cancel-btn"
          onClick={() => setViewMode(!viewMode)}
        >
          {viewMode ? "Edit" : "View"}
        </button> */}

        <form
          className="consultant-form"
          onSubmit={handleSubmit}
        >
          <div
            className="profile-section"
            style={{ gridTemplateColumns: "13em 14em 12em 8em" }}
          >
            <div id="profile">
              <h2>Profile</h2>
            </div>
            <div className="form-group">
              <label htmlFor="first-name">First Name</label>
              <input
                type="text"
                id="first-name"
                name="firstName"
                value={employee.firstName}
                onChange={viewMode ? null : handleChange}
                placeholder="First Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="middle-name">Middle Name</label>
              <input
                type="text"
                id="middle-name"
                name="middleName"
                value={employee.middleName}
                onChange={viewMode ? null : handleChange}
                placeholder="Middle Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="last-name">Last Name</label>
              <input
                type="text"
                id="last-name"
                name="lastName"
                value={employee.lastName}
                onChange={viewMode ? null : handleChange}
                placeholder="Last Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="gender">Gender</label>
              <select
                id="gender"
                name="gender"
                value={employee.gender}
                onChange={viewMode ? null : handleChange}
              >
                <option value="">Select</option>
                <option value="M">Male</option>
                <option value="F">Female</option>
                <option value="Others">Other</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="dob">Date of Birth</label>
              <input
                type="date"
                id="dob"
                name="dob"
                value={employee.dob}
                onChange={viewMode ? null : handleChange}
                placeholder="DD-MM-YYYY"
              />
            </div>
            <div className="form-group">
              <label htmlFor="proof-type">ID Proof</label>
              <div>
                {!viewMode ? (
                  <select
                    id="proof-type"
                    name="proof.type"
                    value={employee.idProof.type}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="1">Aadhar</option>
                    <option value="2">ABHA</option>
                    <option value="3">Voter Id</option>
                    <option value="4">Driving License</option>
                    <option value="5">Ration Card</option>
                    <option value="6">Drug License</option>
                    <option value="7">Nabl License</option>
                  </select>
                ) : (
                  <div className="tempxy">
                    {(() => {
                      switch (employee.idProof.type) {
                        case 1:
                          return <span className="id-option">Aadhar</span>
                        case 2:
                          return <span className="id-option">Aabha</span>
                        case 3:
                          return <span className="id-option">Voter id</span>
                        case 4:
                          return (
                            <span className="id-option">Driving License</span>
                          )
                        case 5:
                          return <span className="id-option">Ration Card</span>
                        case 6:
                          return <span className="id-option">Drug License</span>
                        case 7:
                          return <span className="id-option">Nabl License</span>
                        default:
                          return <span className="id-option">Not Provided</span>
                      }
                    })()}
                  </div>
                )}
                <span
                  id="span"
                  className="view-option"
                >
                  {!viewMode && (
                    <>
                      <img
                        src={uploadIcon}
                        style={{
                          filter: proofDocument
                            ? "invert(36%) sepia(76%) saturate(2696%) hue-rotate(81deg) brightness(95%) contrast(92%)"
                            : "none",
                          cursor: "pointer",
                        }}
                        alt="Upload Document"
                        onClick={() =>
                          document.getElementById("proof-document").click()
                        }
                      />
                      <input
                        type="file"
                        id="proof-document"
                        accept="application/pdf"
                        onChange={viewMode ? null : handleProofDocumentChange}
                        style={{ display: "none" }}
                      />
                    </>
                  )}

                  {proofDocument && (
                    <a
                      href={proofDocument}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        marginLeft: "5px",
                        fontSize: "x-small",
                        textDecoration: "underline",
                        color: "#777",
                      }}
                    >
                      {typeof proofDocument === "string"
                        ? "View"
                        : "doc. uploaded"}
                    </a>
                  )}

                  {!proofDocument && (
                    <span
                      style={{
                        marginLeft: "5px",
                        fontSize: "x-small",
                        textDecoration: "underline",
                        color: "#777",
                      }}
                    >
                      No doc. uploaded
                    </span>
                  )}
                </span>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={employee.email}
                onChange={viewMode ? null : handleChange}
                placeholder="abc@xyz.com"
              />
            </div>
            <div className="form-group">
              <label htmlFor="personal-mobile">Contact</label>
              <input
                type="text"
                id="personal-mobile"
                name="contact"
                value={employee.contact}
                onChange={viewMode ? null : handleChange}
                placeholder="Mobile no."
              />
            </div>
            <div id="profile-picture">
              <div>
                <img
                  src={profilePicture || profileIcon}
                  alt="Profile"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    document.getElementById("profile-picture-input").click()
                  }
                />
                <span>Profile Picture</span>
                <input
                  type="file"
                  id="profile-picture-input"
                  accept="image/*"
                  onChange={viewMode ? null : handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>

          <div className="contact-address-section">
            <div id="contact-address">
              <h2>Contact Address</h2>
            </div>
            <div className="form-group">
              <label htmlFor="address-line1">Address Line 1</label>
              <input
                type="text"
                id="address-line1"
                name="address.line1"
                value={employee.address.line1}
                onChange={viewMode ? null : handleChange}
                placeholder="Address Line 1"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-line2">Address Line 2</label>
              <input
                type="text"
                id="address-line2"
                name="address.line2"
                value={employee.address.line2}
                onChange={viewMode ? null : handleChange}
                placeholder="Address Line 2"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-city">City</label>
              <input
                type="text"
                id="address-city"
                name="address.city"
                value={employee.address.city}
                onChange={viewMode ? null : handleChange}
                placeholder="City"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-district">District</label>
              <input
                type="text"
                id="address-district"
                name="address.district"
                value={employee.address.district}
                onChange={viewMode ? null : handleChange}
                placeholder="District"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-state">State</label>
              <input
                type="text"
                id="address-state"
                name="address.state"
                value={employee.address.state}
                onChange={viewMode ? null : handleChange}
                placeholder="State"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-zipcode">Zipcode</label>
              <input
                type="text"
                id="address-zipcode"
                name="address.zipcode"
                value={employee.address.zipcode}
                onChange={viewMode ? null : handleChange}
                placeholder="Zipcode"
              />
            </div>
          </div>

          <div
            className="consultant-details-section"
            id="accordion-container"
          >
            <div className="accordion-div1">
              <CPAccordion
                selectedCPs={assignedCPs}
                onCPSelectionChange={handleCPSelectionChange}
                viewMode={viewMode}
              />
            </div>
            <div className="accordion-div2">
              <ZoneAccordion
                selectedZones={assignedZones}
                onZoneSelectionChange={handleZoneSelectionChange}
                viewMode={viewMode}
              />
            </div>
          </div>

          <div className="form-actions">
            <button
              type="button"
              className="cancel-btn"
              onClick={() => setViewMode(!viewMode)}
            >
              {viewMode ? "Edit" : "View"}
            </button>
            <button
              type="submit"
              className="save-btn"
              // disabled={viewMode}
              onClick={viewMode ? handleBackButtonClick : handleSubmit}
            >
              {viewMode ? "Back" : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default EditCFCP
