import React, { useState } from "react"
import "../../Admin_CSS/EmployeeForm.css"
import uploadIcon from "../../../../assets/Astha web app/upload icon.svg"
import profileIcon from "../../../../assets/Astha web app/Rectangle 24.svg"
import RoleSelector from "../EmployeeComponents/RolesPopupState"
import { errorToast, successToast } from "../../../../utils/toastConfig"
import Sidebar from "../../../../layout/Sidebar";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../../../../utils/urlConfig"

// Define the initial state outside of the component
const initialEmployeeState = {
  firstName: "",
  middleName: "",
  lastName: "",
  gender: "",
  dob: "",
  email: "",
  personalMobile: "",
  consultationMobile: "",
  photo: "",
  address: {
    line1: "",
    line2: "",
    city: "",
    district: "",
    state: "",
    zipcode: "",
  },
  proof: {
    type: "",
    number: "",
    document: null,
  },
  category: "",
  degree: "",
  specialty: "",
  subSpecialty: "",
  superSpecialty: "",
  fellowship: "",
}

const CPForm = () => {
  // State for the employee form
  const [employee, setEmployee] = useState(initialEmployeeState)
  const [profilePicture, setProfilePicture] = useState(profileIcon)
  const [proofDocument, setProofDocument] = useState(null)

  // Handle change in input fields
  const handleChange = (e) => {
    const { name: inputName, value } = e.target

    if (inputName.startsWith("address.")) {
      const fieldName = inputName.split(".")[1]
      setEmployee((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [fieldName]: value,
        },
      }))
    } else if (inputName.startsWith("proof.")) {
      const fieldName = inputName.split(".")[1]
      setEmployee((prev) => ({
        ...prev,
        proof: {
          ...prev.proof,
          [fieldName]:
            fieldName === "type" ? (value ? parseInt(value, 10) : null) : value,
        },
      }))
    } else {
      setEmployee((prev) => ({
        ...prev,
        [inputName]: value,
      }))
    }
  }
  // Handle file upload for proof document
  const handleProofDocumentChange = (e) => {
    const file = e.target.files[0]
    if (file && file.type === "application/pdf") {
      setProofDocument(file) // Update state with the selected PDF document
      setEmployee((prev) => ({
        ...prev,
        proof: {
          ...prev.proof,
          document: file, // Assign file to the proof document
        },
      }))
    } else {
      errorToast("Please upload a PDF document")
    }
  }
  console.log(employee.proof.document)
  console.log(proofDocument)

  const uploadFiles = async (files) => {
    const formData = new FormData()

    // Append files to FormData
    if (files.photo) {
      formData.append("photo", files.photo)
    }
    if (files.document) {
      formData.append("document", files.document)
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/api/upload-files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${token}`,

          },
        }
      )
      console.log(response.data)
      return response.data // This should contain the URLs of the uploaded files
    } catch (error) {
      console.error(
        "Error uploading files:",
        error.response?.data || error.message
      )
      throw error
    }
  }
 
  const token =
  useSelector((state) => state.auth.token) || localStorage.getItem("token");

const handleSubmit = async (e) => {
  e.preventDefault()

  if (
    !employee.firstName ||
    !employee.lastName ||
    !employee.gender ||
    !employee.address.line1 ||
    !employee.address.city ||
    !employee.address.district ||
    !employee.address.zipcode ||
    !employee.consultationMobile ||
    !employee.dob ||
    !employee.email ||
    !employee.personalMobile ||
    !employee.photo ||
    !employee.proof.type ||
    !employee.proof.document ||
    !employee.category ||
    !employee.specialty
  ) {
    errorToast("Please Enter the required fields")
    return
  } else {

  const files = {
    photo: employee.photo,
    document: proofDocument,
  }

  try {
    // Step 2: Upload files and get URLs
    const fileResponse = await uploadFiles(files)
    const { photoUrl, documentUrls } = fileResponse

    // Step 3: Prepare and send JSON data with URLs
    const jsonData = {
      ...employee,
      photo: photoUrl, // Assign photoUrl to photo
      proof: {
        ...employee.proof,
        document: documentUrls, // Assign documentUrls to proof.document
      },
    }

    // Step 4: Send JSON data
    await axios.post(`${BASE_URL}/api/add-cp`, jsonData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,

      },
    })

    // Step 5: Reset form and state
    handleReset()

    // Step 6: Show success toast
    successToast("Form Submitted Successfully!")
  } catch (error) {
    errorToast("An error occurred while submitting the form.")
    console.error("Error:", error.response?.data || error.message)
  }}
}


  // Handle form reset
  const handleReset = () => {
    setEmployee(initialEmployeeState)
    setProfilePicture(profileIcon)
  }
  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        setProfilePicture(reader.result)
        setEmployee((prev) => ({
          ...prev,
          photo: file,
        }))
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <>
    <div><Sidebar/></div>
    <div className="containers" style={{ margin: "0 18% 5%" }}>
      <h1 className="ah1">
        Add Consultant Provider
        <RoleSelector />
      </h1>

      <form
        className="consultant-form"
        onSubmit={handleSubmit}
      >
        <div className="profile-section">
          <div id="profile">
            <h2>Profile</h2>
          </div>
          <div className="form-group">
            <label htmlFor="first-name">First Name</label>
            <input
              type="text"
              id="first-name"
              name="firstName"
              value={employee.firstName}
              onChange={handleChange}
              placeholder="First Name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="middle-name">Middle Name</label>
            <input
              type="text"
              id="middle-name"
              name="middleName"
              value={employee.middleName}
              onChange={handleChange}
              placeholder="Middle Name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="last-name">Last Name</label>
            <input
              type="text"
              id="last-name"
              name="lastName"
              value={employee.lastName}
              onChange={handleChange}
              placeholder="Last Name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="gender">Gender</label>
            <select
              id="gender"
              name="gender"
              value={employee.gender}
              onChange={handleChange}
            >
              <option value="select">Select</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
              <option value="Others">Other</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="dob">Date of Birth</label>
            <input
              type="date"
              id="dob"
              name="dob"
              value={employee.dob}
              onChange={handleChange}
              placeholder="DD-MM-YYYY"
            />
          </div>
          <div className="form-group">
            <label htmlFor="proof-type">ID Proof</label>
            <div>
              <select
                id="proof-type1"
                name="proof.type"
                value={employee.proof.type}
                onChange={handleChange}
              >
                <option value="">Select</option>
                  <option value="1">Aadhar</option>
                  <option value="2">ABHA</option>
                  <option value="3">Voter Id</option>
                  <option value="4">Driving License</option>
                  <option value="5">Ration Card</option>
                  <option value="6">Drug License</option>
                  <option value="7">Nabl License</option>
              </select>
              <span id="span">
                <img
                  type="file"
                
                  name="idProof.document"
                  value="idProof.document"
                  style={{
                    filter: proofDocument ? "invert(36%) sepia(76%) saturate(2696%) hue-rotate(81deg) brightness(95%) contrast(92%)" : "none",
                    cursor: "pointer",
                  }}
                  src={uploadIcon}
                  alt=""
                  onClick={() =>
                    document.getElementById("proof-document").click()
                  }
                />
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="proof-document"
                  accept="application/pdf"
                  onChange={handleProofDocumentChange} // Updated to handle PDF uploads
                />
              </span>
            </div>
          </div>
          
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={employee.email}
              onChange={handleChange}
              placeholder="abc@xyz.com"
            />
          </div>
          <div className="form-group">
            <label htmlFor="personal-mobile">Personal Mobile</label>
            <input
              type="text"
              id="personal-mobile"
              name="personalMobile"
              value={employee.personalMobile}
              onChange={handleChange}
              placeholder="Personal Mob."
            />
          </div>
          <div className="form-group">
            <label htmlFor="consultation-mobile">Consultation Mobile</label>
            <input
              type="text"
              id="consultation-mobile"
              name="consultationMobile"
              value={employee.consultationMobile}
              onChange={handleChange}
              placeholder="Consultation Mob."
            />
          </div>
          <div id="profile-picture">
            <div>
              <img
                src={profilePicture || profileIcon}
                alt="Profile"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
                onClick={() =>
                  document.getElementById("profile-picture-input").click()
                }
              />
              <span>Profile Picture</span>
              <input
                type="file"
                id="profile-picture-input"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </div>
          </div>
        </div>

        <div className="contact-address-section">
          <div id="contact-address">
            <h2>Contact Address</h2>
          </div>
          <div className="form-group">
            <label htmlFor="address-line1">Address Line 1</label>
            <input
              type="text"
              id="address-line1"
              name="address.line1"
              value={employee.address.line1}
              onChange={handleChange}
              placeholder="Address Line 1"
            />
          </div>
          <div className="form-group">
            <label htmlFor="address-line2">Address Line 2</label>
            <input
              type="text"
              id="address-line2"
              name="address.line2"
              value={employee.address.line2}
              onChange={handleChange}
              placeholder="Address Line 2"
            />
          </div>
          <div className="form-group">
            <label htmlFor="address-city">City</label>
            <input
              type="text"
              id="address-city"
              name="address.city"
              value={employee.address.city}
              onChange={handleChange}
              placeholder="City"
            />
          </div>
          <div className="form-group">
            <label htmlFor="address-district">District</label>
            <input
              type="text"
              id="address-district"
              name="address.district"
              value={employee.address.district}
              onChange={handleChange}
              placeholder="District"
            />
          </div>
          <div className="form-group">
            <label htmlFor="address-state">State</label>
            <input
              type="text"
              id="address-state"
              name="address.state"
              value={employee.address.state}
              onChange={handleChange}
              placeholder="State"
            />
          </div>
          <div className="form-group">
            <label htmlFor="address-zipcode">Zipcode</label>
            <input
              type="text"
              id="address-zipcode"
              name="address.zipcode"
              value={employee.address.zipcode}
              onChange={handleChange}
              placeholder="Zipcode"
            />
          </div>
        </div>

        <div className="consultant-details-section">
          <div id="consultant-details">
            <h2>Consultant Details</h2>
          </div>
          <div className="form-group">
            <label htmlFor="category">Category</label>
            <select
              id="category"
              name="category"
              value={employee.category}
              onChange={handleChange}
            >
              <option value="">Select</option>
              <option value="1">Category1</option>
              <option value="2">Category2</option>
              {/* Add more categories as needed */}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="degree">Degree</label>
            <input
              type="text"
              id="degree"
              name="degree"
              value={employee.degree}
              onChange={handleChange}
              placeholder="Degree"
            />
          </div>
          <div className="form-group">
            <label htmlFor="specialty">Specialty</label>
            <input
              type="text"
              id="specialty"
              name="specialty"
              value={employee.specialty}
              onChange={handleChange}
              placeholder="Specialty"
            />
          </div>
          <div className="form-group">
            <label htmlFor="sub-specialty">Sub-Specialty</label>
            <input
              type="text"
              id="sub-specialty"
              name="subSpecialty"
              value={employee.subSpecialty}
              onChange={handleChange}
              placeholder="Sub-Specialty"
            />
          </div>
          <div className="form-group">
            <label htmlFor="super-specialty">Super-Specialty</label>
            <input
              type="text"
              id="super-specialty"
              name="superSpecialty"
              value={employee.superSpecialty}
              onChange={handleChange}
              placeholder="Super-Specialty"
            />
          </div>
          <div className="form-group">
            <label htmlFor="fellowship">Fellowship</label>
            <input
              type="text"
              id="fellowship"
              name="fellowship"
              value={employee.fellowship}
              onChange={handleChange}
              placeholder="Fellowship"
            />
          </div>
        </div>

        <div className="form-actions">
          <button
            type="submit"
            className="save-btn"
          >
            Save
          </button>
          <button
            type="button"
            className="cancel-btn"
            onClick={handleReset}
          >
            Cancel
          </button>
        </div>
      </form>
    </div></>
  )
}

export default CPForm