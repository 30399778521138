import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import avatar from "../images/Avtar (1).svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import epatientIcon from "../images/Vector (3).svg";
import npatientIcon from "../images/Vector (2).svg";
import Chart from "../images/Consultation Graph.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/urlConfig"

const NDHDeshbboard = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showSuccessCard, setShowSuccessCard] = useState(false);
  const [registerPatient, setRegisterPatient] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const [showexisting, setShowexisting] = useState(false);
  const [showExistingPatientList, setShowExistingPatientList] = useState(false);
  const [existingPatients, setExistingPatients] = useState([]);
  const [showEditPatient, setShowEditPatient] = useState(false);
  const [editPatientid, setEditPatientid] = useState();
  const [showConsultation, setShowConsultation] = useState(false);
  const [consultationid, setConsultationid] = useState();
  const [id, setId]=useState();
  const [pic, setPic] = useState();
  const [user, setUser] = useState([]);

  const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");
  useEffect(() => {
    axios
      .get(`${BASE_URL}/auth/user/data`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        const data = res.data;

        const calculateAge = (dob) => {
          const [day, month, year] = dob.split("/").map(Number);
          const birthDate = new Date(year, month - 1, day);
          const today = new Date();
          let age = today.getFullYear() - birthDate.getFullYear();
          const monthDiff = today.getMonth() - birthDate.getMonth();

          // Adjust age if the birthday hasn't occurred yet this year
          if (
            monthDiff < 0 ||
            (monthDiff === 0 && today.getDate() < birthDate.getDate())
          ) {
            age--;
          }
          return age;
        };

        setUser({
          ...data,
          age: calculateAge(data.dob), // Add calculated age
        });
        console.log();
        setPic(
          data.photo
            ? `${BASE_URL}/api/image/${
                data.photo.startsWith("/") ? data.photo.slice(1) : data.photo
              }`
            : ""
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [token]);

  const formatDate = (date) => {
    if (!date) return null;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [startDate, setStartDate] = useState(new Date("2024-01-01"));
  const [endDate, setEndDate] = useState(new Date());
  const [consultants, setConsultants] = useState([]);

  const handleDateChange = (date, type) => {
    if (type === "start") {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  useEffect(() => {
    const fetchConsultations = async () => {
      try {
        const res = await axios.get(
          `${BASE_URL}/api/consultationsbydate?startDate=${formatDate(
            startDate
          )}&endDate=${formatDate(endDate)}`
        );
        setConsultants(res.data.consultations);
      } catch (error) {
        console.error("Error fetching consultations:", error);
      }
    };

    if (startDate && endDate) {
      fetchConsultations();
    }
  }, [startDate, endDate]);


  return (
    <>
      <div
        className={`
          ${registerPatient ? "blur-sm" : ""}
          ${showSuccessCard ? "	blur-sm" : ""}
          ${showexisting ? " blur-sm " : ""} 
          ${showConsultation ? "blur-sm" : ""}
        ${showExistingPatientList ? "blur-md" : ""}
        ${showEditPatient ? "blur-md" : ""}
        
        `}
      >
        <div className="p-4">
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: "#454545",
              fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
              textDecoration: "underline",
              textDecorationColor: "#454534",
              textDecorationThickness: "1px",
            }}
          >
            Dashboard
          </Typography>

          <div
            className={`mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 xl:grid-cols-6 gap-4 h-full`}
          >
            <div className="shadow-xl h-36 bg-white border border-b-gray-400 rounded-lg flex items-center p-4 col-span-1 sm:col-span-2">
              <img
                src={pic || avatar}
                alt=""
                className="h-16 sm:h-20 contrast-200"
              />
              <div className="ml-4">
                <Typography
                  variant="body2"
                  className="text-gray-600 underline decoration-slate-400 pb-2"
                  sx={{ fontWeight: "200" }}
                >
                  {user.id} | {user.first_name} {user.middle_name}{" "}
                  {user.last_name}
                </Typography>
                <Typography
                  variant="body2"
                  className="text-gray-600 underline decoration-slate-400"
                  sx={{ fontWeight: "400" }}
                >
                  {user.mobile}
                </Typography>
              </div>
            </div>

            <div className="shadow-xl h-36 bg-white border border-b-gray-400 rounded-lg col-span-1 sm:col-span-1 p-4 flex flex-col justify-between">
            
            </div>

            <div className=" shadow-xl bg-white border border-b-gray-400 rounded-lg p-4 col-span-1 h-36 flex flex-col justify-between text-[#271E5D]">
             
            </div>
          </div>
        </div>

        <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 gap-4 p-4">
          <img src={Chart} alt="" className="w-full h-auto" />
          <img src={Chart} alt="" className="w-full h-auto" />
        </div>
      </div>
      
      

      
    </>
  );
}

export default NDHDeshbboard
