import React, { useState } from "react"
import { TextField, Button, Box } from "@mui/material"
import logo from "./../../assets/images/astha-brand-logo.png"
import "./LoginForm.css" // Reusing the same styles as LoginForm
import { useNavigate } from "react-router-dom"
import { useLocation } from "react-router-dom";
import { errorToast, successToast } from "../../utils/toastConfig";
import axios from "axios"
import { BASE_URL } from "../../utils/urlConfig"

const ChangePasswordForm = ({ setPassword, otp, email }) => {
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const navigate=useNavigate()
  const location = useLocation();
  const data = location.data;
  console.log(data)

  const handleSubmit =  async (e) => {
    e.preventDefault()
    if (newPassword === confirmPassword) {
      
      const jsonData = {
        email: email,
        otp:otp,
        newPassword: newPassword
      };
      try {
        await axios.post(
        `${BASE_URL}/auth/resetpassword`,
        jsonData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      successToast("password Changed succesfuly!")
      navigate("/login")
      
    } catch (error) {
      errorToast("Something went wrong!")
    }
    
  } else {
    errorToast("Passwords do not match!")
  }

  }
  const handlelogin=()=>{
    navigate("/login");
  }
  return (
    <Box className="login-container">
      <Box className="login-form">
        <div className="logo">
          <img
            src={logo}
            alt="astha-logo"
          />
        </div>
        <form onSubmit={handleSubmit}>
          <TextField
            className="textarea"
            placeholder="Enter New Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            className="textarea"
            placeholder="Confirm New Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            className="login-button"
          >
            Change Password
          </Button>
          <p
            className="back-to-login cursor-pointer"
            onClick={handlelogin}
          >
            Back to Login
          </p>
        </form>
      </Box>
    </Box>
  )
}

export default ChangePasswordForm
