import React, { useState } from "react";
import DatePicker from "react-datepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import avatar from "../images/Avtar (1).svg";
import DescriptionIcon from "@mui/icons-material/Description";
import "react-datepicker/dist/react-datepicker.css";

const initialFile = [
  {
    id: 1,
    name: "John Doe",
    age: 7,
    sex: "Male",
    cpName: "John's Clinic",
    orderId: "ID000745",
    deliveryId: "DELV00876",
    deliveryDate: "01/10/2024",
    document: "",
    status: "Delivered", // New field to represent order status
  },
  {
    id: 2,
    name: "Jane Doe",
    age: 7,
    sex: "Female",
    cpName: "Jane's Clinic",
    orderId: "ID000746",
    deliveryId: "DELV00877",
    deliveryDate: "01/10/2024",
    document: "",
    status: "Out for Delivery",
  },
];

function MyOrders() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showFile, setShowfile] = useState(initialFile);

  const handleFile = (e, patientId) => {
    const file = e.target.files[0];
    if (file) {
      const updatedPatients = showFile.map((patient) =>
        patient.id === patientId ? { ...patient, document: file.name } : patient
      );
      setShowfile(updatedPatients);
    }
  };

  //   const getProgressWidth = (status) => {
  //     switch (status) {
  //       case "Order Placed":
  //         return "w-1/5";
  //       case "Confirmed":
  //         return "w-2/5";
  //       case "Dispatched":
  //         return "w-3/5";
  //       case "Out for Delivery":
  //         return "w-4/5";
  //       case "Delivered":
  //         return "w-full";
  //       default:
  //         return "w-1/5";
  //     }
  //   };

  return (
    <>
      {/* Page Header */}
      <div className="ml-4 font-bold text-xl font-display text-gray-700 underline decoration-gray-500">
        My Orders
      </div>

      <div className="shadow-sm grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-7 p-4 h-auto justify-items-center border rounded-xl bg-gray-100">
        {/* Heading */}
        <div className="col-span-1 lg:col-span-1 ml-6 underline text-gray-600 font-bold text-center lg:text-left mb-2 lg:mb-0">
          Delivery Analytics
        </div>

        {/* Statistics */}
        <div className="grid grid-cols-3 col-span-1 lg:col-span-1 gap-2 text-center mb-2 lg:mb-0">
          <div className="flex flex-col items-center border rounded-lg px-2 py-1 bg-[#8ADBCA] text-lg">
            <span className="font-semibold text-[#0E044A] underline">06</span>
            <span className="inline-block text-[#0E044A] font-medium text-[12px]">
              Total Orders
            </span>
          </div>
          <div className="flex flex-col items-center border rounded-lg px-2 py-1 bg-[#7CA7FC40] text-lg">
            <span className="font-semibold text-[#0E044A] underline">01</span>
            <span className="inline-block text-[#0E044A] font-medium text-[12px]">
              Delivered Orders
            </span>
          </div>
          <div className="flex flex-col items-center border rounded-lg px-2 py-1 bg-[#B34C6540] text-lg">
            <span className="font-semibold text-[#0E044A] underline">05</span>
            <span className="inline-block text-[#0E044A] font-medium text-[12px]">
              Pending Orders
            </span>
          </div>
        </div>

        {/* Date Picker */}
        <div className="col-span-1 lg:col-span-1 flex items-center justify-center lg:justify-end mt-4 lg:mt-0">
          <CalendarMonthIcon className="mr-2" />
          <DatePicker
            selected={selectedDate}
            // onChange={handleDateChange}
            className="rounded-md bg-white p-1 border border-gray-300"
            placeholderText="Select Date"
            dateFormat="yyyy-MM-dd" // Set display format to match "YYYY-MM-DD"
          />
        </div>
      </div>
      {/* Order List Section */}
      <div className="font-xl font-semibold underline font-display text-gray-800 mb-2 mt-5 sm:mb-0">
        Order List
      </div>

      {showFile.map((patient) => (
        <div
          key={patient.id}
          className="shadow-md rounded-lg bg-white p-4 mb-4 mt-5"
        >
          {/* Order Details */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-center mb-4">
            <div className="flex items-center justify-center md:justify-start">
              <img src={avatar} alt="" className="w-16 h-16 rounded-full" />
            </div>
            <div>
              <p className="font-semibold">Name: {patient.name}</p>
              <p>Age: {patient.age}</p>
              <p>Sex: {patient.sex}</p>
              <p>CPName: {patient.cpName}</p>
            </div>
            <div>
              <p>Order ID: {patient.orderId}</p>
              <p>Delivery ID: {patient.deliveryId}</p>
              <p>Delivery: {patient.deliveryDate}</p>
            </div>
            <div className="flex items-center">
              <DescriptionIcon />
              <label
                htmlFor={`document-upload-${patient.id}`}
                className="ml-2 cursor-pointer text-blue-600 underline"
              >
                {patient.document || "Upload"}
              </label>
              <input
                type="file"
                id={`document-upload-${patient.id}`}
                className="hidden"
                onChange={(e) => handleFile(e, patient.id)}
              />
            </div>
          </div>

          {/* Order Tracking Line */}
          <div className="mt-4 px-4 py-2 rounded-lg border shadow-sm bg-gray-50">
            <div className="flex justify-between items-center mb-2">
              <span className="font-bold text-sm">
                Order Status: {patient.status}
              </span>
              <button className="text-blue-600 border rounded-md px-3 py-1 hover:bg-blue-50">
                Track Order
              </button>
            </div>

            {/* Tracking Line */}
            <div className="relative h-2 bg-gray-200 rounded-full mt-4">
              <div
                className={`absolute h-2 bg-blue-600 rounded-full ${
                  patient.status === "Delivered"
                    ? "w-full"
                    : patient.status === "Out for Delivery"
                    ? "w-3/4"
                    : "w-1/2"
                }`}
              ></div>
              <div className="absolute top-1/2 -translate-y-1/2 left-0 w-4 h-4 bg-blue-600 rounded-full text-white flex items-center justify-center">
                ✓
              </div>
              <div className="absolute top-1/2 -translate-y-1/2 left-1/2 w-4 h-4 bg-blue-600 rounded-full text-white flex items-center justify-center">
                ✓
              </div>
              <div
                className={`absolute top-1/2 -translate-y-1/2 left-3/4 w-4 h-4 ${
                  patient.status === "Delivered" ||
                  patient.status === "Out for Delivery"
                    ? "bg-blue-600 text-white"
                    : "bg-gray-400 text-gray-600"
                } rounded-full flex items-center justify-center`}
              >
                ✓
              </div>
              <div
                className={`absolute top-1/2 -translate-y-1/2 right-0 w-4 h-4 ${
                  patient.status === "Delivered"
                    ? "bg-blue-600 text-white"
                    : "bg-gray-400 text-gray-600"
                } rounded-full flex items-center justify-center`}
              >
                ✓
              </div>
            </div>

            {/* Order Status Labels */}
            <div className="flex justify-between text-xs mt-2">
              <span>Order Placed</span>
              <span>Confirmed</span>
              <span>Dispatched</span>
              <span>Out for Delivery</span>
              <span>Delivered</span>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default MyOrders;
