import React, { useEffect, useState } from 'react';
import Avatar from './images/Avtar.svg';
import Vector from './images/Vector.svg';
import Chart from './images/Group 47.svg';
import Map from './images/Bg.svg';
import { Link, Outlet, useNavigate } from 'react-router-dom'; // <-- import Link for navigation
import { useSelector } from "react-redux";
import axios from "axios"
import { BASE_URL } from "../../../utils/urlConfig"

function Cf_Cp_Dashboard() {
    const [user, setUser]=useState();
    const [pic, setPic]=useState();

    const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");

    useEffect(() => {
        axios
          .get(`${BASE_URL}/auth/user/data`, {
            headers: {
              Authorization: `${token}`,
            },
          })
          .then((res) => {
            console.log(res.data);
            const data = res.data;
    
            const calculateAge = (dob) => {
              const [day, month, year] = dob.split("/").map(Number);
              const birthDate = new Date(year, month - 1, day);
              const today = new Date();
              let age = today.getFullYear() - birthDate.getFullYear();
              const monthDiff = today.getMonth() - birthDate.getMonth();
    
              // Adjust age if the birthday hasn't occurred yet this year
              if (
                monthDiff < 0 ||
                (monthDiff === 0 && today.getDate() < birthDate.getDate())
              ) {
                age--;
              }
              return age;
            };
    
            setUser({
              ...data,
              age: calculateAge(data.dob), // Add calculated age
            });
            console.log();
            setPic(
              data.photo
                ? `${BASE_URL}/api/image/${
                    data.photo.startsWith("/") ? data.photo.slice(1) : data.photo
                  }`
                : ""
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }, [token]);



    return (
        <>
            <div className='p-4 md:p-6 lg:p-1'>
                <h1 className='font-mono text-xl font-bold text-gray-700 underline md:text-start sm:text-center mb-8'>
                    CF-CP Dashboard
                </h1>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6'>
                    {/* CF's Info Card */}
                    <div className='col-span-1 xs:flex-col sm:col-span-2 lg:col-span-2 bg-gray-200 border border-gray-400 h-auto rounded-lg flex items-center p-4 overflow-hidden flex-col sm:flex-row'>
                        <img src={Avatar} alt="Avatar" className='h-16 w-16 mb-4 sm:mb-0 sm:mr-4 flex-shrink-0' />
                        <div className='text-center sm:text-left'>
                            <span className='underline decoration-slate-600 text-gray-800 font-serif font-medium text-lg block'>
                            {user.first_name} {user.middle_name}{" "}
                            {user.last_name}
                            </span>
                            <span className='underline decoration-gray-600 text-gray-800 font-medium text-md block'>
                                +91 {user.mobile}
                            </span>
                        </div>
                    </div>

                    {/* View Consultation List Card */}
                    <div className='relative bg-gray-200 border border-gray-400 h-36 rounded-lg flex flex-col justify-center items-center p-4'>
                        <div className='relative'>
                            <img src={Vector} alt="Vector" className='h-12 w-12' />
                            <div className='absolute -top-2 -right-2 bg-red-700 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center'>
                                15
                            </div>
                        </div>
                        <span className='underline decoration-gray-600 text-[#3E366E] font-semibold text-center mt-2'>
                            View Consultation List
                        </span>
                    </div>

                    {/* Consultant Provider Active Card */}
                    <div className='bg-gray-200 h-36 rounded-lg flex flex-col justify-center items-center border border-gray-400 p-4'>
                        <span className='underline decoration-gray-600 text-4xl text-black font-semibold'>
                            20
                        </span>
                        <span className='underline decoration-gray-600 text-[#3E366E] font-semibold text-center mt-2'>
                            Consultant Provider Active
                            {/* <Link to='/consultantprovider' onClick={showConsultantProvider}> Consultant Provider Active</Link> */}
                        </span>
                    </div>
                </div>
            </div>

            <div className='p-4 md:p-6 lg:p-8'>
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-6'>
                    <div className='flex justify-center'>
                        <img src={Map} alt="Map" className='w-full h-auto' />
                    </div>
                    <div className='flex justify-center'>
                        <img src={Chart} alt="Chart" className='w-full h-auto' />
                    </div>
                </div>
            </div>

        </>
    );
}

export default Cf_Cp_Dashboard;
