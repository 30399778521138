import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import DescriptionIcon from "@mui/icons-material/Description";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import axios from "axios";
import EditPatient from "../dashboard/EditPatient";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/urlConfig"

function PatientDashboard() {
  // pagination
  const [page, setPage] = React.useState(1);
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const [editPatientid, setEditPatientid] = useState();
  const [showEditPatient, setShowEditPatient] = useState(false);

  // State to keep track of patient data including documents
  const [patients, setPatients] = useState([]);
  const [doc, setDoc] = useState();
  const token =
  useSelector((state) => state.auth.token) || localStorage.getItem("token");
  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/api/patient`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        console.log(res.data)
        const updatedPatients = res.data.map((patient) => ({
          ...patient,
          dob: patient.dob ? formatDob(patient.dob) : null,
          documentUrl: patient.id_proof?.proof
            ? `${BASE_URL}/api/pdf/${
                patient.id_proof.proof.startsWith("/")
                  ? patient.id_proof.proof.slice(1)
                  : patient.id_proof.proof
              }`
            : null,
        }));
        setPatients(updatedPatients);
        console.log(updatedPatients);
      } catch (error) {
        console.error("Error fetching patients:", error);
      }
    };

    const formatDob = (dob) => {
      const date = new Date(dob);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };

    fetchPatients();
  }, []);

  const handleViewDocument = (url) => {
    if (url) {
      window.open(url, "_blank"); // Open the document in a new tab
    } else {
      alert("No document available to view.");
    }
  };

  // Function to handle file upload
  // const handleFileUpload = (e, patientId) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     // Update document name in the patients array
  //     const updatedPatients = patients.map((patient) =>
  //       patient.id === patientId ? { ...patient, document: file.name } : patient
  //     );
  //     setPatients(updatedPatients);
  //   }
  // };

  const [searchTerm, setSearchTerm] = useState("");

  const filteredPatients = patients.filter(
    (patient) =>
      patient.id.toString().includes(searchTerm) ||
      patient.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const patientsPerPage = 15;
  const paginatedPatients = filteredPatients.slice(
    (page - 1) * patientsPerPage,
    page * patientsPerPage
  );
  return (
    <>
      <div
        className={`
      
        ${showEditPatient ? "blur-md" : ""}
        
        `}
      >
        <div className=" sm:flex">
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: "#454545",
              fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
              textDecoration: "underline",
              textDecorationColor: "#454534",
              textDecorationThickness: "1px",
            }}
          >
            Patient Dashboard
          </Typography>
          <div className="sm:ml-auto">
  <span className="relative">
    <SearchIcon
      className="absolute left-3 top-1/2 transform -translate-y-1/2"
      style={{ fontSize: "30px", color: "gray" }}
    />
    <input
      type="text"
      placeholder="Search by Patient Details..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      className="pl-12 pr-4 py-1 rounded-full border-2 border-gray-400 w-[30vh] 320px:w-[40vh]"
    />
  </span>
</div>

        </div>

        <div className="mt-10">
          <table className="min-w-full border-collapse border border-gray-300 bg-white">
            <thead>
              <tr className="bg-[#0E044A] border-2 border-gray-800 text-white">
                <th className="px-4 py-2">Patient ID</th>
                <th className="px-4 py-2">Patient Name</th>
                <th className="px-4 py-2">Contact</th>
                <th className="px-4 py-2">Gender</th>
                <th className="px-4 py-2">Date of Birth</th>
                <th className="px-4 py-2">Documents</th>
              </tr>
            </thead>
            <tbody>
              {/* Mapping through the filtered patient data */}
              {paginatedPatients.map((patient) => (
                <tr
                  key={patient.id}
                  className="text-center border-2 border-gray-900"
                >
                  <td
                    className="border-r border-gray-900 px-4 py-2 cursor-pointer"
                    onClick={() => {
                      setEditPatientid(patient.id);
                      setShowEditPatient(true);
                    }}
                  >
                    {patient.id}
                  </td>
                  <td className="border-r border-gray-900 px-4 py-2">
                    {patient.name}
                  </td>
                  <td className="border-r border-gray-900 px-4 py-2">
                    {patient.mobile}
                  </td>
                  <td className="border-r border-gray-900 px-4 py-2">
                    {patient.gender}
                  </td>
                  <td className="border-r border-gray-900 px-4 py-2">
                    {patient.dob}
                  </td>
                  <td className="px-4 py-2">
                    <DescriptionIcon className="mr-2" />
                    <label
                      htmlFor={`document-upload-${patient.refId}`}
                      className="text-blue-500 underline cursor-pointer"
                      onClick={() => handleViewDocument(patient.documentUrl)}
                    >
                      {patient.documentUrl
                        ? "View Document"
                        : "Upload Document"}
                    </label>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Stack spacing={2} className="flex items-end mt-4">
          <Pagination
            count={Math.ceil(filteredPatients.length / patientsPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
          />
        </Stack>
      </div>
      {showEditPatient && (
        <div className="xl:ml-[110px] ">
          <EditPatient
            editPatientid={editPatientid}
            setShowEditPatient={setShowEditPatient}
          />
        </div>
      )}
    </>
  );
}

export default PatientDashboard;
