import * as React from "react"
import PropTypes from "prop-types"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
// import Divider from '@mui/material/Divider';
// import { Typography } from "@mui/material";
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
// import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import MenuIcon from "@mui/icons-material/Menu"
import Toolbar from "@mui/material/Toolbar"
import logo from "./../assets/Astha web app/Logo.svg"
import settings from "./../assets/Astha web app/Settings.svg"
import notification from "./../assets/Astha web app/Notification.svg"
import account from "./../assets/Astha web app/Accounts.svg"
import dashboardicon from "./../assets/Astha web app/Ellipse 3.svg"
import employeeicon from "./../assets/Astha web app/Ellipse 4.svg"
import storeicon from "./../assets/Astha web app/Ellipse 6.svg"
import drugsicon from "./../assets/Astha web app/Ellipse 7.svg"
import labtesticon from "./../assets/Astha web app/Ellipse 8.svg"
import zoneicon from "./../assets/Astha web app/Ellipse 35.svg"
// import styles from "./Sidebar.module.css";
import Button from "@mui/material/Button" // Import Button from MUI
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux" // Import useDispatch hook
import { logOut } from "../features/auth/authSlice" // Import the logOut action

const drawerWidth = 220

function Sidebar(props) {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [isClosing, setIsClosing] = React.useState(false)
  const [currentTime, setCurrentTime] = React.useState("")

  const navigate = useNavigate()
  const dispatch = useDispatch() // Initialize useDispatch hook
  const location = useLocation()

  // Handle logout functionality
  const handleLogout = () => {
    dispatch(logOut()) // Dispatch the logOut action to clear auth state
    navigate("/login") // Navigate to the login page
  }

  const handleDrawerClose = () => {
    setIsClosing(true)
    setMobileOpen(false)
  }

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false)
  }

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen)
    }
  }

  React.useEffect(() => {
    const updateTime = () => {
      const now = new Date()
      const formattedTime = now.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })
      // const formattedDate = now.toLocaleDateString()
      const formattedDate =
        ("0" + now.getDate()).slice(-2) +
        "-" +
        ("0" + (now.getMonth() + 1)).slice(-2) +
        "-" +
        now.getFullYear()
      setCurrentTime(`${formattedTime} | ${formattedDate}`)
    }
    updateTime()
    const intervalId = setInterval(updateTime, 1000)
    return () => clearInterval(intervalId)
  }, [])
  const items = [
    {
      text: "DashBoard",
      icon: dashboardicon,
      route: "/home",
    },
    {
      text: "Add Employee",
      icon: employeeicon,
      route: "/EmployeeDetails",
    },
    {
      text: "Add Store",
      icon: storeicon,
      route: "/StoreDetails",
    },
    {
      text: "Add Drugs",
      icon: drugsicon,
      route: "/DrugDetails",
    },
    {
      text: "Add Lab tests",
      icon: labtesticon,
      route: "/LabTestsDetails",
    },
    {
      text: "Add Zones",
      icon: zoneicon,
      route: "/ZoneDetails",
    },
  ]

  const drawer = (
    <div>
      <Toolbar />
      <List>
        {" "}
        {items.map(({ text, icon, route }) => (
          <ListItem
            key={text}
            disablePadding
          >
            {/* <ListItemButton onClick={() => navigate(route)}> */}
            <ListItemButton
              onClick={() => navigate(route)}
              sx={{
                boxShadow:
                  location.pathname === route
                    ? "0px 4px 12px rgba(0, 0, 0, 0.3)"
                    : "none", // Apply shadow if the route is selected
                borderRadius: location.pathname === route ? "8px" : "0px", // Rounded corners when selected
                backgroundColor:
                  location.pathname === route ? "#f8f8f854" : "transparent", // Slight background color change
                color: location.pathname === route ? "#ffffff" : "#ffffff", // Highlight text color
                marginBottom: "10px", // Add space between items
              }}
            >
              <ListItemIcon>
                <img
                  src={icon}
                  alt={text}
                />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}{" "}
      </List>
    </div>
  )

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#0e044a",
          width: { sm: ` calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        {/* <Toolbar> */}
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            alignItems: "left",
            marginLeft: "1%",
            mr: 2,
            display: { sm: "none" },
          }}
        >
          <MenuIcon />
        </IconButton>
        {/* </Toolbar> */}
        <Box
          sx={{
            display: "flex",
            alignItems: "right",
            justifyContent: "right",
            marginRight: "1%",
          }}
        >
          {currentTime}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
          }}
        >
          <IconButton color="inherit">
            <img
              src={notification}
              alt="Notifications"
            />
          </IconButton>
          <IconButton color="inherit">
            <img
              src={settings}
              alt="Settings"
            />
          </IconButton>
          <IconButton color="inherit">
            <img
              src={account}
              alt="Account"
            />
          </IconButton>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              color="inherit"
              onClick={handleLogout}
              sx={{ textTransform: "none", fontWeight: "bold" }}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </AppBar>
      <Box
        component="nav"
        sx={{
          backgroundColor: "#0e044a",
          height: "100px",
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              backgroundColor: "#0e044a",
              color: "#ffffff",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              backgroundColor: "#0e044a",
              color: "#ffffff",
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          <Box sx={{ margin: "10%" }}>
            <img
              src={logo}
              alt="logo"
            />
          </Box>
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
      </Box>
     
    </Box>
  )
}

Sidebar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
}

export default Sidebar
