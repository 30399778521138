import React, { useState } from "react"
import { TextField, Button, Box } from "@mui/material"
import logo from "./../../assets/images/astha-brand-logo.png"
import { useNavigate } from "react-router-dom" 
import "./LoginForm.css" // Reusing the same styles as LoginForm
import { useLocation } from "react-router-dom";

const EnterOtpForm = ({ setOtp, setShowotp,setPassword }) => {
  const [o, setO] = useState("")
  const navigate=useNavigate();
  const location = useLocation();
  const jsonData = location.jsonData;
  console.log(jsonData)

  const handleSubmit = (e) => {
    e.preventDefault()
    setOtp(o)
    setShowotp(false)
    setPassword(true)
  }
  const handlelogin=()=>{
    navigate("/login");
  }

  return (
    <Box className="login-container">
      <Box className="login-form">
        <div className="logo">
          <img
            src={logo}
            alt="astha-logo"
          />
        </div>
        <form onSubmit={handleSubmit}>
          <TextField
            className="textarea"
            placeholder="Enter OTP"
            variant="outlined"
            fullWidth
            margin="normal"
            value={o}
            onChange={(e) => setO(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            className="login-button"
          >
            Enter OTP
          </Button>
          <Button
            className="back-to-login"
            onClick={handlelogin}
          >
            Back to Login
          </Button>
        </form>
      </Box>
    </Box>
  )
}

export default EnterOtpForm
