import React,{useState} from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CrossIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { warnToast } from "../../../utils/toastConfig";
import { useSelector } from "react-redux";

const ExistingPatientList = ({
  existingPatients,
  setEditPatientid,
  setShowEditPatient,
  setShowExistingPatientList,
  setShowConsultation,
  setConsultationid,
  setShowexisting
}) => {
  const patients = existingPatients.data;
  const [selectedPatientId, setSelectedPatientId] = useState(null);


  const handleEdit = (patientId) => {
    setEditPatientid(patientId);
    setShowEditPatient(true);
    setShowExistingPatientList(false);
  };

  const handleSubmit = () => {
    if (selectedPatientId) {
      setShowExistingPatientList(false);
      setShowConsultation(true);
      setConsultationid(selectedPatientId);
    } else {
      warnToast("Please select a patient");
    }
  };

  const handleCancel = () => {
    setShowExistingPatientList(false);
  };

  const handleprev =()=>{
    setShowexisting(true)
    setShowExistingPatientList(false);

  }
  return (
    <>
      <div className="flex justify-center items-center h-screenc">

      <div className=" block mx-auto absolute top-[8rem] bg-[#ECECEC]  max-h-[650px] max-w-[1100px] rounded-lg shadow-lg p-3
      ">
        {/* xl:w-[1100px] lg:w-[800px] */}
        <CrossIcon onClick={handleCancel} className="absolute top-3 right-3 cursor-pointer" />
        <ArrowBackIcon onClick={handleprev} className="absolute top-1 left-1 cursor-pointer" />
        <p className=" text-2xl font-semibold font-display underline mt-6 ml-10">
          Matched Patients
        </p>
        <div className="overflow-auto max-h-[500px] m-2 flex justify-center flex-wrap gap-10">
          {patients && patients.length > 0 ? (
            patients.map((patient) => (
              <div key={patient.id} className="flex flex-row h-[150px]">
                <input
                  type="radio"
                  name="patient"
                  id={`patient-${patient.id}`}
                  value={patient.id}
                  className="mr-4 p-5"
                  onChange={() => setSelectedPatientId(patient.id)}
                />
                <label
                  htmlFor={`patient-${patient.id}`}
                  className="flex flex-col p-3 m-2 min-w-[400px] bg-white rounded-lg shadow-lg relative"
                >
                  <h2 className="text-lg font-semibold pb-1 pl-2">{patient.name}</h2>
                  <p>{patient.id}</p>
                  <p>
                    {patient.gender} |{" "}
                    {new Date(patient.dob).toLocaleDateString()}
                  </p>
                  <p>
                    {patient.address.city_block}, {patient.address.district},{" "}
                    {patient.address.state}
                  </p>
                  <p>{patient.mobile}</p>
                  <EditIcon
                    className="cursor-pointer absolute top-2 right-2"
                    onClick={() => handleEdit(patient.id)}
                  />
                </label>
              </div>
            ))
          ) : (
            <p>No patients found</p>
          )}
        </div>

        <div className="mt-4 pr-6 flex flex-row-reverse items-center">
          <button
          onClick={handleSubmit}
          className="px-4 ml-4 py-2 bg-blue-500 text-white rounded">
            Submit
          </button>
          <button
          onClick={handleCancel} 
          className="mr-4 px-4 ml-3 py-2 bg-gray-500 text-white rounded">
            Cancel
          </button>
        </div>
      </div>
      </div>
    </>
  );
};

export default ExistingPatientList;
