import * as React from "react"
import { useState, useEffect } from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import { errorToast, successToast } from "../../../utils/toastConfig"
import axios from "axios"
import { BASE_URL } from "../../../utils/urlConfig"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  height: 410,
  bgcolor: "background.paper",
  borderRadius: "16px",
  boxShadow: 24,
  p: 4,
}

const group = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  input: {
    color: "#777",
    minWidth:"55%",
    height:"100%",
    backgroundColor: "#f5f5f5",
    border: "1px solid #f5f5f5",
    borderRadius: "5px",
  },
  label: {
    color: "#454545",
  },
  select: {
    minWidth:"55%",
    height:"100%",
    color: "#777",
    backgroundColor: "#f5f5f5",
    border: "1px solid #f5f5f5",
    borderRadius: "5px",
    overflowY: "hidden",
    maxHeight:"150px",
    display:"inline-block",
  },
  option:{
    maxHeight:"100px",  
    boxSizing:"border-box",
  }
}

export default function EditZoneModal({ zone, open, onClose, onZoneUpdate}) {

  const [zoneName, setZoneName] = useState("")
  const [selectedStateId, setSelectedStateId] = useState("") // To store selected state_id
  const [district, setDistrict] = useState("")
  const [zipcode, setZipcode] = useState("")
  const [states, setStates] = useState([]) // To store all states from API
  const [viewMode, setViewMode]=useState(true)  // Start in view mode



  // Fetch states from API
  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/states`)
        setStates(response.data)
      } catch (error) {
        console.error("Error fetching states:", error)
      }
    }

    fetchStates()

    if (zone) {
        setZoneName(zone.zone_name)
        setDistrict(zone.district)
        setZipcode(zone.zipcode)
        switch (zone.state_name) {
            case "Andhra Pradesh":
                setSelectedStateId(1);
                break;
            case "Arunachal Pradesh":
                setSelectedStateId(2);
                break;
            case "Assam":
                setSelectedStateId(3);
                break;
            case "Bihar":
                setSelectedStateId(4);
                break;
            case "Chandigarh":
                setSelectedStateId(5);
                break;
            case "Chhattisgarh":
                setSelectedStateId(6);
                break;
            case "Delhi":
                setSelectedStateId(7);
                break;
            case "Goa":
                setSelectedStateId(8);
                break;
            case "Gujarat":
                setSelectedStateId(9);
                break;
            case "Haryana":
                setSelectedStateId(10);
                break;
            case "Himachal Pradesh":
                setSelectedStateId(11);
                break;
            case "Jharkhand":
                setSelectedStateId(12);
                break;
            case "Karnataka":
                setSelectedStateId(13);
                break;
            case "Kerala":
                setSelectedStateId(14);
                break;
            case "Madhya Prades":
                setSelectedStateId(15);
                break;
            case "Maharashtra":
                setSelectedStateId(16);
                break;
            case "Manipur":
                setSelectedStateId(17);
                break;
            case "Meghalaya":
                setSelectedStateId(18);
                break;
            case "Mizoram":
                setSelectedStateId(19);
                break;
            case "Nagaland":
                setSelectedStateId(20);
                break;
            case "Odisha":
                setSelectedStateId(21);
                break;
            case "Punjab":
                setSelectedStateId(22);
                break;
            case "Rajasthan":
                setSelectedStateId(23);
                break;
            case "Sikkim":
                setSelectedStateId(24);
                break;
            case "Tamil Nadu":
                setSelectedStateId(25);
                break;
            case "Telangana":
                setSelectedStateId(26);
                break;
            case "Tripura":
                setSelectedStateId(27);
                break;
            case "Uttar Pradesh":
                setSelectedStateId(28);
                break;
            case "Uttarakhand":
                setSelectedStateId(29);
                break;
            case "West Bengal":
                setSelectedStateId(30);
                break;
            
        }
    }
  }, [zone])
 console.log(zone);
 
  const handleSubmit = async () => {
    // Validate form inputs
    if (!zoneName || !selectedStateId || !district || !zipcode) {
      errorToast("Please fill all the required fields.")
      return
    }
    else {

    // Convert selectedStateId to a number
    const stateIdNumber = Number(selectedStateId)

    // Check if the conversion was successful
    if (isNaN(stateIdNumber)) {
      console.error("Invalid stateId. It must be a number.")
      return
    }
    

    const zoneData = {
      zone_name: zoneName,
      state_id: stateIdNumber, // Use the converted number here
      district,
      zipcode,
    }

    console.log("Submitting zone data:", zoneData) // Debugging line

    try {
      const response = await axios.put(
        `${BASE_URL}/api/zones/${zone.zone_id}`,
        zoneData

       
      )
      
      successToast("Zone updated successfully:", response.data)
      
       // After successful update
       if (onZoneUpdate) {
        onZoneUpdate() // Trigger the refresh in ZoneDetails
      }
      onClose() // Close modal after success
    } catch (error) {
      errorToast("An error occurred while updating Zone data.")
      console.error(
        "Error updatinging zone data:",
        error.response ? error.response.data : error.message
      )
    }

    
  }}

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        sx={{
          backgroundColor: "#6bc6b0",
          "&:hover": {
            backgroundColor: "#6bc6b0",
          },
          minWidth: "171px",
          height: "50px",
          fontWeight: "550",
        }}
        onClick={open}
      >
        + Add Zone
      </Button>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
          >
            Edit Zone
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            <Box
              sx={{
                marginTop: "40px",
                display: "grid",
                gridTemplateColumns: "100%",
                rowGap: 4,
              }}
            >
              <div style={group}>
                <label
                  style={group.label}
                  htmlFor="state"
                >
                  State
                </label>
                <select
                  style={group.select}
                  value={selectedStateId}
                  onChange={viewMode ? null : (e) => setSelectedStateId(e.target.value)}
                >
              
                  <option value="">Select State</option>
                  {states.map((state) => (
                    <option
                    
                    style={group.option}
                      key={state.id}
                      value={state.id}
                    >
                      {state.state}
                    </option>
                  ))}
                </select>
              </div>
              <div style={group}>
                <label
                  style={group.label}
                  htmlFor="district"
                >
                  District
                </label>
                <input
                  style={group.input}
                  type="text"
                  placeholder="District"
                  value={district}
                  onChange={viewMode ? null : (e) => setDistrict(e.target.value)}
                />
              </div>
              <div style={group}>
                <label
                  style={group.label}
                  htmlFor="zipcode"
                >
                  Zip Code
                </label>
                <input
                  style={group.input}
                  type="text"
                  placeholder="Zipcode"
                  value={zipcode}
                  onChange={viewMode ? null : (e) => setZipcode(e.target.value)}
                />
              </div>
              <div style={group}>
                <label
                  style={group.label}
                  htmlFor="zoneName"
                >
                  Zone Name
                </label>
                <input
                  style={group.input}
                  type="text"
                  placeholder="Zone Name"
                  value={zoneName}
                  onChange={viewMode ? null : (e) => setZoneName(e.target.value)}
                />
              </div>
            </Box>
            <Box
              sx={{
                width: "270px",
                display: "flex",
                margin: "15% 1%",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                sx={{ borderRadius: "50px", backgroundColor: "#3F6584" }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{ borderRadius: "50px",fontWeight: "550",color: "#454545", backgroundColor: "#ead8b1" }}
                onClick={() => setViewMode(!viewMode)}
              >
                {viewMode ? "Edit" : "View"}
              </Button>
              <Button
                variant="contained"
                sx={{
                  width: "95px",
                  fontWeight: "550",
                  borderRadius: "50px",
                  backgroundColor: "#a9fcf7",
                  color: "#454545",
                }}
                onClick={handleSubmit}
                disabled={viewMode}
                
              >
                Update
              </Button>
            </Box>
          </Typography>
        </Box>
      </Modal>
    </div>
  )
}