import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import "../../Admin_CSS/EmployeeForm.css"
import uploadIcon from "../../../../assets/Astha web app/upload icon.svg"
import profileIcon from "../../../../assets/Astha web app/Rectangle 24.svg"
import { errorToast, successToast } from "../../../../utils/toastConfig"
import axios from "axios"
import Sidebar from "../../../../layout/Sidebar"
import { useNavigate } from "react-router-dom"
import { BASE_URL } from "../../../../utils/urlConfig"

// Define the initial state outside of the component
const initialEmployeeState = {
  firstName: "",
  middleName: "",
  lastName: "",
  gender: "",
  dob: "",
  email: "",
  photo: "",
  contact: "",
  eclinicId: "",
  permanentAddress: {
    line1: "",
    line2: "",
    city: "",
    district: "",
    state: "",
    zipcode: "",
  },
  presentAddress: {
    line1: "",
    line2: "",
    city: "",
    district: "",
    state: "",
    zipcode: "",
  },
  idProof: {
    type: "",
    number: "",
    document: null,
  },
}

const EditCFeclinic = () => {
  const { employeeId } = useParams()
  const navigate = useNavigate()
  const [employee, setEmployee] = useState(initialEmployeeState)
  const [profilePicture, setProfilePicture] = useState(profileIcon)
  const [sameAddress, setSameAddress] = useState(false)
  const [proofDocument, setProofDocument] = useState(null)
  const [viewMode, setViewMode] = useState(true) // Start in view mode

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        const response = await axios.get(`/api/cfeclinic/${employeeId}`)
        const data = response.data
        setEmployee({
          firstName: data.first_name,
          middleName: data.middle_name,
          lastName: data.last_name,
          gender: data.gender,
          dob: new Date(data.dob).toISOString().split("T")[0],
          email: data.email,
          contact: data.contact,
          photo: data.photo,
          permanentAddress: {
            line1: data.permanentAddress.line1,
            line2: data.permanentAddress.line2,
            city: data.permanentAddress.city,
            state: data.permanentAddress.state,
            zipcode: data.permanentAddress.zipcode,
            district: data.permanentAddress.district,
          },
          presentAddress: {
            line1: data.presentAddress.line1,
            line2: data.presentAddress.line2,
            city: data.presentAddress.city,
            state: data.presentAddress.state,
            zipcode: data.presentAddress.zipcode,
            district: data.presentAddress.district,
          },
          idProof: {
            type: data.idProof.type,
            number: data.idProof.number,
            document: data.idProof.document,
          },
          eclinicId: data.eclinic_id,
        })

        setProfilePicture(
          data.photo
            ? `${BASE_URL}/api/image/${
                data.photo.startsWith("/") ? data.photo.slice(1) : data.photo
              }`
            : profileIcon
        )
        // setProofDocument(
        //   data.proof.proof
        //     ? `${BASE_URL}/api/pdf/${
        //         data.proof.proof.startsWith("/") ? data.proof.proof.slice(1) : data.proof.proof
        //       }`
        //     : data.proof.proof
        // );

        // Check if there is a proof document in the response
        const newProofDocument = data.idProof?.document

        // Set the proof document URL using the fetched or previously set document
        setProofDocument(
          newProofDocument
            ? `${BASE_URL}/api/pdf/${
                newProofDocument.startsWith("/")
                  ? newProofDocument.slice(1)
                  : newProofDocument
              }`
            : null
        )
      } catch (error) {
        console.error("Error fetching employee details:", error)
      }
    }
    fetchEmployeeDetails()
  }, [employeeId])
  console.log(employee)
  console.log(profilePicture)
  console.log(proofDocument)

  const handleChange = (e) => {
    const { name, value } = e.target

    if (
      name.startsWith("permanentAddress.") ||
      name.startsWith("presentAddress.") ||
      name.startsWith("idProof.")
    ) {
      const [section, field] = name.split(".")

      setEmployee((prev) => ({
        ...prev,
        [section]: {
          ...prev[section],
          [field]: value,
        },
      }))
    } else if (name === "eclinicId") {
      parseInt(value, 10)
      setEmployee((prev) => ({
        ...prev,
        [name]: value,
      }))
    } else {
      setEmployee((prev) => ({
        ...prev,
        [name]: value,
      }))
    }
  }

  const handleProofDocumentChange = (e) => {
    const file = e.target.files[0]
    if (file && file.type === "application/pdf") {
      setProofDocument(file)
      setEmployee((prev) => ({
        ...prev,
        idProof: {
          ...prev.idProof,
          document: file,
        },
      }))
    } else {
      errorToast("Please upload a PDF document")
    }
  }

  const uploadFiles = async (files) => {
    const formData = new FormData()
    if (files.photo) {
      formData.append("photo", files.photo)
    }
    if (files.document) {
      formData.append("document", files.document)
    }
    try {
      const response = await axios.post("/api/upload-files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      console.log(response.data)
      return response.data
    } catch (error) {
      console.error(
        "Error uploading files:",
        error.response?.data || error.message
      )
      throw error
    }
  }

  const handleCheckboxChange = (e) => {
    setSameAddress(e.target.checked)
    if (e.target.checked) {
      setEmployee((prev) => ({
        ...prev,
        presentAddress: { ...prev.permanentAddress },
      }))
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // Start with existing proof document if available
    let proof =
      employee.idProof.document.length > 0 ? employee.idProof.document : null

    const files = {
      photo: employee.photo || null,
      document: proofDocument || null, // New document if uploaded
    }

    try {
      let fileResponse = null

      // Only upload if there's a new document or photo
      if (files.photo || files.document) {
        fileResponse = await uploadFiles(files)
      }

      // Update proof document if a new one is uploaded and URLs are available
      if (
        fileResponse &&
        fileResponse.documentUrls &&
        fileResponse.documentUrls.length > 0
      ) {
        proof = fileResponse.documentUrls
      } else {
        console.log(
          "No new document uploaded, retaining existing proof:",
          proof
        )
      }

      // Check if proof is still empty after uploads
      if (!proof || proof.length === 0) {
        errorToast("Please upload or select a proof document.")
        return
      }

      const jsonData = {
        ...employee,
        photo: fileResponse?.photoUrl || employee.photo, // Use new photo URL if uploaded
        idProof: {
          ...employee.idProof,
          document: proof, // Updated or existing proof document
        },
      }
      // Perform the API update request
      await axios.patch(
        `${BASE_URL}/api/cfeclinic/${employeeId}`,
        jsonData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )

      successToast("Employee details updated successfully!")
    } catch (error) {
      errorToast("An error occurred while updating the employee details.")
      console.error("Error:", error.response?.data || error.message)
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        setProfilePicture(reader.result)
        setEmployee((prev) => ({
          ...prev,
          photo: file,
        }))
      }
      reader.readAsDataURL(file)
    }
  }

  const handleBackButtonClick = () => {
    navigate(-1) // Go back to the previous page
  }

  return (
    <>
      <div>
        <Sidebar />
      </div>
      <div
        className="containers"
        style={{ margin: "0 18% 5%" }}
      >
        <h1 className="ah1">{employeeId ? "Edit CF-Eclinic" : "Add CF-Eclinic"}</h1>
        {/* <button
          type="button"
          className="cancel-btn"
          onClick={() => setViewMode(!viewMode)}
        >
          {viewMode ? "Edit" : "View"}
        </button> */}

        <form
          className="consultant-form"
          onSubmit={handleSubmit}
        >
          <div
            className="profile-section"
            style={{ gridTemplateColumns: "13em 14em 12em 8em" }}
          >
            <div id="profile">
              <h2>Profile</h2>
            </div>
            <div className="form-group">
              <label htmlFor="first-name">First Name</label>
              <input
                type="text"
                id="first-name"
                name="firstName"
                value={employee.firstName}
                onChange={viewMode ? null : handleChange}
                placeholder="First Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="middle-name">Middle Name</label>
              <input
                type="text"
                id="middle-name"
                name="middleName"
                value={employee.middleName}
                onChange={viewMode ? null : handleChange}
                placeholder="Middle Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="last-name">Last Name</label>
              <input
                type="text"
                id="last-name"
                name="lastName"
                value={employee.lastName}
                onChange={viewMode ? null : handleChange}
                placeholder="Last Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="gender">Gender</label>
              <select
                id="gender"
                name="gender"
                value={employee.gender}
                onChange={viewMode ? null : handleChange}
              >
                <option value="">Select</option>
                <option value="M">Male</option>
                <option value="F">Female</option>
                <option value="Others">Other</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="dob">Date of Birth</label>
              <input
                type="date"
                id="dob"
                name="dob"
                value={employee.dob}
                onChange={viewMode ? null : handleChange}
                placeholder="DD-MM-YYYY"
              />
            </div>

            <div className="form-group">
              <label htmlFor="idProof-type">ID Proof</label>
              <div>
                {!viewMode ? (
                  <select
                    id="proof-type"
                    name="idProof.type"
                    value={employee.idProof.type}
                    onChange={viewMode ? null : handleChange}
                  >
                    <option value="">Select</option>
                    <option value="1">Aadhar</option>
                    <option value="2">ABHA</option>
                    <option value="3">Voter Id</option>
                    <option value="4">Driving License</option>
                    <option value="5">Ration Card</option>
                    <option value="6">Drug License</option>
                    <option value="7">Nabl License</option>
                  </select>
                ) : (
                  <div className="tempxy">
                    {(() => {
                      switch (employee.idProof.type) {
                        case 1:
                          return <span className="id-option">Aadhar</span>
                        case 2:
                          return <span className="id-option">Aabha</span>
                        case 3:
                          return <span className="id-option">Voter id</span>
                        case 4:
                          return (
                            <span className="id-option">Driving License</span>
                          )
                        case 5:
                          return <span className="id-option">Ration Card</span>
                        case 6:
                          return <span className="id-option">Drug License</span>
                        case 7:
                          return <span className="id-option">Nabl License</span>
                        default:
                          return <span className="id-option">Not Provided</span>
                      }
                    })()}
                  </div>
                )}
                <span
                  id="span"
                  className="view-option"
                >
                  {!viewMode && (
                    <>
                      <img
                        src={uploadIcon}
                        style={{
                          filter: proofDocument
                            ? "invert(36%) sepia(76%) saturate(2696%) hue-rotate(81deg) brightness(95%) contrast(92%)"
                            : "none",
                          cursor: "pointer",
                        }}
                        alt="Upload Document"
                        onClick={() =>
                          document.getElementById("proof-document").click()
                        }
                      />
                      <input
                        type="file"
                        id="proof-document"
                        accept="application/pdf"
                        onChange={viewMode ? null : handleProofDocumentChange}
                        style={{ display: "none" }}
                      />
                    </>
                  )}

                  {proofDocument && (
                    <a
                      href={proofDocument}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        marginLeft: "10px",
                        fontSize: "x-small",
                        textDecoration: "underline",
                        color: "#777",
                      }}
                    >
                      {typeof proofDocument === "string"
                        ? "View"
                        : "doc. uploaded"}
                    </a>
                  )}

                  {!proofDocument && (
                    <span
                      style={{
                        marginLeft: "5px",
                        fontSize: "x-small",
                        textDecoration: "underline",
                        color: "#777",
                      }}
                    >
                      No doc. uploaded
                    </span>
                  )}
                </span>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={employee.email}
                onChange={viewMode ? null : handleChange}
                readOnly
                placeholder="abc@xyz.com"
              />
            </div>
            <div className="form-group">
              <label htmlFor="personal-mobile">Contact</label>
              <input
                type="text"
                id="contact"
                name="contact"
                value={employee.contact}
                onChange={viewMode ? null : handleChange}
                placeholder="Mobile No."
              />
            </div>
            <div className="form-group">
              <label htmlFor="eclinicId">Eclinic Id</label>
              <input
                type="number"
                id="eclinicId"
                name="eclinicId"
                value={employee.eclinicId}
                onChange={viewMode ? null : handleChange}
                placeholder="Eclinic Id"
              />
            </div>
            <div id="profile-picture">
              <div>
                <img
                  src={profilePicture || profileIcon}
                  alt="Profile"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    document.getElementById("profile-picture-input").click()
                  }
                />
                <span>Profile Picture</span>
                <input
                  type="file"
                  id="profile-picture-input"
                  accept="image/*"
                  onChange={viewMode ? null : handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>

          <div className="contact-address-section">
            <div id="contact-address">
              <h2>Permanent Address</h2>
            </div>
            <div className="form-group">
              <label htmlFor="permanent-address-line1">Address Line1</label>
              <input
                type="text"
                id="permanent-address-line1"
                name="permanentAddress.line1"
                value={employee.permanentAddress.line1}
                onChange={viewMode ? null : handleChange}
                placeholder="Address Line1"
              />
            </div>
            <div className="form-group">
              <label htmlFor="permanent-address-line2">Address Line2</label>
              <input
                type="text"
                id="permanent-address-line2"
                name="permanentAddress.line2"
                value={employee.permanentAddress.line2}
                onChange={viewMode ? null : handleChange}
                placeholder="Address Line2"
              />
            </div>
            <div className="form-group">
              <label htmlFor="permanent-address-city">City</label>
              <input
                type="text"
                id="permanent-address-city"
                name="permanentAddress.city"
                value={employee.permanentAddress.city}
                onChange={viewMode ? null : handleChange}
                placeholder="City"
              />
            </div>
            <div className="form-group">
              <label htmlFor="permanent-address-district">District</label>
              <input
                type="text"
                id="permanent-address-district"
                name="permanentAddress.district"
                value={employee.permanentAddress.district}
                onChange={viewMode ? null : handleChange}
                placeholder="District"
              />
            </div>
            <div className="form-group">
              <label htmlFor="permanent-address-state">State</label>
              <input
                type="text"
                id="permanent-address-state"
                name="permanentAddress.state"
                value={employee.permanentAddress.state}
                onChange={viewMode ? null : handleChange}
                placeholder="State"
              />
            </div>
            <div className="form-group">
              <label htmlFor="permanent-address-zipcode">Zipcode</label>
              <input
                type="text"
                id="permanent-address-zipcode"
                name="permanentAddress.zipcode"
                value={employee.permanentAddress.zipcode}
                onChange={viewMode ? null : handleChange}
                placeholder="Zipcode"
              />
            </div>
          </div>

          <div className="contact-address-section">
            <div id="present-address">
              <h2>Present Address</h2>
              <div>
                <label htmlFor="sameAddress">Same as Permanent Address</label>
                <input
                  type="checkbox"
                  name="sameAddress"
                  id="sameAddress"
                  checked={sameAddress}
                  onChange={viewMode ? null : handleCheckboxChange}
                />
              </div>
            </div>
            {!sameAddress && (
              <>
                <div className="form-group">
                  <label htmlFor="present-address-line1">Address Line1</label>
                  <input
                    type="text"
                    id="present-address-line1"
                    name="presentAddress.line1"
                    value={employee.presentAddress.line1}
                    onChange={viewMode ? null : handleChange}
                    placeholder="Address Line1"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="present-address-line2">Address Line2</label>
                  <input
                    type="text"
                    id="present-address-line2"
                    name="presentAddress.line2"
                    value={employee.presentAddress.line2}
                    onChange={viewMode ? null : handleChange}
                    placeholder="Address Line2"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="present-address-city">City</label>
                  <input
                    type="text"
                    id="present-address-city"
                    name="presentAddress.city"
                    value={employee.presentAddress.city}
                    onChange={viewMode ? null : handleChange}
                    placeholder="City"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="present-address-district">District</label>
                  <input
                    type="text"
                    id="present-address-district"
                    name="presentAddress.district"
                    value={employee.presentAddress.district}
                    onChange={viewMode ? null : handleChange}
                    placeholder="District"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="present-address-state">State</label>
                  <input
                    type="text"
                    id="present-address-state"
                    name="presentAddress.state"
                    value={employee.presentAddress.state}
                    onChange={viewMode ? null : handleChange}
                    placeholder="State"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="present-address-zipcode">Zipcode</label>
                  <input
                    type="text"
                    id="present-address-zipcode"
                    name="presentAddress.zipcode"
                    value={employee.presentAddress.zipcode}
                    onChange={viewMode ? null : handleChange}
                    placeholder="Zipcode"
                  />
                </div>
              </>
            )}
          </div>

          <div className="form-actions">
            <button
              type="button"
              className="cancel-btn"
              onClick={() => setViewMode(!viewMode)}
            >
              {viewMode ? "Edit" : "View"}
            </button>
            <button
              type="submit"
              className="save-btn"
              // disabled={viewMode}
              onClick={viewMode ? handleBackButtonClick : handleSubmit}
            >
              {viewMode ? "Back" : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default EditCFeclinic
