import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import "../../Admin_CSS/EmployeeForm.css"
import uploadIcon from "../../../../assets/Astha web app/upload icon.svg"
import profileIcon from "../../../../assets/Astha web app/Rectangle 24.svg"
import { errorToast, successToast } from "../../../../utils/toastConfig"
import axios from "axios"
import Sidebar from "../../../../layout/Sidebar"
import { useNavigate } from "react-router-dom"
import { BASE_URL } from "../../../../utils/urlConfig"
import VisibilityIcon from '@mui/icons-material/Visibility';
// Define the initial state outside of the component
const initialEmployeeState = {
  firstName: "",
  middleName: "",
  lastName: "",
  gender: "",
  dob: "",
  email: "",
  personalMobile: "",
  consultationMobile: "",
  photo: "",
  address: {
    line1: "",
    line2: "",
    city: "",
    district: "",
    state: "",
    zipcode: "",
  },
  proof: {
    type: "",
    number: "",
    document: null,
  },
  category: "",
  degree: "",
  specialty: "",
  subSpecialty: "",
  superSpecialty: "",
  fellowship: "",
}

const EditCP = () => {
  const { employeeId } = useParams()
  const navigate = useNavigate()
  const [employee, setEmployee] = useState(initialEmployeeState)
  const [profilePicture, setProfilePicture] = useState(profileIcon)
  const [proofDocument, setProofDocument] = useState(null)
  const [viewMode, setViewMode] = useState(true) // Start in view mode

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        const response = await axios.get(`/api/cp/${employeeId}`)
        const data = response.data
        setEmployee({
          firstName: data.first_name,
          middleName: data.middle_name,
          lastName: data.last_name,
          gender: data.gender,
          dob: new Date(data.dob).toISOString().split("T")[0],
          email: data.email,
          personalMobile: data.personal_mobile,
          consultationMobile: data.consultation_mobile,
          photo: data.photo,
          address: {
            line1: data.address.address_line1,
            line2: data.address.address_line2,
            city: data.address.city_block,
            district: data.address.district,
            state: data.address.state,
            zipcode: data.address.zipcode,
          },
          proof: {
            type: data.proof.proof_type,
            number: data.proof.proof_no,
            document: data.proof.proof,
          },
          category: data.category_id,
          degree: data.degree,
          specialty: data.specialty,
          subSpecialty: data.sub_specialty,
          superSpecialty: data.super_specialty,
          fellowship: data.fellowship,
        })

        setProfilePicture(
          data.photo
            ? `${BASE_URL}/api/image/${
                data.photo.startsWith("/") ? data.photo.slice(1) : data.photo
              }`
            : profileIcon
        )

        // setProofDocument(
        //   data.proof.proof
        //     ? `${BASE_URL}/api/pdf/${
        //         data.proof.proof.startsWith("/") ? data.proof.proof.slice(1) : data.proof.proof
        //       }`
        //     : data.proof.proof
        // );

        // Check if there is a proof document in the response
        const newProofDocument = data.proof?.proof

        // Set the proof document URL using the fetched or previously set document
        setProofDocument(
          newProofDocument
            ? `${BASE_URL}/api/pdf/${
                newProofDocument.startsWith("/")
                  ? newProofDocument.slice(1)
                  : newProofDocument
              }`
            : null
        )
      } catch (error) {
        console.error("Error fetching employee details:", error)
      }
    }
    fetchEmployeeDetails()
  }, [employeeId])

  console.log(employee)
  console.log(proofDocument)

  const handleChange = (e) => {
    const { name: inputName, value } = e.target
    if (inputName.startsWith("address.")) {
      const fieldName = inputName.split(".")[1]
      setEmployee((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [fieldName]: value,
        },
      }))
    } else if (inputName.startsWith("proof.")) {
      const fieldName = inputName.split(".")[1]
      setEmployee((prev) => ({
        ...prev,
        proof: {
          ...prev.proof,
          [fieldName]:
            fieldName === "type" ? (value ? parseInt(value, 10) : null) : value,
        },
      }))
    } else {
      setEmployee((prev) => ({
        ...prev,
        [inputName]: value,
      }))
    }
  }

  const handleBackButtonClick = () => {
    navigate(-1) // Go back to the previous page
  }

  const handleProofDocumentChange = (e) => {
    const file = e.target.files[0]
    if (file && file.type === "application/pdf") {
      setProofDocument(file)
      setEmployee((prev) => ({
        ...prev,
        proof: {
          ...prev.proof,
          document: file,
        },
      }))
    } else {
      errorToast("Please upload a PDF document")
    }
  }

  const uploadFiles = async (files) => {
    const formData = new FormData()
    if (files.photo) {
      formData.append("photo", files.photo)
    }
    if (files.document) {
      formData.append("document", files.document)
    }
    try {
      const response = await axios.post("/api/upload-files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      console.log(response.data)
      return response.data
    } catch (error) {
      console.error(
        "Error uploading files:",
        error.response?.data || error.message
      )
      throw error
    }
  }

  // const checkEmptyFields = (data) => {
  //   for (const key in data) {
  //     const value = data[key]
  //     if (typeof value === "object" && value !== null) {
  //       const result = checkEmptyFields(value)
  //       if (result) return result
  //     } else {
  //       if (!value || value === "select") {
  //         return key
  //       }
  //     }
  //   }
  //   return null
  // }

  // const handleSubmit = async (e) => {
  //   e.preventDefault()
  //   // const emptyField = checkEmptyFields(employee)
  //   // if (emptyField) {
  //   //   errorToast(Please fill the ${emptyField} field.)
  //   //   return
  //   // }

  //   const files = {
  //     photo: employee.photo,
  //     document: proofDocument,
  //   }
  //   let proof = employee.proof.document
  //   try {
  //     const fileResponse = await uploadFiles(files)
  //     const { photoUrl, documentUrls } = fileResponse
  //     documentUrls ? (proof = documentUrls) : (proof = proof)

  //     const jsonData = {
  //       ...employee,
  //       photo: photoUrl,
  //       proof: {
  //         ...employee.proof,
  //         document: proof,
  //       },
  //     }
  //     console.log(jsonData)
  //     await axios.patch(
  //       ${BASE_URL}/api/cp/${employeeId},
  //       jsonData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //     successToast("Employee details updated successfully!")
  //   } catch (error) {
  //     errorToast("An error occurred while updating the employee details.")
  //     console.error("Error:", error.response?.data || error.message)
  //   }
  // }
  const handleSubmit = async (e) => {
    e.preventDefault()

    if (
      !employee.firstName ||
      !employee.lastName ||
      !employee.gender ||
      !employee.address.line1 ||
      !employee.address.city ||
      !employee.address.district ||
      !employee.address.zipcode ||
      !employee.consultationMobile ||
      !employee.dob ||
      !employee.email ||
      !employee.personalMobile ||
      !employee.photo ||
      !employee.proof.type ||
      !employee.proof.document ||
      !employee.category ||
      !employee.specialty
    ) {
      errorToast("Please Enter the required fields")
      return
    } else {
      // Start with existing proof document if available
      let proof =
        employee.proof.document.length > 0 ? employee.proof.document : null

      const files = {
        photo: employee.photo || null,
        document: proofDocument || null, // New document if uploaded
      }

      try {
        let fileResponse = null

        // Only upload if there's a new document or photo
        if (files.photo || files.document) {
          fileResponse = await uploadFiles(files)
        }

        // Update proof document if a new one is uploaded and URLs are available
        if (
          fileResponse &&
          fileResponse.documentUrls &&
          fileResponse.documentUrls.length > 0
        ) {
          proof = fileResponse.documentUrls
        } else {
          console.log(
            "No new document uploaded, retaining existing proof:",
            proof
          )
        }

        // Check if proof is still empty after uploads
        if (!proof || proof.length === 0) {
          errorToast("Please upload or select a proof document.")
          return
        }

        const jsonData = {
          ...employee,
          photo: fileResponse?.photoUrl || employee.photo, // Use new photo URL if uploaded
          proof: {
            ...employee.proof,
            document: proof, // Updated or existing proof document
          },
        }
        // Perform the API update request
        await axios.patch(
          `${BASE_URL}/api/cp/${employeeId}`,
          jsonData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )

        successToast("Employee details updated successfully!")
      } catch (error) {
        errorToast("An error occurred while updating the employee details.")
        console.error("Error:", error.response?.data || error.message)
      }
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        setProfilePicture(reader.result)
        setEmployee((prev) => ({
          ...prev,
          photo: file,
        }))
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <>
      <div>
        <Sidebar />
      </div>
      <div
        className="containers"
        style={{ margin: "0 18% 5%" }}
      >
        <h1 className="ah1">
          {employeeId ? "Edit Consultant Provider" : "Add Consultant Provider"}
        </h1>
        {/* <button
          type="button"
          className="cancel-btn"
          onClick={() => setViewMode(!viewMode)}
        >
          {viewMode ? "Edit" : "View"}
        </button> */}

        <form
          className="consultant-form"
          onSubmit={handleSubmit}
        >
          <div className="profile-section">
            <div id="profile">
              <h2>Profile</h2>
            </div>
            <div className="form-group">
              <label htmlFor="first-name">First Name</label>
              <input
                type="text"
                id="first-name"
                name="firstName"
                value={employee.firstName}
                onChange={viewMode ? null : handleChange}
                placeholder="First Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="middle-name">Middle Name</label>
              <input
                type="text"
                id="middle-name"
                name="middleName"
                value={employee.middleName}
                onChange={viewMode ? null : handleChange}
                placeholder="Middle Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="last-name">Last Name</label>
              <input
                type="text"
                id="last-name"
                name="lastName"
                value={employee.lastName}
                onChange={viewMode ? null : handleChange}
                placeholder="Last Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="gender">Gender</label>
              <select
                id="gender"
                name="gender"
                value={employee.gender}
                onChange={viewMode ? null : handleChange}
              >
                <option value="select">Select</option>
                <option value="M">Male</option>
                <option value="F">Female</option>
                <option value="Others">Other</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="dob">Date of Birth</label>
              <input
                type="date"
                id="dob"
                name="dob"
                value={employee.dob}
                onChange={viewMode ? null : handleChange}
                placeholder="DD-MM-YYYY"
              />
            </div>
            <div className="form-group">
              <label htmlFor="proof-type">ID Proof</label>
              <div>
                {!viewMode ? (
                  <select
                    id="proof-type"
                    name="proof.type"
                    value={employee.proof.type}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="1">Aadhar</option>
                    <option value="2">ABHA</option>
                    <option value="3">Voter Id</option>
                    <option value="4">Driving License</option>
                    <option value="5">Ration Card</option>
                    <option value="6">Drug License</option>
                    <option value="7">Nabl License</option>
                  </select>
                ) : (
                  <div className="tempxy w-[98px]">
                    {(() => {
                      switch (employee.proof.type) {
                        case 1:
                          return <span className="id-option">Aadhar</span>
                        case 2:
                          return <span className="id-option">ABHA</span>
                        case 3:
                          return <span className="id-option">Voter ID</span>
                        case 4:
                          return (
                            <span className="id-option">Driving License</span>
                          )
                        case 5:
                          return <span className="id-option">Ration Card</span>
                        case 6:
                          return <span className="id-option">Drug License</span>
                        case 7:
                          return <span className="id-option">Nabl License</span>
                        default:
                          return <span className="id-option">Not Provided</span>
                      }
                    })()}
                  </div>
                )}

                <span
                  id="span"
                  className="view-option"
                >
                  {!viewMode && (
                    <>
                      <img
                        src={uploadIcon}
                        style={{
                          filter: proofDocument
                            ? "invert(36%) sepia(76%) saturate(2696%) hue-rotate(81deg) brightness(95%) contrast(92%)"
                            : "none",
                          cursor: "pointer",
                        }}
                        alt="Upload Document"
                        onClick={() =>
                          document.getElementById("proof-document").click()
                        }
                      />
                      <input
                        type="file"
                        id="proof-document"
                        accept="application/pdf"
                        onChange={viewMode ? null : handleProofDocumentChange}
                        style={{ display: "none" }}
                      />
                    </>
                  )}

                  {proofDocument && (
                    <a
                      href={proofDocument}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        marginLeft: "5px",
                        fontSize: "x-small",
                        textDecoration: "underline",
                        color: "#777",
                      }}
                    >
                      {typeof proofDocument === "string"
                        ? <VisibilityIcon style={{ fontSize: "15px" }} />
                        : "doc. uploaded"}
                    </a>
                  )}


                  {!proofDocument && (
                    <span
                      style={{
                        marginLeft: "5px",
                        fontSize: "x-small",
                        textDecoration: "underline",
                        color: "#777",
                      }}
                    >
                      No doc. uploaded
                    </span>
                  )}
                </span>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={employee.email}
                readOnly
                placeholder="abc@xyz.com"
              />
            </div>
            <div className="form-group">
              <label htmlFor="personal-mobile">Personal Mobile</label>
              <input
                type="text"
                id="personal-mobile"
                name="personalMobile"
                value={employee.personalMobile}
                onChange={viewMode ? null : handleChange}
                placeholder="Personal Mob."
              />
            </div>
            <div className="form-group">
              <label htmlFor="consultation-mobile">Consultation Mobile</label>
              <input
                type="text"
                id="consultation-mobile"
                name="consultationMobile"
                value={employee.consultationMobile}
                onChange={viewMode ? null : handleChange}
                placeholder="Consultation Mob."
              />
            </div>
            <div id="profile-picture">
              <div>
                <img
                  src={profilePicture ? profilePicture : profileIcon}
                  alt=""
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    document.getElementById("profile-picture-input").click()
                  }
                />
                <span>Profile Picture</span>
                <input
                  type="file"
                  id="profile-picture-input"
                  accept="image/*"
                  onChange={viewMode ? null : handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>

          <div className="contact-address-section">
            <div id="contact-address">
              <h2>Contact Address</h2>
            </div>
            <div className="form-group">
              <label htmlFor="address-line1">Address Line 1</label>
              <input
                type="text"
                id="address-line1"
                name="address.line1"
                value={employee.address.line1}
                onChange={viewMode ? null : handleChange}
                placeholder="Address Line 1"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-line2">Address Line 2</label>
              <input
                type="text"
                id="address-line2"
                name="address.line2"
                value={employee.address.line2}
                onChange={viewMode ? null : handleChange}
                placeholder="Address Line 2"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-city">City</label>
              <input
                type="text"
                id="address-city"
                name="address.city"
                value={employee.address.city}
                onChange={viewMode ? null : handleChange}
                placeholder="City"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-district">District</label>
              <input
                type="text"
                id="address-district"
                name="address.district"
                value={employee.address.district}
                onChange={viewMode ? null : handleChange}
                placeholder="District"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-state">State</label>
              <input
                type="text"
                id="address-state"
                name="address.state"
                value={employee.address.state}
                onChange={viewMode ? null : handleChange}
                placeholder="State"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-zipcode">Zipcode</label>
              <input
                type="text"
                id="address-zipcode"
                name="address.zipcode"
                value={employee.address.zipcode}
                onChange={viewMode ? null : handleChange}
                placeholder="Zipcode"
              />
            </div>
          </div>

          <div className="consultant-details-section">
            <div id="consultant-details">
              <h2>Consultant Details</h2>
            </div>
            <div className="form-group">
              <label htmlFor="category">Category</label>
              <select
                id="category"
                name="category"
                value={employee.category}
                onChange={viewMode ? null : handleChange}
              >
                <option value="">Select</option>
                <option value="1">Category1</option>
                <option value="2">Category2</option>
                {/* Add more categories as needed */}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="degree">Degree</label>
              <input
                type="text"
                id="degree"
                name="degree"
                value={employee.degree}
                onChange={viewMode ? null : handleChange}
                placeholder="Degree"
              />
            </div>
            <div className="form-group">
              <label htmlFor="specialty">Specialty</label>
              <input
                type="text"
                id="specialty"
                name="specialty"
                value={employee.specialty}
                onChange={viewMode ? null : handleChange}
                placeholder="Specialty"
              />
            </div>
            <div className="form-group">
              <label htmlFor="sub-specialty">Sub-Specialty</label>
              <input
                type="text"
                id="sub-specialty"
                name="subSpecialty"
                value={employee.subSpecialty}
                onChange={viewMode ? null : handleChange}
                placeholder="Sub-Specialty"
              />
            </div>
            <div className="form-group">
              <label htmlFor="super-specialty">Super-Specialty</label>
              <input
                type="text"
                id="super-specialty"
                name="superSpecialty"
                value={employee.superSpecialty}
                onChange={viewMode ? null : handleChange}
                placeholder="Super-Specialty"
              />
            </div>
            <div className="form-group">
              <label htmlFor="fellowship">Fellowship</label>
              <input
                type="text"
                id="fellowship"
                name="fellowship"
                value={employee.fellowship}
                onChange={viewMode ? null : handleChange}
                placeholder="Fellowship"
              />
            </div>
          </div>

          <div className="form-actions">
            <button
              type="button"
              className="cancel-btn"
              onClick={() => setViewMode(!viewMode)}
            >
              {viewMode ? "Edit" : "View"}
            </button>
            <button
              type="submit"
              className="save-btn"
              // disabled={viewMode}
              onClick={viewMode ? handleBackButtonClick : handleSubmit}
            >
              {viewMode ? "Back" : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default EditCP
