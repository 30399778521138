import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Sidebar from "../../../layout/Sidebar"
import editButton from "./../../../assets/Astha web app/edit.svg"
import trashButton from "./../../../assets/Astha web app/trash-2.svg"
import axios from "axios"
import {
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  TextField,
  Box,
} from "@mui/material"

const StoreDetails = () => {
  const [stores, setStores] = useState([])
  const [zones, setZones] = useState([])
  const [filterRole, setFilterRole] = useState("nph")
  const [filterStatus, setFilterStatus] = useState("")
  const [filterZone, setFilterZone] = useState("")
  const [searchQuery, setSearchQuery] = useState("")
  const [page, setPage] = useState(1)
  const [rowsPerPage] = useState(5)

  // Fetch zone data
  const fetchZones = async () => {
    try {
      const response = await axios.get("/api/zones")
      setZones(response.data)
    } catch (error) {
      console.error("Error fetching zones:", error)
    }
  }

  // Function to process store data
  const processStoreData = (storeData) => {
    return storeData.map((store) => {
      const zones = store.assignedZones
        ? store.assignedZones.map((zone) => zone.zone_name).join(", ")
        : store.zone && store.zone.zone_name
        ? store.zone.zone_name
        : "N/A"

      return {
        id: store.cp_id || store.nphId || store.id,
        business_name: store.business_name,
        mobile: store.mobile,
        zone_name: store.zone_name || store.zone.zone_name || "N/A",
        address: store.address ? `${store.address.state}` : "N/A",
        status: store.status || "Active",
      }
    })
  }

  // Fetch stores data based on the role
  const fetchStores = async () => {
    let url = ""
    switch (filterRole) {
      case "nph":
        url = "/api/nph/"
        break
      case "ndh":
        url = "/api/ndh/"
        break
      case "eclinic":
        url = "/api/eclinic/"
        break
      default:
        url = "" // If no role is selected, do nothing
        break
    }

    if (url) {
      try {
        const response = await axios.get(url)
        const data = processStoreData(response.data) // Process data outside axios
        setStores(data)
      } catch (error) {
        console.error("Error fetching store data:", error)
      }
    }
  }

  useEffect(() => {
    fetchZones()
    if (filterRole) {
      fetchStores() // Fetch data only when a role is selected
    }
  }, [filterRole])

  // Filter stores based on search query, status, and zone
  const filteredStores = stores.filter(
    (store) =>
      (filterStatus === "" || store.status === filterStatus) &&
      (searchQuery === "" ||
        store.business_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())) &&
      (filterZone === "" || store.zone_name === filterZone) // Corrected zone filter logic
  )

  const indexOfLastStore = page * rowsPerPage
  const indexOfFirstStore = indexOfLastStore - rowsPerPage
  const currentStores = filteredStores.slice(
    indexOfFirstStore,
    indexOfLastStore
  )

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  const navigate = useNavigate()

  return (
    <>
      <div>
        <Sidebar />
      </div>
      <Box sx={{ padding: "0 2%", margin: "0 20%" }}>
        <Box
          sx={{
            fontWeight: "600",
            color: "#454545",
            fontSize: "larger",
            marginLeft: "-2%",
          }}
        >
          Store House Details
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "3%",
            marginBottom: "20px",
            "& .MuiOutlinedInput-input": {
              backgroundColor: "#f5f5f5",
              height: "16px",
              fontWeight: "medium",
            },
            "& .MuiSelect-select ": {
              padding: "14px 32px 14px 14px",
            },
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ minWidth: 255, marginRight: 2 }}
          />

          <FormControl
            variant="outlined"
            sx={{ minWidth: 200, marginRight: 2 }}
          >
            <InputLabel>Store</InputLabel>
            <Select
              value={filterRole}
              onChange={(e) => setFilterRole(e.target.value)}
              label="Role"
            >
              <MenuItem value="nph">Nodal Patholab House</MenuItem>
              <MenuItem value="ndh">Nodal Drug House</MenuItem>
              <MenuItem value="eclinic">Eclinic Hospital</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            sx={{ minWidth: 130, marginRight: 2 }}
          >
            <InputLabel>Status</InputLabel>
            <Select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              label="Status"
            >
              <MenuItem value="">All Status</MenuItem>
              <MenuItem value="Locked">Locked</MenuItem>
              <MenuItem value="Active">Active</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            sx={{ minWidth: 135, marginRight: 2 }}
          >
            <InputLabel>Zone</InputLabel>
            <Select
              value={filterZone}
              onChange={(e) => setFilterZone(e.target.value)}
              label="Zone"
            >
              <MenuItem value="">All Zones</MenuItem>
              {zones.map((zone) => (
                <MenuItem
                  key={zone.zone_id}
                  value={zone.zone_name}
                >
                  {zone.zone_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate("/AddNPH")
            }}
            sx={{
              backgroundColor: "#6bc6b0",
              "&:hover": {
                backgroundColor: "#6bc6b0",
              },
              minWidth: "171px",
              height: "50px",
              fontWeight: "550",
            }}
          >
            + Add Store
          </Button>
        </Box>

        <TableContainer
          component={Paper}
          sx={{ marginTop: "20px", minWidth: "70vw" }}
        >
          <Table>
            <TableHead
              sx={{
                border: "1px",
                backgroundColor: "#5A79C9",
                color: "rgba(11 96 96)",
              }}
            >
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    color: "#ffffff",
                    fontWeight: "550",
                  },
                }}
              >
                <TableCell>ID</TableCell>
                <TableCell>Business Name</TableCell>
                <TableCell>Mobile No</TableCell>
                <TableCell>Zone</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Edit/Stop</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentStores.map((store) => (
                <TableRow
                  key={store.id}
                  onClick={() => {
                    const role = store.role || filterRole.toLowerCase() // Use store.role if available, otherwise fallback to filterRole
                    navigate(`/store/${role}/${store.id}`)
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell>{store.id}</TableCell>
                  <TableCell>{store.business_name}</TableCell>
                  <TableCell>{store.mobile}</TableCell>
                  <TableCell>{store.zone_name}</TableCell>
                  <TableCell>{store.address}</TableCell>
                  <TableCell sx={{ paddingLeft: "2%" }}>
                    <img
                      src={editButton}
                      alt="Edit"
                      style={{ cursor: "pointer",minHeight:"15px", marginLeft:"5px",marginRight: "25px" }}
                      onClick={() => navigate(`/editStore/${store.id}`)}
                    />
                    <img
                      src={trashButton}
                      alt="Delete"
                      style={{ minHeight:"15px",cursor: "pointer" }}
                      onClick={() => {
                        // Handle delete functionality here
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination Controls */}
        <Stack
          spacing={2}
          style={{
            marginTop: "4vh",
            marginLeft: "50vw",
            marginRight: "-17vw",
            alignItems: "center",
          }}
        >
          <Pagination
            count={Math.ceil(filteredStores.length / rowsPerPage)}
            page={page}
            onChange={handlePageChange}
            sx={{
              "& .MuiPaginationItem-root": {
                borderRadius: "50%",
                marginBottom: "50px",
                color: "#0E044A",
                "&.Mui-selected": { backgroundColor: "#0E044A", color: "#fff" },
                "&:hover": { backgroundColor: "#0E044A20" },
              },
            }}
          />
        </Stack>
      </Box>
    </>
  )
}

export default StoreDetails