import React, { useEffect, useState } from "react"
import "../../Admin_CSS/EmployeeForm.css"
import { useParams } from "react-router-dom"
import uploadIcon from "../../../../assets/Astha web app/upload icon.svg"
import axios from "axios"
import { successToast, errorToast } from "../../../../utils/toastConfig"
import profileIcon from "../../../../assets/Astha web app/Rectangle 24.svg"
import Sidebar from "../../../../layout/Sidebar"
import { useNavigate } from "react-router-dom"
import { BASE_URL } from "../../../../utils/urlConfig"

const initialStoreState = {
  business_name: "",
  owner_name: "",
  email: "",
  mobile: "",
  gst_no: "",
  address: {
    line1: "",
    line2: "",
    city: "",
    district: "",
    state: "",
    zipcode: "",
  },
  photo: "",
  nabl_license: {
    proof_type: "",
    proof_no: "",
    proof: null,
  },
  supporting_docs: {
    name: "",
    document: null,
  },
  zone_id: "",
}

const EditNPH = () => {
  const { storeId } = useParams()
  const navigate = useNavigate()
  const [store, setStore] = useState(initialStoreState)
  const [profilePicture, setProfilePicture] = useState(profileIcon)
  const [proofDocument, setProofDocument] = useState(null)
  const [supportDocument, setSupportDocument] = useState(null)
  const [viewMode, setViewMode] = useState(true)

  useEffect(() => {
    fetchNPHDetails(storeId)
  }, [storeId])

  const createFileUrl = (filePath, type) => {
    if (!filePath) return null
    const baseUrl =
      type === "image"
        ? `${BASE_URL}/api/image/`
        : `${BASE_URL}/api/pdf/`

    return `${baseUrl}${
      filePath.startsWith("/") ? filePath.slice(1) : filePath
    }`
  }
  // Function to fetch NPH details
  const fetchNPHDetails = async (id) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/nph/${storeId}`
      )
      const data = response.data
      console.log(data)

      // Map the API response to form fields
      setStore({
        business_name: data.business_name || "",
        owner_name: data.owner_name || "",
        email: data.email || "",
        mobile: data.mobile || "",
        gst_no: data.gst_no || "",
        address: {
          line1: data.address.line1 || "",
          line2: data.address.line2 || "",
          city: data.address.city || "",
          district: data.address.district || "",
          state: data.address.state || "",
          zipcode: data.address.zipcode || "",
        },
        photo: data.photo || null,
        nabl_license: {
          proof_type: data.nabl_license.proof_type || "",
          proof_no: data.nabl_license.proof_no || "",
          proof: data.nabl_license.proof || null,
        },
        supporting_docs: {
          name: data.supporting_docs.name || "",
          document: data.supporting_docs.document || null,
        },
        zone_id: data.zone.zone_id || "",
      })
      console.log(data.photo)
      setProfilePicture(createFileUrl(data.photo, "image"))
      setProofDocument(createFileUrl(data.nabl_license.proof, "pdf"))
      setSupportDocument(createFileUrl(data.supporting_docs.document, "pdf"))
    } catch (error) {
      console.error("Error fetching NPH details:", error)
      errorToast("Failed to fetch NPH details.")
    }
  }

  // Handle form changes
  const handleChange = (e) => {
    const { name: inputName, value } = e.target

    if (inputName.startsWith("address.")) {
      const fieldName = inputName.split(".")[1]
      setStore((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [fieldName]: value,
        },
      }))
    } else if (inputName.startsWith("nabl_license.")) {
      const fieldName = inputName.split(".")[1]
      setStore((prev) => ({
        ...prev,
        nabl_license: {
          ...prev.nabl_license,
          [fieldName]: value,
        },
      }))
    } else if (inputName.startsWith("supporting_docs.")) {
      const fieldName = inputName.split(".")[1]
      setStore((prev) => ({
        ...prev,
        supporting_docs: {
          ...prev.supporting_docs,
          [fieldName]: value,
        },
      }))
    } else {
      setStore((prev) => ({
        ...prev,
        [inputName]: value,
      }))
    }
  }

  // Handle proof document change
  const handleProofDocumentChange = (e) => {
    const file = e.target.files[0] // Get single selected file

    if (file && file.type === "application/pdf") {
      setProofDocument(file) // Update state with proof document file
      setStore((prev) => ({
        ...prev,
        nabl_license: {
          ...prev.nabl_license,
          proof: file,
        },
      }))
    } else {
      errorToast("Please upload a valid PDF document.")
    }
  }

  // Handle supporting document change
  const handleSupportDocumentChange = (e) => {
    const file = e.target.files[0] // Get single selected file

    if (file && file.type === "application/pdf") {
      setSupportDocument(file) // Update state with support document file
      setStore((prev) => ({
        ...prev,
        supporting_docs: {
          ...prev.supporting_docs,
          document: file,
        },
      }))
    } else {
      errorToast("Please upload a valid PDF document.")
    }
  }
  // Handle profile picture change
  const handleFileChange = (e) => {
    const file = e.target.files ? e.target.files[0] : null // Ensure the file exists

    if (file && file.type.startsWith("image/")) {
      // Validate it's an image file
      const reader = new FileReader()
      reader.onload = () => {
        setProfilePicture(reader.result) // Set the image preview
        setStore((prev) => ({
          ...prev,
          photo: file, // Set the file in the state
        }))
      }
      reader.readAsDataURL(file) // Read the file as a data URL
    } else {
      console.error("Please select a valid image file.")
    }
  }

  // Upload files to server
  const uploadFiles = async (files) => {
    const formData = new FormData()
    if (files.photo) {
      formData.append("photo", files.photo)
    }
    if (files.proof) {
      formData.append("document", files.proof)
    }
    if (files.supportingDocs) {
      formData.append("document", files.supportingDocs)
    }
    console.log(formData)

    try {
      const response = await axios.post(
        `${BASE_URL}/api/upload-files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      console.log(response.data)
      return response.data // Return the URLs of the uploaded files
    } catch (error) {
      console.error(
        "Error uploading files:",
        error.response?.data || error.message
      )
      throw error
    }
  }
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault()

    // Basic validation for required fields
    if (
      !store.business_name ||
      !store.owner_name ||
      !store.email ||
      !store.mobile ||
      !store.gst_no ||
      !store.photo ||
      !store.address.line1 ||
      !store.address.zipcode ||
      !store.address.city ||
      !store.address.state ||
      !store.address.district ||
      !store.nabl_license.proof_no ||
      !store.nabl_license.proof ||
      !store.supporting_docs.document ||
      !store.supporting_docs.name ||
      !store.zone_id
    ) {
      errorToast("Please Enter the required fields")
      return
    }

    // Handle file uploads only if new files are selected
    const files = {
      photo: store.photo,
      proof: proofDocument || store.nabl_license.proof, // Keep previous if no new proof document
      supportingDocs: supportDocument || store.supporting_docs.document, // Keep previous if no new supporting document
    }

    try {
      // Upload new files if any, otherwise use existing ones
      let photoUrl = store.photo
      let documentUrls = [
        store.nabl_license.proof,
        store.supporting_docs.document,
      ]

      if (files.photo || files.proof || files.supportingDocs) {
        const uploadResponse = await uploadFiles(files)
        photoUrl = uploadResponse.photoUrl || store.photo // Keep old photo if not updated
        documentUrls = uploadResponse.documentUrls || documentUrls // Keep old documents if not updated
      }
      console.log(photoUrl)

      // Prepare JSON data for the PUT request
      const jsonData = {
        ...store,
        photo: photoUrl, // Ensure photo URL is correct
        nabl_license: {
          ...store.nabl_license,
          proof: documentUrls[0] || store.nabl_license.proof, // Use new proof if uploaded, else keep previous
        },
        supporting_docs: {
          ...store.supporting_docs,
          document: documentUrls[1] || store.supporting_docs.document, // Use new document if uploaded, else keep previous
        },
        zone_id: parseInt(store.zone_id, 10), // Ensure zone_id is a number
      }

      console.log("Submitting JSON data:", jsonData)

      // Submit the form data using PUT request
      await axios.put(
        `${BASE_URL}/api/nph/${storeId}`,
        jsonData,
        {
          headers: { "Content-Type": "application/json" },
        }
      )

      handleReset() // Reset form after successful submission
      successToast("Form updated successfully!")
      console.log("Form submitted successfully!")
    } catch (error) {
      errorToast("An error occurred while submitting the form")
      console.error(
        "Error submitting form:",
        error.response?.data || error.message
      )
    }
  }

  // Handle form reset
  const handleReset = () => {
    setStore(initialStoreState)
    setProfilePicture(profileIcon)
  }
  const handleBackButtonClick = () => {
    navigate(-1) // Go back to the previous page
  }

  return (
    <>
      <div>
        <Sidebar />
      </div>
      <div
        className="containers"
        style={{ margin: "0 18% 5%" }}
      >
        <h1 className="ah1">
          {storeId ? "Edit Nodal Patholab House" : "Add Nodal Patholab House"}
        </h1>
        <button
          type="button"
          className="cancel-btn"
          onClick={() => setViewMode(!viewMode)}
        >
          {viewMode ? "Edit" : "View"}
        </button>
        <form
          className="consultant-form"
          onSubmit={handleSubmit}
        >
          <div
            className="profile-section"
            style={{ gridTemplateColumns: "14em 14em 12em 8em" }}
          >
            <div id="profile">
              <h2>Profile</h2>
            </div>
            <div className="form-group">
              <label htmlFor="Business Name">Business Name</label>
              <input
                type="text"
                id=""
                name="business_name"
                value={store.business_name}
                onChange={viewMode ? null : handleChange}
                placeholder="Business Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="owner-name">Owner Name</label>
              <input
                type="text"
                id="owner-name"
                name="owner_name"
                value={store.owner_name}
                onChange={viewMode ? null : handleChange}
                placeholder="Owner Name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={store.email}
                onChange={viewMode ? null : handleChange}
                placeholder="abc@xyz.com"
              />
            </div>
            <div className="form-group">
              <label htmlFor="personal-mobile">Contact</label>
              <input
                type="text"
                id="personal-mobile"
                name="mobile"
                value={store.mobile}
                onChange={viewMode ? null : handleChange}
                placeholder="Mobile No."
              />
            </div>
            <div className="form-group">
              <label htmlFor="GST-No">GST No.</label>
              <input
                type="text"
                id="GST-No"
                name="gst_no"
                value={store.gst_no}
                onChange={viewMode ? null : handleChange}
                placeholder="GST-No."
              />
            </div>

            <div className="form-group">
              <label htmlFor="Eclinic-Id">Zone Id</label>
              <input
                type="text"
                id="Zone-Id"
                name="zone_id"
                value={store.zone_id}
                onChange={viewMode ? null : handleChange}
                placeholder="Zone Id"
              />
            </div>
            <div id="profile-picture">
              <div>
                <img
                  src={profilePicture || profileIcon}
                  alt="Profile"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    document.getElementById("profile-picture-input").click()
                  }
                />
                <span>Profile Picture</span>
                <input
                  type="file"
                  id="profile-picture-input"
                  accept="image/*"
                  onChange={viewMode ? null : handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>

          <div className="contact-address-section">
            <div id="contact-address">
              <h2>Store Address</h2>
            </div>
            <div className="form-group">
              <label htmlFor="address-line1">Address Line1</label>
              <input
                type="text"
                id="address-line1"
                name="address.line1"
                value={store.address.line1}
                onChange={viewMode ? null : handleChange}
                placeholder="Address Line1"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-line2">Address Line2</label>
              <input
                type="text"
                id="address-line2"
                name="address.line2"
                value={store.address.line2}
                onChange={viewMode ? null : handleChange}
                placeholder="Address Line2"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-city">City</label>
              <input
                type="text"
                id="address-city"
                name="address.city"
                value={store.address.city}
                onChange={viewMode ? null : handleChange}
                placeholder="City"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-district">District</label>
              <input
                type="text"
                id="address-district"
                name="address.district"
                value={store.address.district}
                onChange={viewMode ? null : handleChange}
                placeholder="District"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-state">State</label>
              <input
                type="text"
                id="address-state"
                name="address.state"
                value={store.address.state}
                onChange={viewMode ? null : handleChange}
                placeholder="State"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-zipcode">Zipcode</label>
              <input
                type="text"
                id="address-zipcode"
                name="address.zipcode"
                value={store.address.zipcode}
                onChange={viewMode ? null : handleChange}
                placeholder="Zipcode"
              />
            </div>
          </div>

          <div className="consultant-details-section">
            <div id="present-address">
              <h2>Document Details</h2>
            </div>
            {/* <div className="form-group">
              <label htmlFor="nabl_license.proof_no">nabl License</label>
              <div>
                <input
                  type="text"
                  name="nabl_license.proof_no"
                  id="nabl_license.proof_no"
                  onChange={viewMode ? null : handleChange}
                  value={store.nabl_license.proof_no}
                  placeholder="License No"
                />
                <span id="span">
                  {!viewMode && (
                    <>
                      <img
                        src={uploadIcon}
                        style={{
                          filter: proofDocument
                            ? "invert(36%) sepia(76%) saturate(2696%) hue-rotate(81deg) brightness(95%) contrast(92%)"
                            : "none",
                          cursor: "pointer",
                        }}
                        alt=""
                        onClick={() =>
                          document.getElementById("nabl-document").click()
                        }
                      />
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="nabl-document"
                        accept="application/pdf"
                        onChange={handleProofDocumentChange} // Updated to handle PDF uploads
                      />
                    </>
                  )}
                  {supportDocument && (
                    <a
                      href={supportDocument}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        marginLeft: "5px",
                        fontSize: "x-small",
                        textDecoration: "underline",
                        color: "#777",
                      }}
                    >
                      {typeof supportDocument === "string"
                        ? "View"
                        : "doc. uploaded"}
                    </a>
                  )}

                  {!supportDocument && (
                    <span
                      style={{
                        marginLeft: "5px",
                        fontSize: "x-small",
                        textDecoration: "underline",
                        color: "#777",
                      }}
                    >
                      No doc. uploaded
                    </span>
                  )}
                </span>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="supporting_docs">Supporting Docs</label>
              <div>
                <select
                  style={{ minWidth: "18vh" }}
                  id="supporting_docs"
                  name="supporting_docs.name"
                  value={store.supporting_docs.name}
                  onChange={viewMode ? null : handleChange}
                >
                  <option value="">Select</option>
                  <option value="Aadhar">Aadhar</option>
                  <option value="ABHA">ABHA</option>
                  <option value="Voter Id">Voter Id</option>
                  <option value="Driving License">Driving License</option>
                  <option value="Ration Card">Ration Card</option>

                  <option value="NABL License">NABL License</option>
                </select>
                <span id="span">
                  {!viewMode && (
                    <>
                      <img
                        src={uploadIcon}
                        style={{
                          filter: supportDocument
                            ? "invert(36%) sepia(76%) saturate(2696%) hue-rotate(81deg) brightness(95%) contrast(92%)"
                            : "none",
                          cursor: "pointer",
                        }}
                        alt=""
                        onClick={() =>
                          document
                            .getElementById("supporting_docs-document")
                            .click()
                        }
                      />
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="supporting_docs-document"
                        accept="application/pdf"
                        onChange={viewMode ? null : handleSupportDocumentChange}
                      />
                    </>
                  )}
                  {supportDocument && (
                    <a
                      href={supportDocument}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        marginLeft: "5px",
                        fontSize: "x-small",
                        textDecoration: "underline",
                        color: "#777",
                      }}
                    >
                      {typeof supportDocument === "string"
                        ? "View"
                        : "doc. uploaded"}
                    </a>
                  )}

                  {!supportDocument && (
                    <span
                      style={{
                        marginLeft: "5px",
                        fontSize: "x-small",
                        textDecoration: "underline",
                        color: "#777",
                      }}
                    >
                      No doc. uploaded
                    </span>
                  )}
                </span>
              </div>
            </div> */}
            <div className="form-group">
              <label htmlFor="nabl_license.proof_no">NABL License</label>
              <div>
                <input
                  type="text"
                  name="nabl_license.proof_no"
                  id="nabl_license.proof_no"
                  onChange={viewMode ? null : handleChange}
                  value={store.nabl_license.proof_no}
                  placeholder="License No"
                  disabled={viewMode} // Disable in view mode
                />
                <span id="span">
                  {!viewMode && (
                    <>
                      <img
                        src={uploadIcon}
                        style={{
                          filter: proofDocument
                            ? "invert(36%) sepia(76%) saturate(2696%) hue-rotate(81deg) brightness(95%) contrast(92%)"
                            : "none",
                          cursor: "pointer",
                        }}
                        alt=""
                        onClick={() =>
                          document.getElementById("nabl-document").click()
                        }
                      />
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="nabl-document"
                        accept="application/pdf"
                        onChange={handleProofDocumentChange} // Handle document upload
                      />
                    </>
                  )}
                  {proofDocument && (
                    <a
                      href={proofDocument} // Link to uploaded document
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        marginLeft: "5px",
                        fontSize: "x-small",
                        textDecoration: "underline",
                        color: "#777",
                      }}
                    >
                      {typeof proofDocument === "string"
                        ? "View"
                        : "doc. uploaded"}
                    </a>
                  )}
                  {!proofDocument && (
                    <span
                      style={{
                        marginLeft: "5px",
                        fontSize: "x-small",
                        textDecoration: "underline",
                        color: "#777",
                      }}
                    >
                      No doc. uploaded
                    </span>
                  )}
                </span>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="supporting_docs">Supporting Docs</label>
              <div>
                <select
                  style={{ minWidth: "18vh" }}
                  id="supporting_docs"
                  name="supporting_docs.name"
                  value={store.supporting_docs.name}
                  onChange={viewMode ? null : handleChange}
                  disabled={viewMode} // Disable in view mode
                >
                  <option value="">Select</option>
                  <option value="Aadhar">Aadhar</option>
                  <option value="ABHA">ABHA</option>
                  <option value="Voter Id">Voter Id</option>
                  <option value="Driving License">Driving License</option>
                  <option value="Ration Card">Ration Card</option>
                  <option value="NABL License">NABL License</option>
                </select>
                <span id="span">
                  {!viewMode && (
                    <>
                      <img
                        src={uploadIcon}
                        style={{
                          filter: supportDocument
                            ? "invert(36%) sepia(76%) saturate(2696%) hue-rotate(81deg) brightness(95%) contrast(92%)"
                            : "none",
                          cursor: "pointer",
                        }}
                        alt=""
                        onClick={() =>
                          document
                            .getElementById("supporting_docs-document")
                            .click()
                        }
                      />
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="supporting_docs-document"
                        accept="application/pdf"
                        onChange={handleSupportDocumentChange} // Handle document upload
                      />
                    </>
                  )}
                  {supportDocument && (
                    <a
                      href={supportDocument} // Link to uploaded document
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        marginLeft: "5px",
                        fontSize: "x-small",
                        textDecoration: "underline",
                        color: "#777",
                      }}
                    >
                      {typeof supportDocument === "string"
                        ? "View"
                        : "doc. uploaded"}
                    </a>
                  )}
                  {!supportDocument && (
                    <span
                      style={{
                        marginLeft: "5px",
                        fontSize: "x-small",
                        textDecoration: "underline",
                        color: "#777",
                      }}
                    >
                      No doc. uploaded
                    </span>
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className="form-actions">
            <button
              type="button"
              className="cancel-btn"
              onClick={() => setViewMode(!viewMode)}
            >
              {viewMode ? "Edit" : "View"}
            </button>
            <button
              type="submit"
              className="save-btn"
              // disabled={viewMode}
              onClick={viewMode ? handleBackButtonClick : handleSubmit}
            >
              {viewMode ? "Back" : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default EditNPH
