import React, { useState, useRef, useEffect } from 'react';
import { ReactMediaRecorder } from "react-media-recorder";
import axios from 'axios';
import { BASE_URL } from "../../../utils/urlConfig"

const Audio = ({ setAudioRecord, setAudioUrl }) => {
  const [mediaBlob, setMediaBlob] = useState(null);
  const [isUploading, setIsUploading] = useState(false); // To show the upload status

  const uploadFiles = async (file) => {
    const formData = new FormData();
    formData.append("audio", file);
  
    try {
      setIsUploading(true);
      const response = await axios.post(
        `${BASE_URL}/api/upload-files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      // Modify the URL to remove "/tempaudio"
      const modifiedUrls = response.data.audioUrls.map(url => url.replace('/tempaudio', ''));
  
      console.log(modifiedUrls);
      setAudioUrl(modifiedUrls);
    } catch (error) {
      console.error("Error uploading files:", error.response?.data || error.message);
      throw error;
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div>
      <div className={`absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center`}>
        <ReactMediaRecorder
          audio
          onStop={(blobUrl, blob) => setMediaBlob(blob)}
          render={({ status, startRecording, stopRecording, mediaBlobUrl }) => (
            <div className="flex flex-col items-center justify-center space-y-4">
              <audio src={mediaBlobUrl} autoPlay loop controls />
              <div className="grid grid-cols-2">
                <button
                  onClick={startRecording}
                  className="m-2 border-2 border-gray-400 p-1 bg-[#0E044A] text-white rounded-lg"
                >
                  Start Recording
                </button>
                <button
                  onClick={async () => {
                    stopRecording();
                    if (mediaBlob) {
                      const audioFile = new File([mediaBlob], "recorded-audio.wav", {
                        type: "audio/wav",
                      });
                      await uploadFiles(audioFile); // Upload the audio after stopping
                    }
                  }}
                  className="m-2 border-2 border-gray-400 p-1 bg-[#0E044A] text-white rounded-lg"
                >
                  Stop & Upload
                </button>
                <p
                  className="m-2 text-center border-2 border-gray-400 p-1 bg-[#0E044A] text-white rounded-lg"
                  onClick={() => setAudioRecord(false)}
                >
                  Cancel
                </p>
                <p className="m-2 text-center border-2 border-gray-400 p-1 bg-[#0E044A] text-white rounded-lg">
                  Status: {status}
                </p>
              </div>
              {isUploading && <p className="text-blue-500">Uploading...</p>}
            </div>
          )}
        />
      </div>
    </div>
  );
}

export default Audio;
