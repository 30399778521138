import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

export default function StoreSelector() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate=useNavigate()

const handleMenuClick = (route)=>{
 navigate(route);
 handleClose();
}

  return (
    <div>
      <Button
        id="basic-button"
        variant='contained'
        sx={{backgroundColor:"#0e044a", marginRight:"70px"}}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Type
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={()=>{handleMenuClick('/AddNPH')}}>Nodal Patholab House</MenuItem>
        <MenuItem onClick={()=>{handleMenuClick('/AddNDH')}}>Nodal Drug House</MenuItem>
        <MenuItem onClick={()=>{handleMenuClick('/AddEclinic')}}>Eclinic Hpspital</MenuItem>
      </Menu>
    </div>
  );
}
