import React, { useState } from "react"
import "../../Admin_CSS/EmployeeForm.css"
import uploadIcon from "../../../../assets/Astha web app/upload icon.svg"
import axios from "axios"
import { successToast, errorToast } from "../../../../utils/toastConfig"
import profileIcon from "../../../../assets/Astha web app/Rectangle 24.svg"
import StoreSelector from "../storeComponents/StoreSelector"
import Sidebar from "../../../../layout/Sidebar"
import { BASE_URL } from "../../../../utils/urlConfig"

const initialStoreState = {
  business_name: "",
  owner_name: "",
  email: "",
  mobile: "",
  gst_no: "",
  address: {
    line1: "",
    line2: "",
    city: "",
    district: "",
    state: "",
    zipcode: "",
  },
  photo: "",
  nabl_license: {
    proof_no: "",
    proof: null,
  },
  supporting_docs: {
    name: "",
    document: null,
  },
  zone_id: "",
}

const AddNPH = () => {
  const [store, setStore] = useState(initialStoreState)
  const [profilePicture, setProfilePicture] = useState(profileIcon)
  const [proofDocument, setProofDocument] = useState(null)
  const [supportDocument, setSupportDocument] = useState(null)

  // Handle form changes
  const handleChange = (e) => {
    const { name: inputName, value } = e.target

    if (inputName.startsWith("address.")) {
      const fieldName = inputName.split(".")[1]
      setStore((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [fieldName]: value,
        },
      }))
    } else if (inputName.startsWith("nabl_license.")) {
      const fieldName = inputName.split(".")[1]
      setStore((prev) => ({
        ...prev,
        nabl_license: {
          ...prev.nabl_license,
          [fieldName]: value,
        },
      }))
    } else if (inputName.startsWith("supporting_docs.")) {
      const fieldName = inputName.split(".")[1]
      setStore((prev) => ({
        ...prev,
        supporting_docs: {
          ...prev.supporting_docs,
          [fieldName]: value,
        },
      }))
    } else {
      setStore((prev) => ({
        ...prev,
        [inputName]: value,
      }))
    }
  }

  // Handle proof document change
  const handleProofDocumentChange = (e) => {
    const file = e.target.files[0] // Get single selected file

    if (file && file.type === "application/pdf") {
      setProofDocument(file) // Update state with proof document file
      setStore((prev) => ({
        ...prev,
        nabl_license: {
          ...prev.nabl_license,
          proof: file,
        },
      }))
    } else {
      errorToast("Please upload a valid PDF document.")
    }
  }

  // Handle supporting document change
  const handleSupportDocumentChange = (e) => {
    const file = e.target.files[0] // Get single selected file

    if (file && file.type === "application/pdf") {
      setSupportDocument(file) // Update state with support document file
      setStore((prev) => ({
        ...prev,
        supporting_docs: {
          ...prev.supporting_docs,
          document: file,
        },
      }))
    } else {
      alert("Please upload a valid PDF document.")
    }
  }
  // Handle profile picture change
  const handleFileChange = (e) => {
    const file = e.target.files ? e.target.files[0] : null // Ensure the file exists

    if (file && file.type.startsWith("image/")) {
      // Validate it's an image file
      const reader = new FileReader()
      reader.onload = () => {
        setProfilePicture(reader.result) // Set the image preview
        setStore((prev) => ({
          ...prev,
          photo: file, // Set the file in the state
        }))
      }
      reader.readAsDataURL(file) // Read the file as a data URL
    } else {
      console.error("Please select a valid image file.")
    }
  }

  // Upload files to server
  const uploadFiles = async (files) => {
    const formData = new FormData()
    if (files.photo) {
      formData.append("photo", files.photo)
    }
    if (files.proof) {
      formData.append("document", files.proof)
    }
    if (files.supportingDocs) {
      formData.append("document", files.supportingDocs)
    }
    console.log(formData)

    try {
      const response = await axios.post(
        `${BASE_URL}/api/upload-files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      console.log(response.data)
      return response.data // Return the URLs of the uploaded files
    } catch (error) {
      console.error(
        "Error uploading files:",
        error.response?.data || error.message
      )
      throw error
    }
  }
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault()

    if (
      !store.business_name ||
      !store.owner_name ||
      !store.email ||
      !store.mobile ||
      !store.gst_no ||
      !store.photo ||
      !store.address.line1 ||
      !store.address.zipcode ||
      !store.address.city ||
      !store.address.state ||
      !store.address.district ||
      !store.nabl_license.proof_no ||
      !store.nabl_license.proof ||
      !store.supporting_docs.document ||
      !store.zone_id
    ) {
      errorToast("Please Enter the required fields")
      return
    } else {

    const files = {
      photo: store.photo,
      proof: proofDocument,
      supportingDocs: supportDocument,
    }

    try {
      const { photoUrl, documentUrls } = await uploadFiles(files)

      const jsonData = {
        ...store,
        photo: photoUrl, // Ensure this is correct
        nabl_license: {
          ...store.nabl_license,
          proof: documentUrls[0] || store.nabl_license.proof, // Check this
        },
        supporting_docs: {
          ...store.supporting_docs,
          document: documentUrls[1] || store.supporting_docs.document, // Check this
        },
        zone_id: parseInt(store.zone_id, 10), // Convert zone_id to a number
      }

      console.log(jsonData)

      await axios.post(`${BASE_URL}/api/nph/`, jsonData, {
        headers: { "Content-Type": "application/json" },
      })
      handleReset()
      console.log("Form submitted successfully!")
      successToast("Form Submitted Successfully!")
    } catch (error) {
      errorToast("An error occurred while submitting the form.")
      console.error(
        "Error submitting form:",
        error.response?.data || error.message
      )
    }}
  }

  // Handle form reset
  const handleReset = () => {
    setStore(initialStoreState)
    setProfilePicture(profileIcon)
  }

  return (
    <>
      <div>
        <Sidebar />
      </div>
      <div
        className="containers"
        style={{ margin: "0 18% 5%" }}
      >
        <h1 className="ah1">
          Add Nodal Patholab House
          <StoreSelector />
        </h1>

        <form
          className="consultant-form"
          onSubmit={handleSubmit}
        >
          <div
            className="profile-section"
            style={{ gridTemplateColumns: "14em 14em 12em 8em" }}
          >
            <div id="profile">
              <h2>Profile</h2>
            </div>
            <div className="form-group">
              <label htmlFor="Business Name">Business Name</label>
              <input
                type="text"
                id="first-name"
                name="business_name"
                value={store.business_name}
                onChange={handleChange}
                placeholder="Business Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="owner-name">Owner Name</label>
              <input
                type="text"
                id="owner-name"
                name="owner_name"
                value={store.owner_name}
                onChange={handleChange}
                placeholder="Owner Name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={store.email}
                onChange={handleChange}
                placeholder="abc@xyz.com"
              />
            </div>
            <div className="form-group">
              <label htmlFor="personal-mobile">Contact</label>
              <input
                type="text"
                id="personal-mobile"
                name="mobile"
                value={store.mobile}
                onChange={handleChange}
                placeholder="Mobile No."
              />
            </div>
            <div className="form-group">
              <label htmlFor="GST-No">GST No.</label>
              <input
                type="text"
                id="GST-No"
                name="gst_no"
                value={store.gst_no}
                onChange={handleChange}
                placeholder="GST-No."
              />
            </div>

            <div className="form-group">
              <label htmlFor="Eclinic-Id">Zone Id</label>
              <input
                type="text"
                id="Zone-Id"
                name="zone_id"
                value={store.zone_id}
                onChange={handleChange}
                placeholder="Zone Id"
              />
            </div>
            <div id="profile-picture">
              <div>
                <img
                  src={profilePicture || profileIcon}
                  alt="Profile"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    document.getElementById("profile-picture-input").click()
                  }
                />
                <span>Profile Picture</span>
                <input
                  type="file"
                  id="profile-picture-input"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>

          <div className="contact-address-section">
            <div id="contact-address">
              <h2>Store Address</h2>
            </div>
            <div className="form-group">
              <label htmlFor="address-line1">Address Line1</label>
              <input
                type="text"
                id="address-line1"
                name="address.line1"
                value={store.address.line1}
                onChange={handleChange}
                placeholder="Address Line1"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-line2">Address Line2</label>
              <input
                type="text"
                id="address-line2"
                name="address.line2"
                value={store.address.line2}
                onChange={handleChange}
                placeholder="Address Line2"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-city">City</label>
              <input
                type="text"
                id="address-city"
                name="address.city"
                value={store.address.city}
                onChange={handleChange}
                placeholder="City"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-district">District</label>
              <input
                type="text"
                id="address-district"
                name="address.district"
                value={store.address.district}
                onChange={handleChange}
                placeholder="District"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-state">State</label>
              <input
                type="text"
                id="address-state"
                name="address.state"
                value={store.address.state}
                onChange={handleChange}
                placeholder="State"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address-zipcode">Zipcode</label>
              <input
                type="text"
                id="address-zipcode"
                name="address.zipcode"
                value={store.address.zipcode}
                onChange={handleChange}
                placeholder="Zipcode"
              />
            </div>
          </div>

          <div className="consultant-details-section">
            <div id="present-address">
              <h2>Document Details</h2>
            </div>
            <div className="form-group">
              <label htmlFor="nabl_license.proof_no">NABL License</label>
              <div>
                <input
                  type="text"
                  name="nabl_license.proof_no"
                  id="nabl_license.proof_no"
                  onChange={handleChange}
                  value={store.nabl_license.proof_no}
                  placeholder="License No"
                />
                <span id="span">
                  <img
                    src={uploadIcon}
                    style={{
                      filter: proofDocument ? "invert(36%) sepia(76%) saturate(2696%) hue-rotate(81deg) brightness(95%) contrast(92%)" : "none",
                      cursor: "pointer",
                    }}
                    alt=""
                    onClick={() =>
                      document.getElementById("nabl-document").click()
                    }
                  />
                  <input
                    style={{ display: "none" }}
                    type="file"
                    id="nabl-document"
                    accept="application/pdf"
                    onChange={handleProofDocumentChange} // Updated to handle PDF uploads
                  />
                </span>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="supporting_docs">Supporting Docs</label>
              <div>
                <select
                  style={{ minWidth: "18vh" }}
                  id="supporting_docs"
                  name="supporting_docs.name"
                  value={store.supporting_docs.name}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="Aadhar">Aadhar</option>
                  <option value="ABHA">ABHA</option>
                  <option value="Voter Id">Voter Id</option>
                  <option value="Driving License">Driving License</option>
                  <option value="Ration Card">Ration Card</option>
                  <option value="Drug License">Drug License</option>
                  
                </select>
                <span id="span">
                  <img
                    src={uploadIcon}
                    style={{
                      filter: supportDocument ? "invert(36%) sepia(76%) saturate(2696%) hue-rotate(81deg) brightness(95%) contrast(92%)" : "none",
                      cursor: "pointer",
                    }}
                    alt=""
                    onClick={() =>
                      document
                        .getElementById("supporting_docs-document")
                        .click()
                    }
                  />
                  <input
                    style={{ display: "none" }}
                    type="file"
                    id="supporting_docs-document"
                    accept="application/pdf"
                    onChange={handleSupportDocumentChange} // Updated to handle PDF uploads
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="form-actions">
            <button
              type="submit"
              className="save-btn"
            >
              Save
            </button>
            <button
              type="button"
              className="cancel-btn"
              onClick={handleReset}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddNPH