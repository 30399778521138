import {useState, useEffect} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {errorToast, successToast} from "../../../utils/toastConfig";
import axios from "axios";
import { BASE_URL } from "../../../utils/urlConfig"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 720,
  height: 440,
  bgcolor: "background.paper",
  borderRadius: "16px",
  boxShadow: 24,
  p: 4,
};
const group = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  input: {
    color: "#777",
    backgroundColor: "#f5f5f5",
    border: "1px solid #f5f5f5",
    borderRadius: "5px",
  },
  label: {
    color: "#454545",
    minWidth: 120,
  },
  select:{
    color: "#777",
    minWidth: "58%",
    height: "100%",
    backgroundColor: "#f5f5f5",
    border: "1px solid #f5f5f5",
    borderRadius: "5px",
    
  }
};

export default function EditLabTestModal({test, open, onClose, onTestUpdate}) {
  
  const [categories, setCategories] = useState([])  //to store all the categories from API
  const [selectedCategoryId, setSelectedCategoryId] = useState("") //to store the selected category
  const [name, setName] = useState("")
  const [code, setCode] = useState("")
  const [book_code, setBook_code] = useState("")
  const [billing_rate, setBilling_rate] = useState("")
  const [min_price, setMinPrice] = useState("")
  const [max_price, setMaxPrice] = useState("")
  const [description, setDescription] = useState("")
  const [viewMode, setViewMode]=useState(true)  // Start in view mode



  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/test-categories`
      )
      console.log(response.data) // Check the response structure
      setCategories(response.data)
    } catch (error) {
      console.error("Error Fetching Categories", error)
    }
  }

  useEffect(() => {
    fetchCategories()

    if (test) {
        setName(test.name);
        setDescription(test.description);
        setBilling_rate(test.price);
        setBook_code(test.book_code);
        setCode(test.code);
        setMaxPrice(test.price_max);
        setMinPrice(test.price_min);
        setSelectedCategoryId(test.category_id);
        
    }

  }, [test])
  console.log(test);
  
  const handleSubmit = async () => {
    // Validate form inputs
    if (!name || !selectedCategoryId || !code || !book_code || !billing_rate || !max_price || !min_price || !description) {
      errorToast("Please fill all the required fields.")
      return
    }
    else {

    // Convert selectedCategoryId to a number
    const categoryIdNumber = Number(selectedCategoryId)

    // Check if the conversion was successful
    if (isNaN(categoryIdNumber)) {
      console.error("Invalid categoryId. It must be a number.")
      return
    }
    

    const labTestData = {
      name,
      code,
      category_id: categoryIdNumber,  // Use the converted number here
      book_code,
      price: billing_rate,
      price_min: min_price,
      price_max: max_price,
      description
    }

    console.log("Submitting Lab Test data:", labTestData) // Debugging line

    try {
      const response = await axios.put(
        `${BASE_URL}/api/pathology-tests/${test.id}`,
        labTestData
      )
      
      console.log("Lab Test updated successfully:", response.data)
      successToast("Test updated successfully !")
      
       // After successful update
       if (onTestUpdate) {
        onTestUpdate() // Trigger the refresh in ZoneDetails
      }
      onClose() // Close modal after success
    } catch (error) {
      errorToast("An error occurred while updating LabTest Data.")
      console.error(
        "Error saving Test data:",
        error.response ? error.response.data : error.message
      )
    }}

    
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        sx={{
          backgroundColor: "#6bc6b0",
          "&:hover": {
            backgroundColor: "#6bc6b0",
          },
          minWidth: "191px",
          height: "50px",
          fontWeight: "550",
        }}
        onClick={open}
      >
        + Add Test
      </Button>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Edit Lab Test
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Box
              sx={{
                margin: "5% 0",
                display: "grid",
                gridTemplateColumns: "280px 280px",
                columnGap: "9%",
                rowGap: "29%",
              }}
            >
              <Box sx={group}>
                <label sx={group.label} htmlFor="">
                  Name
                </label>{" "}
                <input
                  sx={group.input}
                  type="text"
                  value={name}
                  onChange={viewMode ? null : (e)=>setName(e.target.value)}
                  placeholder="Name"
                />
              </Box>
              <Box sx={group}>
                <label sx={group.label} htmlFor="">
                  Code
                </label>{" "}
                <input
                  sx={group.input}
                  type="text"
                  value={code}
                  onChange={viewMode ? null : (e)=>setCode(e.target.value)}
                  placeholder="Code"
                />
              </Box>
              <Box sx={group}>
              <label
                  style={group.label}
                  htmlFor="state"
                >
                  Category
                </label>
                <select
                  style={group.input}
                  value={selectedCategoryId}
                  onChange={viewMode ? null : (e) => setSelectedCategoryId(e.target.value)}
                >
                  <option value="">Select Category</option>
                  {categories.map((data) => (
                    <option
                      key={data.id}
                      value={data.id}
                    >
                      {data.name}
                    </option>
                  ))}
                </select>
              </Box>
              <Box sx={group}>
                <label sx={group.label} htmlFor="">
                  Book code
                </label>{" "}
                <input
                  sx={group.input}
                  type="text"
                  value={book_code}
                  onChange={viewMode ? null : (e)=>setBook_code(e.target.value)}
                  placeholder="Book code"
                />
              </Box>
              <Box sx={group}>
                <label sx={group.label} htmlFor="">
                  Market Price
                </label>{" "}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <input
                    style={{ marginBottom: "2.8%" }}
                    type="text"
                    value={min_price}
                  onChange={viewMode ? null : (e)=>setMinPrice(e.target.value)}
                    placeholder="Min"
                  />{" "}
                  <input type="text" value={max_price}
                  onChange={viewMode ? null : (e)=>setMaxPrice(e.target.value)} placeholder="Max" />
                </div>
              </Box>
              <Box sx={group}>
                <label sx={group.label} htmlFor="">
                  Billing Rate
                </label>{" "}
                <input
                  sx={group.input}
                  type="text"
                  value={billing_rate}
                  onChange={viewMode ? null : (e)=>setBilling_rate(e.target.value)}
                  placeholder="Our Rate"
                />
              </Box>
              <Box sx={group}>
                <label sx={group.label} htmlFor="">
                  Description
                </label>{" "}
                <input
                  sx={group.input}
                  type="text"
                  value={description}
                  onChange={viewMode ? null : (e)=>setDescription(e.target.value)}
                  placeholder="Description"
                />
              </Box>
            </Box>
          </Typography>
          <Box
            sx={{
              width: "270px",
              display: "flex",
              margin: "20% 56%",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              sx={{ borderRadius: "50px", backgroundColor: "#3F6584" }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
                variant="contained"
                sx={{ borderRadius: "50px",fontWeight: "550",color: "#454545", backgroundColor: "#ead8b1" }}
                onClick={() => setViewMode(!viewMode)}
              >
                {viewMode ? "Edit" : "View"}
              </Button>
            <Button
              variant="contained"
              sx={{
                width: "100px",
                color: "#454545",
                borderRadius: "50px",
                backgroundColor: "#a9fcf7",
                fontWeight: "550",
              }}
              onClick={handleSubmit}
              disabled={viewMode}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
