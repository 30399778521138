import React, { useState } from "react";
import AllOreders from "./AllOreders";
import PendingOreders from "./PendingOreders";



const NDHOrder = () => {
  const [info, setInfo] = useState(false);
  const [activeTab, setActiveTab] = useState("Pending Requests");

  const handleInfo = () => {
    setInfo(!info);
  };

  return (
    <>
      <div
        className={`pr-4 max-w-screen-xl mx-auto overflow-x-hidden ${
          info ? "bg-gray-300 bg-opacity-20 backdrop-blur-md" : "bg-gray-300"
        }`}
      >
        <h1 className="text-[#454545] font-semibold text-lg font-display underline decoration-1 mb-4">
          Orders
        </h1>

        <div className="flex flex-row sm:flex-row justify-center sm:justify-start space-y-2 sm:space-y-0 sm:space-x-4 w-full sm:w-auto">
          <span
            className={`mt-2 mx-2 sm:mt-0 sm:mx-0 h-8 font-semibold font-sans border px-4 py-1 rounded-lg text-center whitespace-nowrap cursor-pointer text-xs sm:text-sm ${
              activeTab === "Pending Requests"
                ? "bg-[#0E044A] text-white"
                : "border-gray-500 hover:bg-[#0E044A] hover:text-white"
            }`}
            onClick={() => setActiveTab("Pending Requests")}
          >
            Pending Requests
          </span>
          <span
            className={`h-8 font-semibold font-sans border px-4 py-1 rounded-lg text-center whitespace-nowrap cursor-pointer text-xs sm:text-sm ${
              activeTab === "All Orders"
                ? "bg-[#0E044A] text-white"
                : "border-gray-500 hover:bg-[#0E044A] hover:text-white"
            }`}
            onClick={() => setActiveTab("All Orders")}
          >
            All Orders
          </span>
        </div>
      </div>

      {activeTab === "Pending Requests" ? (
        <PendingOreders handleInfo={handleInfo} />
      ) : (
        <AllOreders handleInfo={handleInfo} />
      )}
    </>
  );
};

export default NDHOrder;
