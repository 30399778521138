import * as React from "react"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Fade from "@mui/material/Fade"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import RemoveIcon from "@mui/icons-material/Remove"
import AddIcon from "@mui/icons-material/Add"
import axios from "axios"
import { BASE_URL } from "../../../../utils/urlConfig"

const dataStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontWeight: "bold",
  color: "#777",
  data: {
    width: "9.2em",
    color: "#777",
    fontWeight: "lighter",
  },
}

export default function CPAccordion({
  onCPSelectionChange,
  selectedCPs: propAddedCPs,
  viewMode,
}) {
  const [expanded, setExpanded] = React.useState(false)
  const [cpData, setCpData] = React.useState([])
  const [filteredData, setFilteredData] = React.useState([])
  const [searchQuery, setSearchQuery] = React.useState("")
  const [addedCPs, setAddedCPs] = React.useState(new Set(propAddedCPs || []))

  React.useEffect(() => {
    const getCPData = async () => {
      axios
        .get(`${BASE_URL}/api/cp`)
        .then((response) => {
          setCpData(response.data)
          setFilteredData(response.data)
        })
        .catch((error) => {
          console.error("Error fetching CP data:", error)
        })
    }
    getCPData()
  }, [])

  React.useEffect(() => {
    const query = searchQuery.toLowerCase()
    setFilteredData(
      cpData.filter((cp) =>
        [cp.first_name, cp.last_name, cp.specialty]
          .map((field) => field?.toLowerCase() || "")
          .some((field) => field.includes(query))
      )
    )
  }, [searchQuery, cpData])

  // Update the selected CPs when the prop changes
  React.useEffect(() => {
    setAddedCPs(new Set(propAddedCPs))
  }, [propAddedCPs])

  const handleExpansion = (cpId) => {
    setExpanded(expanded === cpId ? false : cpId)
  }

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
  }

  const handleAddCP = (cp) => {
    setAddedCPs((prevAddedCPs) => {
      const newSet = new Set(prevAddedCPs)
      newSet.add(cp.cp_id) // Use cp.cp_id consistently
      onCPSelectionChange(Array.from(newSet))
      return newSet
    })
  }

  const handleRemoveCP = (cpId) => {
    setAddedCPs((prevAddedCPs) => {
      const newSet = new Set(prevAddedCPs)
      newSet.delete(cpId)
      onCPSelectionChange(Array.from(newSet))
      return newSet
    })
  }

  return (
    <div style={{ backgroundColor: "white", minWidth: "40vh" }}>
      <div style={{ padding: "0 15px 15px", color: "#454545" }}>
        <h2>Assigned CP</h2>
        {!viewMode && (
          <TextField
            label="Search CP"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={handleSearchChange}
            value={searchQuery}
          />
        )}
      </div>
      {!viewMode && (
        <div style={{ color: "#454545" }}>
          <div
            style={{ height: "110px", padding: "0 3.5%", overflowY: "auto" }}
          >
            {filteredData.map((cp) => (
              <div
                key={cp.cp_id} // Use cp.cp_id as the key
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body2"
                  style={{ flex: 1 }}
                >
                  {cp.first_name} {cp.last_name}
                </Typography>
                {!viewMode && (
                  <IconButton
                    color="primary"
                    onClick={() => handleAddCP(cp)}
                    aria-label="add CP"
                    disabled={addedCPs.has(cp.cp_id)}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      <hr />
      <div style={{}}>
        {Array.from(addedCPs).map((cpId) => {
          const cp = cpData.find((item) => item.cp_id === cpId)
          if (!cp) return null
          return (
            <Accordion
              key={cp.cp_id} // Use cp.cp_id as the key
              expanded={expanded === cp.cp_id}
              onChange={() => handleExpansion(cp.cp_id)}
              slots={{ transition: Fade }}
              slotProps={{ transition: { timeout: 400 } }}
              sx={{
                "& .MuiAccordion-region": {
                  height: expanded === cp.cp_id ? "auto" : 0,
                },
                "& .MuiAccordionDetails-root": {
                  display: expanded === cp.cp_id ? "block" : "none",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${cp.cp_id}-content`}
                id={`panel${cp.cp_id}-header`}
                sx={{ height: "20px" }}
              >
                <Typography>
                  {cp.first_name} {cp.last_name}
                </Typography>
                {!viewMode && (
                  <IconButton
                    edge="end"
                    color="primary"
                    onClick={() => handleRemoveCP(cp.cp_id)}
                    aria-label="remove CP"
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
              </AccordionSummary>
              <AccordionDetails
                style={{
                  display: "grid",
                  gridTemplateColumns: "245px 237px",
                  columnGap: ".1px",
                  rowGap: "15%",
                  color: "#454545",
                  paddingBottom: "7%",
                  paddingTop: "0",
                }}
              >
                <Typography
                  style={dataStyle}
                  variant="body2"
                >
                  <div>Degree:</div>
                  <div style={dataStyle.data}>{cp.degree}</div>
                </Typography>
                <Typography
                  style={dataStyle}
                  variant="body2"
                >
                  <div>Category:</div>
                  <div style={dataStyle.data}>{cp.category_id}</div>
                </Typography>
                <Typography
                  style={dataStyle}
                  variant="body2"
                >
                  <div>Speciality:</div>
                  <div style={dataStyle.data}>{cp.specialty}</div>
                </Typography>
                <Typography
                  style={dataStyle}
                  variant="body2"
                >
                  <div>Sub-speciality:</div>
                  <div style={dataStyle.data}>{cp.sub_specialty}</div>
                </Typography>
                <Typography
                  style={dataStyle}
                  variant="body2"
                >
                  <div>Registration No:</div>
                  <div style={dataStyle.data}>{cp.reg_no}</div>
                </Typography>
                <Typography
                  style={dataStyle}
                  variant="body2"
                >
                  <div>Fellowship:</div>
                  <div style={dataStyle.data}>{cp.fellowship}</div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </div>
    </div>
  )
}
