import React, { useState } from "react";
import "../../Admin_CSS/EmployeeForm.css"
import uploadIcon from "../../../../assets/Astha web app/upload icon.svg"
import profileIcon from "../../../../assets/Astha web app/Rectangle 24.svg"
import RoleSelector from "../EmployeeComponents/RolesPopupState"
import { errorToast, successToast } from "../../../../utils/toastConfig"
import Sidebar from "../../../../layout/Sidebar";
import axios from "axios";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../../utils/urlConfig"

// Define the initial state outside of the component
const initialEmployeeState = {
  firstName: "",
  middleName: "",
  lastName: "",
  gender: "",
  dob: "",
  email: "",
  photo: "",
  contact: "",
  zone: "",
  permanentAddress: {
    line1: "",
    line2: "",
    city: "",
    district: "",
    state: "",
    zipcode: ""
  },
  presentAddress: {
    line1: "",
    line2: "",
    city: "",
    district: "",
    state: "",
    zipcode: ""
  },
  idProof: {
    type: "",
    number: "",
    document: null
  }
}
const THProviderForm = () => {
  // State for the employee form
  const [employee, setEmployee] = useState(initialEmployeeState)
  const [profilePicture, setProfilePicture] = useState(profileIcon)
  const [sameAddress, setSameAddress] = useState(false)
  const [proofDocument, setProofDocument] = useState(null)

   // Handle change in input fields
  const handleChange = (e) => {
    const { name, value } = e.target

    if (
      name.startsWith("permanentAddress.") ||
      name.startsWith("presentAddress.") ||
      name.startsWith("idProof.")
    ) {
      const [section, field] = name.split(".")

      setEmployee((prev) => ({
        ...prev,
        [section]: {
          ...prev[section],
          [field]: value,
        },
      }))}

    else {
      setEmployee((prev) => ({
        ...prev,
        [name]: value,
      }))
    }
  }

  const handleCheckboxChange = (e) => {
    setSameAddress(e.target.checked)
    if (e.target.checked) {
      setEmployee((prev) => ({
        ...prev,
        presentAddress: { ...prev.permanentAddress },
      }))
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        setProfilePicture(reader.result)
        setEmployee((prev) => ({
          ...prev,
          photo: file,
        }))
      }
      reader.readAsDataURL(file)
    }
  }

  const handleProofDocumentChange = (e) => {
    const file = e.target.files[0]
    if (file && file.type === "application/pdf") {
      setProofDocument(file) // Update state with the selected PDF document
      setEmployee((prev) => ({
        ...prev,
        idProof: {
          ...prev.idProof,
          document: file, // Assign file to the proof document
        },
      }))
    } else {
      errorToast("Please upload a PDF document")
    }
  }

  const uploadFiles = async (files) => {
    const formData = new FormData()

    // Append files to FormData
    if (files.photo) {
      formData.append("photo", files.photo)
    }
    if (files.document) {
      formData.append("document", files.document)
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/api/upload-files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      console.log(response.data)
      return response.data // This should contain the URLs of the uploaded files
    } catch (error) {
      console.error(
        "Error uploading files:",
        error.response?.data || error.message
      )
      throw error
    }
  }

  const token =
  useSelector((state) => state.auth.token) || localStorage.getItem("token");
  const handleSubmit = async (e) => {
    e.preventDefault()
  
    if (
      !employee.firstName ||
      !employee.lastName ||
      !employee.gender ||
      !employee.zone ||
      !employee.permanentAddress.line1 ||
      !employee.permanentAddress.city ||
      !employee.permanentAddress.district ||
      !employee.permanentAddress.zipcode ||
      !employee.contact ||
      !employee.dob ||
      !employee.email ||
      !employee.photo || 
      !employee.idProof.type ||
      !employee.idProof.document 
    ) {
      errorToast("Please Enter the required fields")
      return
    } else {
  
    // Prepare files
    const files = {
      photo: employee.photo,
      document: proofDocument,
    }
  
    try {
      // Step 2: Upload files and get URLs
      const fileResponse = await uploadFiles(files)
      const { photoUrl, documentUrls } = fileResponse
  
      // Step 3: Prepare and send JSON data with URLs
      const jsonData = {
        ...employee,
        photo: photoUrl, // Assign photoUrl to photo
        idProof: {
          ...employee.idProof,
          document: documentUrls, // Assign documentUrls to proof.document
        },
      }
      console.log(jsonData);
      
  
      // Step 4: Send JSON data
      await axios.post(`${BASE_URL}/api/add-telehealth`, jsonData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,

        },
      })
  
      // Step 5: Reset form and state
      handleReset()
  
      // Step 6: Show success toast
      successToast("Form Submitted Successfully!")
    } catch (error) {
      errorToast("An error occurred while submitting the form.")
      console.error("Error:", error.response?.data || error.message)
    }}
  }

  const handleReset = () => {
    setEmployee(initialEmployeeState)
    setProfilePicture(profileIcon)
    setSameAddress(false)
  }

  return (
    <>
      <div>
        <Sidebar />
      </div>
      <div className="containers" style={{ margin: "0 18% 5%" }}>
        <h1 className="ah1">
          Add Telehealth Provider
          <RoleSelector />
        </h1>

        <form className="consultant-form" onSubmit={handleSubmit}>
          <div
            className="profile-section"
            style={{ gridTemplateColumns: "13em 14em 12em 8em" }}
          >
            <div id="profile">
              <h2>Profile</h2>
            </div>
            <div className="form-group">
              <label htmlFor="first-name">First Name</label>
              <input
                type="text"
                id="first-name"
                name="firstName"
                value={employee.firstName}
                onChange={handleChange}
                placeholder="First Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="middle-name">Middle Name</label>
              <input
                type="text"
                id="middle-name"
                name="middleName"
                value={employee.middleName}
                onChange={handleChange}
                placeholder="Middle Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="last-name">Last Name</label>
              <input
                type="text"
                id="last-name"
                name="lastName"
                value={employee.lastName}
                onChange={handleChange}
                placeholder="Last Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="gender">Gender</label>
              <select
                id="gender"
                name="gender"
                value={employee.gender}
                onChange={handleChange}
              >
                <option value="">Select</option>
                <option value="M">Male</option>
                <option value="F">Female</option>
                <option value="Others">Other</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="dob">Date of Birth</label>
              <input
                type="date"
                id="dob"
                name="dob"
                value={employee.dob}
                onChange={handleChange}
                placeholder="DD-MM-YYYY"
              />
            </div>
            <div className="form-group">
              <label htmlFor="idProof-type">ID Proof</label>
              <div>
                <select
                  id="proof-type1"
                  name="idProof.type"
                  value={employee.idProof.type}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="1">Aadhar</option>
                  <option value="2">ABHA</option>
                  <option value="3">Voter Id</option>
                  <option value="4">Driving License</option>
                  <option value="5">Ration Card</option>
                  <option value="6">Drug License</option>
                  <option value="7">Nabl License</option>
                </select>
                <span id="span">
                <img
                  type="file"
                  style={{
                    filter: proofDocument ? "invert(36%) sepia(76%) saturate(2696%) hue-rotate(81deg) brightness(95%) contrast(92%)" : "none",
                    cursor: "pointer",
                  }}
                  name="idProof.document"
                  value="idProof.document"
          
                  src={uploadIcon}
                  alt=""
                  onClick={() =>
                    document.getElementById("proof-document").click()
                  }
                />
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="proof-document"
                  accept="application/pdf"
                  onChange={handleProofDocumentChange} // Updated to handle PDF uploads
                />
              </span>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={employee.email}
                onChange={handleChange}
                placeholder="abc@xyz.com"
              />
            </div>
            <div className="form-group">
              <label htmlFor="contact">Contact</label>
              <input
                type="text"
                id="contact"
                name="contact"
                value={employee.contact}
                onChange={handleChange}
                placeholder="Mobile No."
              />
            </div>
            <div className="form-group">
              <label htmlFor="Zone-Id">Zone Id</label>
              <input
                type="number"
                id="zone"
                name="zone"
                value={employee.zone}
                onChange={handleChange}
                placeholder="Zone Id"
              />
            </div>
            <div id="profile-picture">
              <div>
                <img
                  src={profilePicture || uploadIcon}
                  alt="Profile"
                  // style={{
                  //   width: "100px",
                  //   height: "100px",
                  //   objectFit: "cover",
                  //   cursor: "pointer",
                  // }}
                  onClick={() =>
                    document.getElementById("profile-picture-input").click()
                  }
                />
                <span>Profile Picture</span>
                <input
                  type="file"
                  id="profile-picture-input"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>

          <div className="contact-address-section">
            <div id="contact-address">
              <h2>Permanent Address</h2>
            </div>
            <div className="form-group">
              <label htmlFor="permanent-address-line1">Address Line1</label>
              <input
                type="text"
                id="permanent-address-line1"
                name="permanentAddress.line1"
                value={employee.permanentAddress.line1}
                onChange={handleChange}
                placeholder="Address Line1"
              />
            </div>
            <div className="form-group">
              <label htmlFor="permanent-address-line2">Address Line2</label>
              <input
                type="text"
                id="permanent-address-line2"
                name="permanentAddress.line2"
                value={employee.permanentAddress.line2}
                onChange={handleChange}
                placeholder="Address Line2"
              />
            </div>
            <div className="form-group">
              <label htmlFor="permanent-address-city">City</label>
              <input
                type="text"
                id="permanent-address-city"
                name="permanentAddress.city"
                value={employee.permanentAddress.city}
                onChange={handleChange}
                placeholder="City"
              />
            </div>
            <div className="form-group">
              <label htmlFor="permanent-address-district">District</label>
              <input
                type="text"
                id="permanent-address-district"
                name="permanentAddress.district"
                value={employee.permanentAddress.district}
                onChange={handleChange}
                placeholder="District"
              />
            </div>
            <div className="form-group">
              <label htmlFor="permanent-address-state">State</label>
              <input
                type="text"
                id="permanent-address-state"
                name="permanentAddress.state"
                value={employee.permanentAddress.state}
                onChange={handleChange}
                placeholder="State"
              />
            </div>
            <div className="form-group">
              <label htmlFor="permanent-address-zipcode">Zipcode</label>
              <input
                type="text"
                id="permanent-address-zipcode"
                name="permanentAddress.zipcode"
                value={employee.permanentAddress.zipcode}
                onChange={handleChange}
                placeholder="Zipcode"
              />
            </div>
          </div>

          <div className="contact-address-section">
            <div id="present-address">
              <h2>Present Address</h2>
              <div>
                <label htmlFor="sameAddress">Same as Permanent Address</label>
                <input
                  type="checkbox"
                  name="sameAddress"
                  id="sameAddress"
                  checked={sameAddress}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
            {!sameAddress && (
              <>
                <div className="form-group">
                  <label htmlFor="present-address-line1">Address Line1</label>
                  <input
                    type="text"
                    id="present-address-line1"
                    name="presentAddress.line1"
                    value={employee.presentAddress.line1}
                    onChange={handleChange}
                    placeholder="Address Line1"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="present-address-line2">Address Line2</label>
                  <input
                    type="text"
                    id="present-address-line2"
                    name="presentAddress.line2"
                    value={employee.presentAddress.line2}
                    onChange={handleChange}
                    placeholder="Address Line2"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="present-address-city">City</label>
                  <input
                    type="text"
                    id="present-address-city"
                    name="presentAddress.city"
                    value={employee.presentAddress.city}
                    onChange={handleChange}
                    placeholder="City"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="present-address-district">District</label>
                  <input
                    type="text"
                    id="present-address-district"
                    name="presentAddress.district"
                    value={employee.presentAddress.district}
                    onChange={handleChange}
                    placeholder="District"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="present-address-state">State</label>
                  <input
                    type="text"
                    id="present-address-state"
                    name="presentAddress.state"
                    value={employee.presentAddress.state}
                    onChange={handleChange}
                    placeholder="State"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="present-address-zipcode">Zipcode</label>
                  <input
                    type="text"
                    id="present-address-zipcode"
                    name="presentAddress.zipcode"
                    value={employee.presentAddress.zipcode}
                    onChange={handleChange}
                    placeholder="Zipcode"
                  />
                </div>
              </>
            )}
          </div>
          <div className="form-actions">
            <button
              type="submit"
              className="save-btn"
            >
              Save
            </button>
            <button
              type="button"
              className="cancel-btn"
              onClick={handleReset}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  )
}
export default THProviderForm;
