
import { useState, useEffect } from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import axios from "axios"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import { errorToast, successToast } from "../../../utils/toastConfig"
import { BASE_URL } from "../../../utils/urlConfig"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 690,
  height: 400,
  bgcolor: "background.paper",
  borderRadius: "16px",
  boxShadow: 24,
  p: 4,
}
const group = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  input: {
    color: "#777",
    backgroundColor: "#f5f5f5",
    border: "1px solid #f5f5f5",
    borderRadius: "5px",
  },
  select:{
    color: "#777",
    minWidth: "58%",
    height: "100%",
    backgroundColor: "#f5f5f5",
    border: "1px solid #f5f5f5",
    borderRadius: "5px",
    
  }
}

export default function EditDrugModal({ drug, open, onClose, onDrugUpdate }) {
  const [categories, setCategories] = useState([])
  const [types, setTypes] = useState([])
  // State to hold form fields
  const [name, setName] = useState("")
  const [price, setPrice] = useState("")
  const [schedule, setSchedule] = useState("")
  const [composition, setComposition] = useState("")
  const [side_effects, setSideEffects] = useState("")
  const [selectedCategoryId, setSelectedCategoryId] = useState("")
  const [selectedTypeId, setSelectedTypeId] = useState("")
  const [viewMode, setViewMode]=useState(true)  // Start in view mode

  // Fetch categories and types once when component mounts
  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/drug-categories`
      )
      setCategories(response.data.drugCategories)
    } catch (error) {
      console.error("Error Fetching Categories", error)
    }
  }

  const fetchTypes = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/drug-types`)
      setTypes(response.data.drugTypes)
    } catch (error) {
      console.error("Error Fetching Types", error)
    }
  }

  useEffect(() => {
    // Fetch categories and types when the modal is opened
    fetchCategories()
    fetchTypes()
  
    if (drug) {
      setName(drug.name);
      setPrice(drug.price);
      setSchedule(drug.schedule);
      setComposition(drug.composition);
      setSideEffects(drug.side_effects);
    
      switch (drug.type_name) {
        case "Tablet":
          setSelectedTypeId(1);
          break;
        case "Capsule":
          setSelectedTypeId(2);
          break;
        case "Injection":
          setSelectedTypeId(3);
          break;
        case "Syrup":
          setSelectedTypeId(4);
          break;
        case "Ointment":
          setSelectedTypeId(5);
          break;
      }
    
      switch (drug.category_name) {
        case "Antibiotics":
          setSelectedCategoryId(1);
          break;
        case "Pain Relievers":
          setSelectedCategoryId(2);
          break;
        case "Antipyretics":
          setSelectedCategoryId(3);
          break;
        case "Antiseptics":
          setSelectedCategoryId(4);
          break;
        case "Mood Stabilizers":
          setSelectedCategoryId(5);
          break;
      }
    }
    
  }, [drug])

  // Handle the form submission
  const handleSubmit = async () => {
    console.log("Composition:", composition); // Debugging log
    console.log("Schedule:", schedule);       // Debugging log
    if (!name || !selectedCategoryId || !selectedTypeId || !schedule || !composition || !price || !side_effects) {
      errorToast("Please fill all the required fields.")
      return
    }

    const categoryIdNumber = Number(selectedCategoryId)
    const typeIdNumber = Number(selectedTypeId)

    if (isNaN(categoryIdNumber) || isNaN(typeIdNumber)) {
      errorToast("Invalid IDs. They must be numbers.")
      return
    }

    const drugData = {
      name,
      schedule,
      category_id: categoryIdNumber,
      type_id: typeIdNumber,
      composition,
      price,
      side_effects,
    }

    console.log("Drug Data being sent:", drugData); // Check this log
    try {
      let response
      if (drug) {
        // Update existing drug
        response = await axios.put(
          `${BASE_URL}/api/drug-details/${drug.id}`,
          drugData
        )
        successToast("Drug updated successfully!")
      } 
     

      if (onDrugUpdate) {
        onDrugUpdate()
      }

      onClose()
    } catch (error) {
      errorToast("An error occurred while updating the drug.")
      console.error(
        "Error saving Drug data:",
        error.response ? error.response.data : error.message
      )
    }
  }

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          {drug ? "Edit Drug" : "Add New Drug"}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Box sx={{ margin: "5% 0", display: "grid", gridTemplateColumns: "280px 280px", columnGap: "9%", rowGap: "29%" }}>
            <Box sx={group}>
              <label htmlFor="">Medicine Name</label>
              <input style={group.input} value={name} onChange={viewMode ? null : (e) => setName(e.target.value)} type="text" placeholder="Medicine Name" />
            </Box>
            <Box sx={group}>
              <label htmlFor="">Composition</label>
              <input style={group.input} value={composition} onChange={viewMode ? null : (e) => setComposition(e.target.value)} type="text" placeholder="Composition" />
            </Box>
            <Box sx={group}>
              <label htmlFor="">Schedule</label>
              <input style={group.input} value={schedule} onChange={viewMode ? null : (e) => setSchedule(e.target.value)} type="text" placeholder="Schedule" />
            </Box>
            <Box sx={group}>
              <label htmlFor="">Side Effects</label>
              <input style={group.input} value={side_effects} onChange={viewMode ? null : (e) => setSideEffects(e.target.value)} type="text" placeholder="Side Effects of Drug" />
            </Box>
            <Box sx={group}>
              <label htmlFor="state">Category</label>
              <select style={group.select} value={selectedCategoryId} onChange={viewMode ? null : (e) => setSelectedCategoryId(e.target.value)}>
                <option value="">Select Category</option>
                {categories.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.name}
                  </option>
                ))}
              </select>
            </Box>
            <Box sx={group}>
              <label htmlFor="state">Drug Type</label>
              <select style={group.select} value={selectedTypeId} onChange={viewMode ? null : (e) => setSelectedTypeId(e.target.value)}>
                <option value="">Select Type</option>
                {types.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.name}
                  </option>
                ))}
              </select>
            </Box>
            <Box sx={group}>
              <label htmlFor="">Price</label>
              <input style={group.input} value={price} onChange={viewMode ? null : (e) => setPrice(e.target.value)} type="text" placeholder="Price" />
            </Box>
          </Box>
        </Typography>
        <Box sx={{ width: "290px", display: "flex", margin: "20% 50%", justifyContent: "space-between" }}>
          
          <Button variant="contained" sx={{ borderRadius: "50px", backgroundColor: "#3F6584" }} onClick={onClose}>
            Cancel
          </Button>
          <Button
                variant="contained"
                sx={{ borderRadius: "50px",fontWeight: "550",color: "#454545", backgroundColor: "#ead8b1" }}
                onClick={() => setViewMode(!viewMode)}
              >
                {viewMode ? "Edit" : "View"}
              </Button>
          <Button disabled={viewMode} variant="contained" sx={{ width: "100px", fontWeight: "550", borderRadius: "50px", color: "#454545", backgroundColor: "#a9fcf7" }} onClick={handleSubmit}>
            Update
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}