
import { useState, useEffect } from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import axios from "axios"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import { errorToast, successToast } from "../../../utils/toastConfig"
import { BASE_URL } from "../../../utils/urlConfig"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 690,
  height: 400,
  bgcolor: "background.paper",
  borderRadius: "16px",
  boxShadow: 24,
  p: 4,
}
const group = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  input: {
    color: "#777",
    backgroundColor: "#f5f5f5",
    border: "1px solid #f5f5f5",
    borderRadius: "5px",
  },
  select:{
    color: "#777",
    minWidth: "58%",
    height: "100%",
    backgroundColor: "#f5f5f5",
    border: "1px solid #f5f5f5",
    borderRadius: "5px",
    
  }
}

export default function DrugModal({ onDrugUpdate }) {
  const [open, setOpen] = useState(false)
  const [categories, setCategories] = useState([]) //to store all the categories from API
  const [selectedCategoryId, setSelectedCategoryId] = useState("") //to store the selected category
  const [selectedTypeId, setSelectedTypeId] = useState("") //to store the selected type
  const [types, setTypes] = useState([])
  const [name, setName] = useState("")
  const [price, setPrice] = useState("")
  const [schedule, setSchedule] = useState("")
  const [composition, setComposition] = useState("")
  const [side_effects, setSideEffects] = useState("")

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/drug-categories`
      )
      setCategories(response.data.drugCategories) // Updated to match response structure
    } catch (error) {
      console.error("Error Fetching Categories", error)
    }
  }

  const fetchTypes = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/drug-types`
      )
      setTypes(response.data.drugTypes) // Updated to match response structure
    } catch (error) {
      console.error("Error Fetching Types", error)
    }
  }

  useEffect(() => {
    fetchCategories()
    fetchTypes()
  }, [])

  const handleSubmit = async () => {
    // Validate form inputs
    if (
      !name ||
      !selectedCategoryId ||
      !selectedTypeId ||
      !schedule ||
      !composition ||
      !price ||
      !side_effects
    ) {
      errorToast("Please fill all the required fields.")
      return
    }
    else {

    // Convert selectedIds to a number
    const categoryIdNumber = Number(selectedCategoryId)
    const typeIdNumber = Number(selectedTypeId)

    // Check if the conversion was successful
     if (isNaN(categoryIdNumber) || isNaN(typeIdNumber)) {
      errorToast("Invalid IDs. They must be numbers.")
      return
    }

    const drugData = {
      name,
      schedule,
      category_id: categoryIdNumber,
      type_id: typeIdNumber,
      composition,
      price,
      side_effects,
    }

    console.log("Submitting Drug data:", drugData)

    try {
      const response = await axios.post(
        `${BASE_URL}/api/drug-details`,
        drugData
      )
      console.log("Drug saved successfully:", response.data)
      successToast("Drug Saved Successfully!")

      if (onDrugUpdate) {
        onDrugUpdate()
      }
      handleClose()
    } catch (error) {
      errorToast("An error occurred while saving the Drug.")
      console.error(
        "Error saving Drug data:",
        error.response ? error.response.data : error.message
      )
    }
  }}

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        sx={{
          backgroundColor: "#6bc6b0",
          "&:hover": { backgroundColor: "#6bc6b0" },
          minWidth: "191px",
          height: "50px",
          fontWeight: "550",
        }}
        onClick={handleOpen}
      >
        + Add Drugs
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
          >
            Medicine Name
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            <Box
              sx={{
                margin: "5% 0",
                display: "grid",
                gridTemplateColumns: "280px 280px",
                columnGap: "9%",
                rowGap: "29%",
              }}
            >
              <Box sx={group}>
                <label htmlFor="">Medicine Name</label>
                <input
                  style={group.input}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  placeholder="Medicine Name"
                />
              </Box>
              <Box sx={group}>
                <label htmlFor="">Composition</label>
                <input
                  style={group.input}
                  value={composition}
                  onChange={(e) => setComposition(e.target.value)}
                  type="text"
                  placeholder="Composition"
                />
              </Box>
              <Box sx={group}>
                <label htmlFor="">Schedule</label>
                <input
                  style={group.input}
                  value={schedule}
                  onChange={(e) => setSchedule(e.target.value)}
                  type="text"
                  placeholder="Schedule"
                />
              </Box>
              <Box sx={group}>
                <label htmlFor="">Side Effects</label>
                <input
                  style={group.input}
                  value={side_effects}
                  onChange={(e) => setSideEffects(e.target.value)}
                  type="text"
                  placeholder="Side Effects of Drug"
                />
              </Box>
              <Box sx={group}>
                <label htmlFor="state">Category</label>
                <select
                  style={group.select}
                  value={selectedCategoryId}
                  onChange={(e) => setSelectedCategoryId(e.target.value)}
                >
                  <option value="">Select Category</option>
                  {categories.map((data) => (
                    <option
                      key={data.id}
                      value={data.id}
                    >
                      {data.name}
                    </option>
                  ))}
                </select>
              </Box>
              <Box sx={group}>
                <label htmlFor="state">Drug Type</label>
                <select
                  style={group.select}
                  value={selectedTypeId}
                  onChange={(e) => setSelectedTypeId(e.target.value)}
                >
                  <option value="">Select Type</option>
                  {types.map((data) => (
                    <option
                      key={data.id}
                      value={data.id}
                    >
                      {data.name}
                    </option>
                  ))}
                </select>
              </Box>
              <Box sx={group}>
                <label htmlFor="">Price</label>
                <input
                  style={group.input}
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  type="text"
                  placeholder="Price"
                />
              </Box>
            </Box>
          </Typography>
          <Box
            sx={{
              width: "200px",
              display: "flex",
              margin: "20% 66%",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              sx={{ borderRadius: "50px", backgroundColor: "#3F6584" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "100px",
                fontWeight: "550",
                borderRadius: "50px",
                color: "#454545",
                backgroundColor: "#a9fcf7",
              }}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}