import React, { useState, useEffect } from "react"
import axios from "axios"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import Sidebar from "../../../layout/Sidebar"
import ToggleSwitch from "./EmployeeComponents/ToggleSwitch/ToggleSwitch"
import { BASE_URL } from "../../../utils/urlConfig"

import {
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  TextField,
  Box,
} from "@mui/material"

const EmployeeDetails = () => {
  const [employees, setEmployees] = useState([])
  const [filterRole, setFilterRole] = useState("CP")
  const [filterStatus, setFilterStatus] = useState("")
  const [searchQuery, setSearchQuery] = useState("")
  const [page, setPage] = useState(1)
  const [rowsPerPage] = useState(5)
  const token =
  useSelector((state) => state.auth.token) || localStorage.getItem("token");
  const fetchData = async () => {
    let url = ""
    switch (filterRole) {
      case "CP":
        url = `${BASE_URL}/api/cp/`
        break
      case "CFCP":
        url = `${BASE_URL}/api/cfcp/`
        break
      case "CFEclinic":
        url = `${BASE_URL}/api/cfeclinic/`
        break
      case "TeleHealthProviders":
        url = `${BASE_URL}/api/telehealthproviders/`
        break
      default:
        url = `${BASE_URL}/api/cp/`
        break
    }

    try {
      const response = await axios.get(url,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      const data = response.data.map((emp) => {
        const zones = emp.assignedZones
          ? emp.assignedZones.map((zone) => zone.zone_name).join(", ") // if assignedZones exists and is an array
          : emp.zone && emp.zone.zone_name // if zone is an object
          ? emp.zone.zone_name
          : "N/A" // if no zone info is available
        return {
          id: emp.cp_id || emp.employee_id || emp.id,
          name: `${emp.first_name} ${emp.middle_name || ""} ${emp.last_name}`,
          mobile: emp.contact || emp.consultation_mobile,
          role: filterRole,
          zones: zones || emp.zone.zone_name || "N/A",
          status: emp.status || "Active",
        }
      })

      setEmployees(data)
    } catch (error) {
      console.error("Error fetching employee data:", error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [filterRole])

  const filteredEmployees = employees.filter(
    (emp) =>
      (filterStatus === "" || emp.status === filterStatus) &&
      (searchQuery === "" ||
        emp.name.toLowerCase().includes(searchQuery.toLowerCase()))
  )

  const indexOfLastEmployee = page * rowsPerPage
  const indexOfFirstEmployee = indexOfLastEmployee - rowsPerPage
  const currentEmployees = filteredEmployees.slice(
    indexOfFirstEmployee,
    indexOfLastEmployee
  )

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  const navigate = useNavigate()

  return (
    <>
      <div>
        <Sidebar />
      </div>
      <Box sx={{ padding: "0 2%", margin: "0 20%" }}>
        <Box
          sx={{
            fontWeight: "600",
            color: "#454545",
            fontSize: "larger",
            marginLeft: "-2%",
          }}
        >
          Employee Details
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "20px",
            "& .MuiOutlinedInput-input": {
              backgroundColor: "#f5f5f5",
              height: "16px",
              fontWeight: "medium",
            },
            "& .MuiSelect-select ": {
              padding: "14px 32px 14px 14px",
            },
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ minWidth: 340, marginRight: 2 }}
          />

          <FormControl
            variant="outlined"
            sx={{ minWidth: 210, marginRight: 2 }}
          >
            <InputLabel>Role</InputLabel>
            <Select
              value={filterRole}
              onChange={(e) => setFilterRole(e.target.value)}
              label="Role"
            >
              {/* <MenuItem value="">All Roles</MenuItem> */}
              <MenuItem value="CP">CP</MenuItem>
              <MenuItem value="CFCP">CFCP</MenuItem>
              <MenuItem value="CFEclinic">CFEclinic</MenuItem>
              <MenuItem value="TeleHealthProviders">
                Tele-Health Provider
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            sx={{ minWidth: 180, marginRight: 2 }}
          >
            <InputLabel>Status</InputLabel>
            <Select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              label="Status"
            >
              <MenuItem value="">All Status</MenuItem>
              <MenuItem value="Locked">Locked</MenuItem>
              <MenuItem value="Active">Active</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/addCP")}
            sx={{
              backgroundColor: "#6bc6b0",
              "&:hover": { backgroundColor: "#6bc6b0" },
              minWidth: "171px",
              height: "50px",
              fontWeight: "550",
            }}
          >
            + Add Employee
          </Button>
        </Box>

        <TableContainer
          component={Paper}
          sx={{ marginTop: "20px", minWidth: "70vw" }}
        >
          <Table>
            <TableHead sx={{ backgroundColor: "#5A79C9", color: "#fff" }}>
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    color: "#ffffff",
                    fontWeight: "550",
                  },
                }}
              >
                <TableCell>ID</TableCell>
                <TableCell>Employee Name</TableCell>
                <TableCell>Mobile No</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Zones</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentEmployees.map((emp) => (
                <TableRow
                  key={emp.id}
                  
                  style={{ cursor: "pointer" }}
                >
                  <TableCell onClick={() =>
                    navigate(`/employee/${emp.role.toLowerCase()}/${emp.id}`)
                  }>{emp.id}</TableCell>
                  <TableCell onClick={() =>
                    navigate(`/employee/${emp.role.toLowerCase()}/${emp.id}`)
                  }>{emp.name}</TableCell>
                  <TableCell onClick={() =>
                    navigate(`/employee/${emp.role.toLowerCase()}/${emp.id}`)
                  }>{emp.mobile}</TableCell>
                  <TableCell onClick={() =>
                    navigate(`/employee/${emp.role.toLowerCase()}/${emp.id}`)
                  }>{emp.role}</TableCell>
                  <TableCell onClick={() =>
                    navigate(`/employee/${emp.role.toLowerCase()}/${emp.id}`)
                  }>{emp.zones}</TableCell>
                  <TableCell>
                    <ToggleSwitch />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack
          spacing={2}
          style={{
            marginTop: "4vh",
            marginLeft: "50vw",
            marginRight: "-17vw",
            alignItems: "center",
          }}
        >
          <Pagination
            count={Math.ceil(filteredEmployees.length / rowsPerPage)}
            page={page}
            onChange={handlePageChange}
            sx={{
              "& .MuiPaginationItem-root": {
                borderRadius: "50%",
                marginBottom: "50px",
                color: "#0E044A",
                "&.Mui-selected": { backgroundColor: "#0E044A", color: "#fff" },
                "&:hover": { backgroundColor: "#0E044A20" },
              },
            }}
          />
        </Stack>
      </Box>
    </>
  )
}

export default EmployeeDetails