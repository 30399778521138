import { useState, useRef, useEffect } from "react";
import avatar from "../images/Avtar (1).svg";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DatePicker from "react-datepicker";
import DescriptionIcon from "@mui/icons-material/Description";
import CrossIcon from "@mui/icons-material/Close";
import axios from "axios";
import { successToast, errorToast } from "../../../utils/toastConfig";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/urlConfig"

const patientdata = {
  name: "",
  gender: "",
  dob: "",
  mobile: "",
  photo: "",
  refId: 21,
  address: {
    line1: "",
    line2: "",
    city: "",
    district: "",
    state: "",
    zipcode: "",
  },
  idProof: {
    type: "",
    number: "",
    document: null,
  },
};
const EditPatient = ({ editPatientid, setShowEditPatient }) => {
  const [buttonText, setButtonText] = useState("Upload");
  const [buttonColor, setButtonColor] = useState("#0E044A");
  const proofDocumentInputRef = useRef(null);

  const [patient, setPatient] = useState(patientdata);
  const [profilePicture, setProfilePicture] = useState(avatar);
  const [proofDocument, setProofDocument] = useState(null);
  const [fileName, setFileName] = useState("");

  //   const [viewMode, setViewMode] = useState(true)
  //   const [patient, setPatient] = useState(initialpatientState)
  const token =
  useSelector((state) => state.auth.token) || localStorage.getItem("token");
  useEffect(() => {
    const fetchpatientDetails = async () => {
      try {
        const response = await axios.get(
          `/api/patient/${editPatientid}`,
          {
            headers: {
              Authorization: `${token}`,
  
            },
          }
        );
        const data = response.data;
        console.log(data);

        setPatient({
          name: data.name,
          id: data.id,
          gender: data.gender,
          dob: new Date(data.dob).toISOString().split("T")[0],
          mobile: data.mobile,
          photo: data.photo,
          address: {
            line1: data.address.address_line1,
            line2: data.address.address_line2,
            city: data.address.city_block,
            district: data.address.district,
            state: data.address.state,
            zipcode: data.address.zipcode,
          },
          idProof: {
            type:
              data.id_proof.proof_type === "Aadhar"
                ? 1
                : data.id_proof.proof_type === "ABHA"
                ? 2
                : data.id_proof.proof_type === "VoterId"
                ? 3
                : null,
            number: data.id_proof.proof_no,
            document: data.id_proof.proof,
          },
        });

        setProfilePicture(
          data.photo
            ? `${BASE_URL}/api/image/${
                data.photo.startsWith("/") ? data.photo.slice(1) : data.photo
              }`
            : ""
        );

        setProofDocument(
          data.id_proof?.proof
            ? `${BASE_URL}/api/pdf/${
                data.id_proof.proof.startsWith("/")
                  ? data.id_proof.proof.slice(1)
                  : data.id_proof.proof
              }`
            : null
        );
      } catch (error) {
        console.error("Error fetching patient details:", error);
      }
    };

    fetchpatientDetails();
  }, [editPatientid]);

  const handleChange = (e) => {
    const { name: inputName, value } = e.target;

    if (inputName.startsWith("address.")) {
      const fieldName = inputName.split(".")[1];
      setPatient((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [fieldName]: value,
        },
      }));
    } else if (inputName.startsWith("idProof.")) {
      const fieldName = inputName.split(".")[1];
      setPatient((prev) => ({
        ...prev,
        idProof: {
          ...prev.idProof,
          [fieldName]: value,
        },
      }));
    } else {
      setPatient((prev) => ({
        ...prev,
        [inputName]: value,
      }));
    }
  };

  const handleProofDocumentChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setProofDocument(file);
      setButtonText("Uploaded");
      setButtonColor("green"); // Update state with the selected PDF document
      setPatient((prev) => ({
        ...prev,
        idProof: {
          ...prev.idProof,
          document: file, // Assign file to the proof document
        },
      }));
      setFileName(file.name);

    } else {
      errorToast("Please upload a PDF document");
    }
  };

  const uploadFiles = async (files) => {
    const formData = new FormData();

    // Append files to FormData
    if (files.photo) {
      formData.append("photo", files.photo);
    }
    if (files.document) {
      formData.append("document", files.document);
    }

    try {
      const response = await axios.post(
        "/api/upload-files",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${token}`,

          },
        }
      );
      console.log(response.data);
      return response.data; // This should contain the URLs of the uploaded files
    } catch (error) {
      console.error(
        "Error uploading files:",
        error.response?.data || error.message
      );
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !patient.photo ||
      !patient.name ||
      !patient.gender ||
      !patient.dob ||
      !patient.mobile ||
      !patient.address.line1 ||
      !patient.address.line2 ||
      !patient.address.city ||
      !patient.address.district ||
      !patient.address.state ||
      !patient.address.zipcode ||
      !patient.idProof.type ||
      !patient.idProof.number ||
      !patient.idProof.document
    ) {
      errorToast("Please Enter the required fields");
      return;
    } else {
      // Start with existing proof document if available
      let proof =
        patient.idProof.document.length > 0 ? patient.idProof.document : null;

      const files = {
        photo: patient.photo || null,
        document: proofDocument || null, // New document if uploaded
      };

      try {
        let fileResponse = null;

        // Only upload if there's a new document or photo
        if (files.photo || files.document) {
          fileResponse = await uploadFiles(files);
        }

        // Update proof document if a new one is uploaded and URLs are available
        if (
          fileResponse &&
          fileResponse.documentUrls &&
          fileResponse.documentUrls.length > 0
        ) {
          proof = fileResponse.documentUrls;
        } else {
          console.log(
            "No new document uploaded, retaining existing proof:",
            proof
          );
        }

        // Check if proof is still empty after uploads
        if (!proof || proof.length === 0) {
          errorToast("Please upload or select a proof document.");
          return;
        }

        const jsonData = {
          ...patient,
          photo: fileResponse?.photoUrl || patient.photo, // Use new photo URL if uploaded
          idProof: {
            ...patient.idProof,
            document: proof, // Updated or existing proof document
          },
        };
        // Perform the API update request
        await axios.put(
          `${BASE_URL}/api/patient/${editPatientid}`,
          jsonData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        successToast("patient details updated successfully!");
        setShowEditPatient(false);
      } catch (error) {
        errorToast("An error occurred while updating the patient details.");
        console.error("Error:", error.response?.data || error.message);
      }
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfilePicture(reader.result);
        setPatient((prev) => ({
          ...prev,
          photo: file,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="absolute block top-24 ml-300px bg-[#ECECEC] p-4 mx-auto">
        <p className=" text-xl font-semibold font-display underline">
          Update Patient Details
        </p>
        <form
          onSubmit={handleSubmit}
          className="mt-4 grid grid-cols-1 420px:grid-cols-2 sm:grid-cols-2 md:grid-cols-2 gap-8 px-4 lg:px-16"
        >
          {/* First Column */}
          <div className="flex flex-col">
            <img
              src={profilePicture || avatar}
              alt="Profile"
              className="w-20 contrast-200 h-20 rounded-full mb-2 cursor-pointer"
              onClick={() =>
                document.getElementById("profile-picture-input").click()
              }
            />
            <input
              type="file"
              id="profile-picture-input"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />

            <p className="font-medium mb-2 mx-5">UID: {patient.id}</p>

            <div className="flex flex-col w-full mb-4">
              <label className="font-medium">Patient Name</label>
              <input
                type="text"
                placeholder="Enter Patient's Full Name"
                className="border rounded px-2  w-full lg:w-[16rem] "
                name="name"
                value={patient.name}
                onChange={handleChange}
              />
            </div>

            <div className="flex flex-col w-full mb-4">
              <label className="font-medium">Age</label>
              <div className="flex items-center">
                {/* <CalendarMonthIcon className="z-20" /> */}
                {/* <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  className="relative -left-6 text-center border rounded px-2  w-full lg:w-[16rem]"
                /> */}

                <input
                  type="text"
                  placeholder="DD-MM-YYYY"
                  className="border rounded px-2  w-full lg:w-[16rem]"
                  name="dob"
                  value={patient.dob}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="flex flex-col w-full mb-4">
              <label className="font-medium">Gender</label>
              <select
                name="gender"
                id="gender"
                className="border rounded px-2  w-full lg:w-[16rem] "
                value={patient.gender}
                onChange={handleChange}
              >
                <option value="" disabled selected>
                  Select patient's gender
                </option>
                <option value="M">Male</option>
                <option value="F">Female</option>
                <option value="Others">Other</option>
              </select>
            </div>

            <div className="flex flex-col w-full mb-4">
              <label className="font-medium">Contact Number</label>
              <input
                type="text"
                placeholder="Enter Patient's Contact Number"
                className="border rounded px-2  w-full lg:w-[16rem]"
                name="mobile"
                value={patient.mobile}
                onChange={handleChange}
              />
            </div>

            <button
              type="submit"
              className="bg-[#0E044A] text-white rounded px-4 py-1 w-full lg:w-[15rem] mt-2"
            >
              Update
            </button>
          </div>

          {/* Second Column */}
          <div>
            <div className="flex flex-col mb-1">
              <label className="font-medium">Address</label>
              <input
                type="text"
                placeholder="Address Line 1"
                className="border rounded-md px-2  w-full lg:w-[16rem] "
                name="address.line1"
                value={patient.address.line1}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col mb-1">
              <input
                type="text"
                placeholder="Address Line 2"
                className="border rounded-md px-2  w-full lg:w-[16rem]"
                name="address.line2"
                value={patient.address.line2}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col mb-1">
              <input
                type="text"
                placeholder="City"
                className="border rounded-md px-2  w-full lg:w-[16rem]"
                name="address.city"
                value={patient.address.city}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col mb-1">
              <input
                type="text"
                placeholder="District"
                className="border rounded-md px-2  w-full lg:w-[16rem]"
                name="address.district"
                value={patient.address.district}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col mb-1">
              <input
                type="text"
                placeholder="State"
                className="border rounded-md px-2  w-full lg:w-[16rem]"
                name="address.state"
                value={patient.address.state}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col mb-1">
              <input
                type="text"
                placeholder="Pincode"
                className="border rounded-md px-2  w-full lg:w-[16rem]"
                name="address.zipcode"
                value={patient.address.zipcode}
                onChange={handleChange}
              />
            </div>

            <div className="flex flex-col mb-1">
              <label className="font-semibold text-gray-700">ID Proof</label>
              <div className="flex items-center gap-4 mt-2">
                <div className="flex flex-col">
                  <span className="text-gray-500">Type</span>
                  <select
                    className="border rounded px-2 text-gray-700"
                    name="idProof.type"
                    value={patient.idProof.type}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select ID Proof Type
                    </option>
                    <option value="1">Aadhar</option>
                    <option value="2">PAN</option>
                    <option value="3">Voter ID</option>
                  </select>
                </div>
                <div className="flex flex-col">
                  <span className="text-gray-500">Number</span>
                  <input
                    type="text"
                    className="border rounded px-2 w-full"
                    name="idProof.number"
                    value={patient.idProof.number}
                    onChange={handleChange}
                    maxLength="10"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col mb-4">
              <div className="border-2 border-dotted rounded border-blue-900 h-16 w-24 mt-2 flex flex-col justify-center items-center">
                <DescriptionIcon />
                <label
                  htmlFor="proof-document"
                  className="text-center cursor-pointer"
                  onClick={() => proofDocumentInputRef.current.click()}
                >
                  ID Proof
                  {fileName && (
                  <div className="text-blue-800 text-[8px]">
                    <span className="font-semibold leading-none">{fileName}</span>
                  </div>
                )}
                </label>
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="proof-document"
                  accept="application/pdf"
                  ref={proofDocumentInputRef}
                  onChange={handleProofDocumentChange}
                />
              </div>
              <button
                className="bg-[#0E044A] text-white rounded max-w-20 mt-2 py-1 mx-2"
                style={{ backgroundColor: buttonColor }}
                type="button"
              >
                {buttonText}
              </button>
            </div>
          </div>
          <CrossIcon
            className="absolute top-8 right-5 cursor-pointer"
            onClick={() => setShowEditPatient(false)}
          />
        </form>
      </div>
    </>
  );
};

export default EditPatient;
