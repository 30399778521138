import { useState, useRef } from "react";
import avatar from "../images/Avtar (1).svg";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DatePicker from "react-datepicker";
import DescriptionIcon from "@mui/icons-material/Description";
import CrossIcon from "@mui/icons-material/Close";
import axios from "axios";
import { successToast, errorToast } from "../../../utils/toastConfig";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/urlConfig";

const patientdata = {
  name: "",
  gender: "",
  dob: "",
  mobile: "",
  photo: "",
  refId: 21,
  address: {
    line1: "",
    line2: "",
    city: "",
    district: "",
    state: "",
    zipcode: "",
  },
  idProof: {
    type: "",
    number: "",
    document: null,
  },
};
const Register = ({
  setRegisterPatient,
  setShowSuccessCard,
  setPatientData,
  setId,
}) => {
  const [buttonText, setButtonText] = useState("Upload");
  const [buttonColor, setButtonColor] = useState("#0E044A");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const proofDocumentInputRef = useRef(null);
  const [fileName, setFileName] = useState("");

  const [patient, setPatient] = useState(patientdata);
  const [profilePicture, setProfilePicture] = useState(avatar);
  const [proofDocument, setProofDocument] = useState(null);
  // const [zipcode, setZipcode] = useState("");
  // const [state, setState] = useState("");
  // const [district, setDistrict] = useState("");
  // "proxy": "http://www.postalpincode.in",

  // const handleZipcodeChange = async (e) => {
  //   const enteredZipcode = e.target.value;
  //   setZipcode(enteredZipcode);

  //   if (enteredZipcode.length === 6) {
  //     // ZIP code validation
  //     try {
  //       const response = await axios.get(
  //         `http://www.postalpincode.in/api/pincode/${enteredZipcode}`
  //       );

  //       if (response.data && response.data.Status === "Success") {
  //         const firstPostOffice = response.data.PostOffice[0];
  //         setState(firstPostOffice.State);
  //         setDistrict(firstPostOffice.District);
  //       } else {
  //         alert("No data found for this ZIP code");
  //         setState("");
  //         setDistrict("");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       alert("Unable to fetch details for this ZIP code");
  //     }
  //   }
  // };

  const handleChange = (e) => {
    const { name: inputName, value } = e.target;

    if (inputName.startsWith("address.")) {
      const fieldName = inputName.split(".")[1];
      setPatient((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [fieldName]: value,
        },
      }));
    } else if (inputName.startsWith("idProof.")) {
      const fieldName = inputName.split(".")[1];
      setPatient((prev) => ({
        ...prev,
        idProof: {
          ...prev.idProof,
          [fieldName]: value,
        },
      }));
    } else {
      setPatient((prev) => ({
        ...prev,
        [inputName]: value,
      }));
    }
  };
  const handleProofDocumentChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setProofDocument(file);
      setButtonText("Uploaded");
      setButtonColor("green");
      setPatient((prev) => ({
        ...prev,
        idProof: {
          ...prev.idProof,
          document: file,
        },
      }));
      setFileName(file.name);
    } else {
      errorToast("Please upload a PDF document");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfilePicture(reader.result);
        setPatient((prev) => ({
          ...prev,
          photo: file,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadFiles = async (files) => {
    const formData = new FormData();

    // Append files to FormData
    if (files.photo) {
      formData.append("photo", files.photo);
    }
    if (files.document) {
      formData.append("document", files.document);
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/api/upload-files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${token}`,
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error(
        "Error uploading files:",
        error.response?.data || error.message
      );
      throw error;
    }
  };
  const token =
    useSelector((state) => state.auth.token) || localStorage.getItem("token");
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(token);
    console.log(patient);
    if (
      !patient.photo ||
      !patient.name ||
      !patient.gender ||
      !patient.dob ||
      !patient.mobile ||
      !patient.address.line1 ||
      !patient.address.line2 ||
      !patient.address.city ||
      !patient.address.district ||
      !patient.address.state ||
      !patient.address.zipcode ||
      !patient.idProof.type ||
      !patient.idProof.number ||
      !patient.idProof.document
    ) {
      console.log(e.error);
      errorToast("Please Enter the required fields");
      return;
    } else {
      const files = {
        photo: patient.photo,
        document: proofDocument,
      };

      try {
        // Step 2: Upload files and get URLs
        const fileResponse = await uploadFiles(files);
        const { photoUrl, documentUrls } = fileResponse;

        const jsonData = {
          ...patient,
          photo: photoUrl,
          idProof: {
            ...patient.idProof,
            document: documentUrls,
          },
        };

        // Step 4: Send JSON data
        const res = await axios.post(`${BASE_URL}/api/add-patient`, jsonData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        });

        setId(res.data.patient_id);
        console.log(res.data.patient_id);

        setPatientData(jsonData);
        setRegisterPatient(false);
        setShowSuccessCard(true);

        // Step 6: Show success toast
        successToast("Form Submitted Successfully!");
      } catch (error) {
        errorToast("An error occurred while submitting the form.");
        console.error("Error:", error.response?.data || error.message);
      }
    }
  };
  const handleDateChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setPatient((prev) => ({
      ...prev,
      dob: formattedDate,
    }));
    setSelectedDate(date);
  };

  return (
    <>
      <div className="absolute block top-24 ml-300px bg-[#ECECEC] p-4 mx-auto mt-20">
        <p className=" text-xl font-semibold font-display underline">
          Register Patient
        </p>
        <form
          onSubmit={handleSubmit}
          className="mt-4 grid grid-cols-1 420px:grid-cols-2 sm:grid-cols-2 md:grid-cols-2 gap-8 px-4 lg:px-16"
        >
          {/* First Column */}
          <div className="flex flex-col">
            <img
              src={profilePicture || avatar}
              alt="Profile"
              className="w-20 contrast-200 h-20 rounded-full mb-2 cursor-pointer"
              onClick={() =>
                document.getElementById("profile-picture-input").click()
              }
            />
            <input
              type="file"
              id="profile-picture-input"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />

            <p className="font-medium mb-2 mx-5">UH ID</p>

            <div className="flex flex-col w-full mb-4">
              <label className="font-medium">Patient Name</label>
              <input
                type="text"
                placeholder="Enter Patient's Full Name"
                className="border rounded px-2  w-full lg:w-[16rem] "
                name="name"
                value={patient.name}
                onChange={handleChange}
              />
            </div>

            <div className="flex flex-col w-full mb-4">
              <label className="font-medium">Age</label>
              <div className="flex items-center">
                {/* <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  className="relative -left-6 text-center border rounded px-2  w-full lg:w-[16rem]"
                /> */}

                <input
                  type="date"
                  id="dob"
                  name="dob"
                  placeholder="DD-MM-YYYY"
                  value={selectedDate}
                  dateFormat="dd-mm-yyyy"
                  onChange={(e) => handleDateChange(e.target.value)}
                  className="border rounded px-2  w-full lg:w-[16rem] cursor-pointer"
                />
              </div>
            </div>

            <div className="flex flex-col w-full mb-4">
              <label className="font-medium">Gender</label>
              <select
                name="gender"
                id="gender"
                className="border rounded px-2  w-full lg:w-[16rem] cursor-pointer "
                value={patient.gender}
                onChange={handleChange}
              >
                <option value="" disabled selected>
                  Select patient's gender
                </option>
                <option value="M">Male</option>
                <option value="F">Female</option>
                <option value="Others">Other</option>
              </select>
            </div>

            <div className="flex flex-col w-full mb-4">
              <label className="font-medium">Contact Number</label>
              <input
                type="text"
                placeholder="Enter Patient's Contact Number"
                className="border rounded px-2  w-full lg:w-[16rem]"
                name="mobile"
                value={patient.mobile}
                onChange={handleChange}
                maxLength="10"
              />
            </div>

            <button
              type="submit"
              className="bg-[#0E044A] text-white rounded px-4 py-1 w-full lg:w-[15rem] mt-2"
            >
              Register
            </button>
          </div>

          {/* Second Column */}
          <div>
            <div className="flex flex-col mb-1">
              <label className="font-medium">Address</label>
              <input
                type="text"
                placeholder="Address Line 1"
                className="border rounded-md px-2  w-full lg:w-[16rem] "
                name="address.line1"
                value={patient.address.line1}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col mb-1">
              <input
                type="text"
                placeholder="Address Line 2"
                className="border rounded-md px-2  w-full lg:w-[16rem]"
                name="address.line2"
                value={patient.address.line2}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col mb-1">
              <input
                type="text"
                placeholder="City"
                className="border rounded-md px-2  w-full lg:w-[16rem]"
                name="address.city"
                value={patient.address.city}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col mb-1">
              <input
                type="text"
                placeholder="District"
                className="border rounded-md px-2  w-full lg:w-[16rem]"
                name="address.district"
                value={patient.address.district}
                onChange={handleChange}
              />

              {/* <input
                type="text"
                id="district"
                className="border rounded-md px-2  w-full lg:w-[16rem]"
                value={district}
                readOnly
                placeholder="District will autofill"
              /> */}
            </div>
            <div className="flex flex-col mb-1">
              <input
                type="text"
                placeholder="State"
                className="border rounded-md px-2  w-full lg:w-[16rem]"
                name="address.state"
                value={patient.address.state}
                onChange={handleChange}
              />

              {/* 
<input
                type="text"
                id="state"
                className="border rounded-md px-2  w-full lg:w-[16rem]"
                value={state}
                readOnly
                placeholder="State will autofill"
              /> */}
            </div>
            <div className="flex flex-col mb-1">
              <input
                type="text"
                placeholder="Pincode"
                className="border rounded-md px-2  w-full lg:w-[16rem]"
                name="address.zipcode"
                value={patient.address.zipcode}
                onChange={handleChange}
              />

              {/* <input
                type="text"
                id="zipcode"
                className="border rounded-md px-2  w-full lg:w-[16rem]"
                value={zipcode}
                onChange={handleZipcodeChange}
                placeholder="Enter ZIP Code"
              /> */}
            </div>

            <div className="flex flex-col mt-2">
              <label className="font-semibold text-gray-700 mb-[-5px]">ID Proof</label>
              <div className="flex items-center gap-4">
                <div className="flex flex-col">
                  <div className="text-gray-500">Type</div>
                  <select
                    className="border rounded px-2 text-gray-700 cursor-pointer"
                    name="idProof.type"
                    value={patient.idProof.type}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select ID Proof Type
                    </option>
                    <option value="1">Aadhar</option>
                    <option value="2">PAN</option>
                    <option value="3">Voter ID</option>
                  </select>
                </div>
                <div className="flex flex-col">
                  <span className="text-gray-500">Number</span>
                  <input
                    type="text"
                    className="border rounded px-2  w-full"
                    name="idProof.number"
                    value={patient.idProof.number}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col mb-4">
              <div className="border-2 border-dotted rounded border-blue-900 min-h-16 w-24 mt-2 pt-1 flex flex-col justify-center items-center">
                <DescriptionIcon />
                <label
                  htmlFor="proof-document"
                  className="text-center cursor-pointer"
                >
                  ID Proof
                {fileName && (
                  <div className="text-blue-800 text-[8px]">
                    <span className="font-semibold leading-none">{fileName}</span>
                  </div>
                )}
                </label>
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="proof-document"
                  accept="application/pdf"
                  ref={proofDocumentInputRef}
                  onChange={handleProofDocumentChange}
                />
              </div>
              <button
                className="bg-[#0E044A] text-white rounded max-w-20 mt-2 py-1 mx-2"
                style={{ backgroundColor: buttonColor }}
                type="button"
                onClick={() => proofDocumentInputRef.current.click()}
              >
                {buttonText}
              </button>
            </div>
          </div>
          <CrossIcon
            className="absolute top-8 right-5 cursor-pointer"
            onClick={() => setRegisterPatient(false)}
          />
        </form>
      </div>
    </>
  );
};

export default Register;
