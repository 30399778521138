import * as React from "react"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Fade from "@mui/material/Fade"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import axios from "axios"
import { BASE_URL } from "../../../../utils/urlConfig"

export default function ZoneAccordion({
  onZoneSelectionChange,
  selectedZones: propSelectedZones,
  viewMode,
}) {
  const [expanded, setExpanded] = React.useState(false)
  const [zoneData, setZoneData] = React.useState([])
  const [filteredData, setFilteredData] = React.useState([])
  const [searchQuery, setSearchQuery] = React.useState("")
  const [selectedZones, setSelectedZones] = React.useState(
    new Set(propSelectedZones || [])
  )

  // Fetch zone data from the API
  React.useEffect(() => {
    const getZoneData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/zones`)
        setZoneData(response.data)
        setFilteredData(response.data)
      } catch (error) {
        console.error("Error fetching zone data:", error)
      }
    }
    getZoneData()
  }, [])

  // Filter zone data based on the search query
  React.useEffect(() => {
    setFilteredData(
      zoneData.filter((zone) =>
        zone.zone_name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    )
  }, [searchQuery, zoneData])

  // Update the selected zones when the prop changes
  React.useEffect(() => {
    setSelectedZones(new Set(propSelectedZones))
  }, [propSelectedZones])

  const handleExpansion = (zoneId) => {
    setExpanded((prevExpanded) => (prevExpanded === zoneId ? false : zoneId))
  }

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
  }

  const handleAddZone = (zone) => {
    setSelectedZones((prevSelectedZones) => {
      const newSelectedZones = new Set(prevSelectedZones)
      newSelectedZones.add(zone.zone_id)
      onZoneSelectionChange(Array.from(newSelectedZones)) // Pass updated zones back to the parent
      return newSelectedZones
    })
  }

  const handleRemoveZone = (zoneId) => {
    setSelectedZones((prevSelectedZones) => {
      const newSelectedZones = new Set(prevSelectedZones)
      newSelectedZones.delete(zoneId)
      onZoneSelectionChange(Array.from(newSelectedZones)) // Pass updated zones back to the parent
      return newSelectedZones
    })
  }

  return (
    <div style={{ backgroundColor: "white", minWidth: "40vh" }}>
      <div style={{ padding: "0 15px 15px", color: "#454545" }}>
        <h2>Assigned Zones</h2>
        {!viewMode && (
          <TextField
            label="Search Zone"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={handleSearchChange}
            value={searchQuery}
          />
        )}
      </div>
      {!viewMode && (
        <div style={{ color: "#454545" }}>
          <div
            style={{ height: "110px", padding: "0 5.5%", overflowY: "auto" }}
          >
            {filteredData.map((zone) => (
              <div
                key={zone.zone_id}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="body2"
                  style={{ flex: 1 }}
                >
                  {zone.zone_name}
                </Typography>
                <IconButton
                  color="primary"
                  onClick={() => handleAddZone(zone)}
                  aria-label="add zone"
                  disabled={selectedZones.has(zone.zone_id)} // Disable if already selected
                >
                  <AddIcon />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
      )}
      <hr />
      <div>
        {Array.from(selectedZones).map((zoneId) => {
          const zone = zoneData.find((item) => item.zone_id === zoneId)
          if (!zone) return null
          return (
            <Accordion
              key={zone.zone_id}
              expanded={expanded === zone.zone_id}
              onChange={() => handleExpansion(zone.zone_id)}
              slots={{ transition: Fade }}
              slotProps={{ transition: { timeout: 400 } }}
              sx={{
                "& .MuiAccordion-region": {
                  height: expanded === zone.zone_id ? "auto" : 0,
                },
                "& .MuiAccordionDetails-root": {
                  display: expanded === zone.zone_id ? "block" : "none",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${zone.zone_id}-content`}
                id={`panel${zone.zone_id}-header`}
                sx={{ height: "10px" }}
              >
                <Typography>{zone.zone_name}</Typography>
                {!viewMode && (
                  <IconButton
                    edge="end"
                    color="primary"
                    onClick={() => handleRemoveZone(zone.zone_id)}
                    aria-label="remove zone"
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
              </AccordionSummary>
              <AccordionDetails
                style={{
                  display: "grid",
                  gridTemplateColumns: "250px",
                  paddingTop: "0",
                  paddingBottom: "7%",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body2"
                  style={{
                    color: "#777",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>District:</div>
                  <div>{zone.district}</div>
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    color: "#777",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>Zipcode:</div>
                  <div>{zone.zipcode}</div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </div>
    </div>
  )
}
