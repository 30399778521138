import React from 'react';
import Sidebar from '../../../layout/Sidebar';
import Dashboard from './Dashboard';
import { Box } from '@mui/material';

function HomePage() {
  return (
  
    <div className="dashboard-page">
      <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box
        component="nav"
        // sx={{ flexShrink: { sm: 0 } }}
        >
      <h1 className="ah1 mt-[50px]">DashBoard</h1>
      <Dashboard/>
      </Box></Box>
      
      </div>
    
  );
}

export default HomePage;
