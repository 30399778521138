import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ roles = [] }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const userRole = useSelector((state) => state.auth.user?.roleId);

  // Check if the user is authenticated and authorized based on their role
  const isAuthorized = roles.length > 0 ? roles.includes(userRole) : true;

  return isAuthenticated && isAuthorized ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
