import React, { useState, useEffect } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import CrossIcon from "@mui/icons-material/Close";
import MicIcon from "@mui/icons-material/Mic";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import CircleIcon from "@mui/icons-material/Circle";
import StraightIcon from "@mui/icons-material/Straight";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DatePicker from "react-datepicker";
import avatar from "../images/Avtar (1).svg";
import axios from "axios";
import Video from "../../cf_e_clinic/dashboard/Video";
import Audio from "../../cf_e_clinic/dashboard/Audio";
import { successToast, errorToast } from "../../../utils/toastConfig";
import { BASE_URL } from "../../../utils/urlConfig"
import { useSelector } from "react-redux";

const patientdata = {
  patient_id: "",
  consultation_type: "",
  cp_id: "",
  chief_complaint: "",
  complaint_audio: "",
  complaint_video: "",
  documents: "",
  meeting_id: "",
  start_time: "",
  end_time: "",
};

const EditNewconsultation = ({
  setShoweditc,
  consultantid,
}) => {
  const [profilePicture, setProfilePicture] = useState();
  const [patient, setPatient] = useState({});
  const [consultation, setConsultation] = useState({});
  const token =
  useSelector((state) => state.auth.token) || localStorage.getItem("token");
  //age calculate
  function calculateAge(dob) {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }
    return age;
  }
  const age = calculateAge(patient.dob);
  const [user, setUser] = useState(patientdata);
  //   for btn
  const [selectedButton, setSelectedButton] = useState(null);
  const handleButtonClick = (value) => {
    setSelectedButton(value);
  };

  //for video recording
  const [videoRecord, setVideoRecord] = useState(false);
  const [videourl, setVideourl] = useState(null);
  //for audio recording
  const [audioRecord, setAudioRecord] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [document, setDocument] = useState([]);

  // patient card
  useEffect(() => {
    const fetchpatientDetails = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/consultations/${consultantid}`,
          {
            headers: {
              Authorization: `${token}`,
  
            },
          }
        );
        const data = response.data.consultation.patient_details;
        const Consultation = response.data.consultation;

        console.log("Response data:", response.data);
        setPatient(data);
        setConsultation(Consultation);

        setProfilePicture(
          data.photo
            ? `${BASE_URL}/api/image/${
                data.photo.startsWith("/") ? data.photo.slice(1) : data.photo
              }`
            : ""
        );

        setSelectedButton(response.data.consultation.consultation_type);

        // Handle multiple documents
        const documentsString = response.data.consultation.documents;
        const documentList = documentsString
          ? documentsString.split(",").map((url) => ({
              name: url.split("/").pop(), // Extract file name from URL
              url: url.trim(), // Ensure no trailing spaces
            }))
          : [];
        // Replace the state to avoid duplication
        setDocument(documentList);
      } catch (error) {
        console.error("Error fetching patient details:", error);
      }
    };
    fetchpatientDetails();
  }, []);

  //date and time formating
  const [selectedDate, setSelectedDate] = useState(new Date());
  const formatDateTime = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  const schedule = formatDateTime(selectedDate);
  //document upload

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      await uploadFile(file);
    }
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("document", file);

    try {
      const response = await axios.post(
        `${BASE_URL}/api/upload-files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${token}`,

          },
        }
      );
      console.log(response.data);

      console.log(response.data.documentUrls[0]);
      const documentUrl = response.data.documentUrls[0]
        ? `${BASE_URL}/api/pdf/${
            response.data.documentUrls[0].startsWith("/")
              ? response.data.documentUrls[0].slice(1)
              : response.data.documentUrls[0]
          }`
        : null;
      console.log(documentUrl);

      setDocument((prevDocs) => [
        ...prevDocs,
        { name: file.name, url: documentUrl },
      ]);
      console.log(setDocument);
    } catch (error) {
      console.error(
        "Error uploading file:",
        error.response?.data || error.message
      );
    }
  };
  const handleDelete = (index) => {
    setDocument((prevDocs) => prevDocs.filter((_, i) => i !== index));
  };

  //Consultant Providers
  const [showConsultantProvider, setShowConsultantProvider] = useState(false);
  const [cp, setCp] = useState([]);
  const [cpid, setCpid] = useState();
  const [cpname, setCpname] = useState();
  const [totalcp, setTotalcp] = useState(0);

  useEffect(() => {
    const fetchCp = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/cp`);
        const data = response.data;
        console.log("Fetched CPs:", data);
        setCp(data);
        setTotalcp(data.length);
      } catch (error) {
        console.error("Error fetching cp:", error);
      }
    };
    fetchCp();
  }, []);
  const handlecp = (id, f, m, l) => {
    setShowConsultantProvider(false);
    setCpid(id);
    setCpname(`${f} ${m} ${l}`);
    console.log(cpname);
    console.log(cpid);
  };
  const [searchTerm, setSearchTerm] = useState("");

  // Function to handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter the consultant providers based on the search term
  const filteredCP = cp.filter((item) => {
    const fullName = `${item.first_name} ${item.middle_name || ""} ${
      item.last_name
    }`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });
  const [complaint, setComplaint] = useState("");

  // Add 45 minutes to the schedule time
  const scheduleDate = new Date();
  const newDate = new Date(scheduleDate);
  newDate.setMinutes(scheduleDate.getMinutes() + 45);
  const formattedScheduleDate = scheduleDate
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");
  const formattedNewDate = newDate.toISOString().slice(0, 19).replace("T", " ");

  const updateUserData = () => {
    const updatedData = {
      patient_id: patient.id,
      consultation_type: selectedButton,
      cp_id: cpid,
      chief_complaint: complaint,

    
      complaint_audio: audioUrl || consultation.complaint_audio,
      complaint_video: videourl || consultation.complaint_video,
      documents: document.map((doc) => doc.url).join(","),
      start_time: formattedScheduleDate,
      end_time: formattedNewDate,
    };
    setUser(updatedData);
  };

  // console.log(user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateUserData();
    console.log(token)
    console.log(user);
    if (!user.cp_id || !user.start_time || !user.end_time) {
      console.log("Please Enter the required fields", e.error);
      errorToast("Please Enter the required fields");
      return;
    } else {
      try {
        const response = await axios.put(
          `${BASE_URL}/api/consultations/${consultantid}`,
          user,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,

            },
          }
        );
        console.log(response);
        successToast("Consultation Updated Successfully!");
        setShoweditc(false);
      } catch (error) {
        errorToast("An error occurred while submitting the form.");
        console.error("Error:", error.response?.data || error.message);
      }
    }
  };

  return (
    <>
      <div
        className={`block absolute top-20 mt-6 bg-gray-100 p-4 md:p-6 max-h-[80vh] max-w-[95vw] overflow-y-auto rounded-lg 
          ${videoRecord ? "blur-md" : ""}
        ${audioRecord ? "blur-md" : ""}`}
      >
        <CrossIcon
          onClick={() => setShoweditc(false)}
          className="absolute top-4 right-4 cursor-pointer text-gray-600 hover:text-gray-900 transition-colors"
          style={{ fontSize: "24px" }}
        />
        <div className="text-xl font-bold underline">Update Consultation</div>
        <div className="grid gap-1 grid-cols-1 md:grid-cols-2">
          {/* Left Side */}
          <div className="p-4 rounded-lg">
            {/* Patient Info */}
            <div className="grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-3 items-center bg-gray-200 p-4 rounded-lg">
              <div className="flex justify-center md:justify-start">
                <img
                  src={profilePicture || avatar}
                  alt="Avatar"
                  className="contrast-200 h-20 w-20 sm:ml-5 lg:mx-auto md:mx-auto lg:px-1 "
                />
              </div>
              <div className="space-y-1 text-center ">
                <div>
                  <span className="font-semibold">Name:</span>
                  <span className="ml-1">{patient.name}</span>
                </div>
                <div className="text-nowrap">
                  <span className="font-semibold">Patient ID:</span>
                  <span className="ml-1">{patient.id}</span>
                </div>
                <div className="text-nowrap md:text-wrap">
                  <span className="font-semibold">Mobile No:</span>
                  <span className="ml-1">{patient.mobile}</span>
                </div>
              </div>
              <div className="space-y-1 text-center">
                <div>
                  <span className="font-semibold">Age:</span>
                  <span className="ml-1">{age}</span>
                </div>
                <div>
                  <span className="font-semibold">Sex:</span>
                  <span className="ml-1">{patient.gender}</span>
                </div>
              </div>
            </div>

            {/* Chief Complaint */}
            <div className="mt-5">
              <div className="text-lg font-semibold">Chief Complaint</div>
              <textarea
                placeholder="Enter Patient Complaints Here"
                className="w-full p-2 border rounded-lg focus:outline-none"
                value={consultation.chief_complaint}
                onChange={(e) => setComplaint(e.target.value)}
              ></textarea>
            </div>

            {/* Audio and Video Buttons */}
            <div
              className={`mt-5 flex flex-wrap justify-center md:justify-start `}
            >
              <button
                className="border-2 border-[#0E044A] m-2 p-2 text-[#0E044A] rounded-lg flex items-center font-semibold"
                onClick={() => setAudioRecord(!audioRecord)}
              >
                <MicIcon className="mr-2" />
                Record Audio
              </button>
              <button
                className="border-2 border-[#0E044A] m-2 p-2 text-[#0E044A] rounded-lg flex items-center font-semibold"
                onClick={() => setVideoRecord(!videoRecord)}
              >
                <VideoCallIcon className="mr-2" />
                Record Video
              </button>
            </div>

            {/* Assigned CP Section */}
            <div className="mt-10 flex flex-col md:flex-row md:items-center gap-4 z-10">
              <div className="flex flex-grow items-center">
                <button className="p-2  bg-[#0E044A] text-white rounded-l-lg w-[130px]">
                  Assigned CP
                </button>
                <input
                  type="text"
                  className="p-2 border border-gray-300 rounded-r-lg flex-grow"
                  placeholder="Enter CP Name"
                  value={cpname}
                />
              </div>
              <div>
                <span className="border border-gray-300 rounded-lg p-2 flex items-center">
                  <CircleIcon
                    style={{ color: "green", fontSize: "10px" }}
                    className="mr-2"
                  />
                  <button
                  className="w-[100px] z-10"
                    onClick={() =>
                      setShowConsultantProvider(!showConsultantProvider)
                    }
                  >
                    {" "}
                    {totalcp} Available CP
                  </button>
                </span>
              </div>
            </div>

            <div className="md:flex md:flex-row flex-col gap-4 mt-5">
              {/* Select Date Section */}
              <div className="text-[#0E044A] flex flex-col md:flex-1 m-2">
                <label className="font-semibold mb-1">Select Date</label>
                <DatePicker
                  className="p-2 border border-gray-300 rounded-lg w-full focus:outline-none"
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>

              {/* Select Time Slot Section */}
              <div className="text-[#0E044A] flex flex-col md:flex-1 m-2">
                <label className="font-semibold mb-1">Select Time Slot</label>
                <DatePicker
                  selected={selectedDate}
                  onChange={(time) => setSelectedDate(time)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm: aa"
                  className="p-2 border border-gray-300 rounded-lg w-full focus:outline-none"
                  placeholderText="Select Time"
                />
              </div>
            </div>

            {/* New Consultation Section */}
          </div>

          {/* Right Side */}
          <div className="bg-gray-100 p-4 rounded-lg">
            <div className="flex flex-col gap-4">
              {/* Choose Consultation Type */}
              <div>
                <div className="text-lg font-semibold mb-2">
                  Choose Consultation Type
                </div>{" "}
                <div className="flex flex-wrap justify-center md:justify-start gap-2">
                  <button
                    className={`border w-[150px] ${
                      selectedButton === 1 ? "bg-[#0E044A]" : "bg-gray-300"
                    } text-white rounded-lg m-1 p-1`}
                    onClick={() => handleButtonClick(1)}
                  >
                    Instant
                  </button>
                  <button
                    className={`border w-[150px] ${
                      selectedButton === 2 ? "bg-[#0E044A]" : "bg-gray-300"
                    } text-white rounded-lg m-1 p-1`}
                    onClick={() => handleButtonClick(2)}
                  >
                    Schedule
                  </button>
                </div>
              </div>

              {/* Supporting Documents */}
              <div className="text-lg font-semibold mb-2 mt-20 flex items-center">
                Supporting Documents
                <input
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="file-upload"
                />
                <label htmlFor="file-upload">
                  <DescriptionIcon className="ml-auto cursor-pointer" />
                </label>
                <StraightIcon />
              </div>
              <div className="space-y-2">
                {document.map((doc, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-2 border-b pb-2"
                  >
                    <DescriptionIcon />
                    <span>{`${index + 1}. ${doc.name}`}</span>{" "}
                    {/* Display actual document name */}
                    <a href={doc.url} target="_blank" rel="noopener noreferrer">
                      <VisibilityIcon className="ml-auto cursor-pointer" />
                    </a>
                    <DeleteIcon
                      className="cursor-pointer"
                      onClick={() => handleDelete(index)} // Handle delete click
                    />
                  </div>
                ))}
              </div>

              {/* Book Consultation Button */}
              <div className="mt-20 text-end">
                <button
                  onClick={handleSubmit}
                  className="border p-2 rounded-lg bg-[#0E044A] text-white"
                >
                  Update Consultation
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Consultant Providers Card */}
      <div
        className={`absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-opacity-50 bg-gray-700 ${
          showConsultantProvider ? "block" : "hidden"
        }`}
      >
        <div className="bg-white rounded-lg shadow-lg p-4 w-full max-w-md">
          {/* Title and Close Icon */}
          <div className="flex items-center justify-between mb-4">
            <div className="text-xl font-semibold">Consultant Providers</div>
            <CrossIcon
              className="cursor-pointer text-gray-500 hover:text-gray-700"
              onClick={() => setShowConsultantProvider(!showConsultantProvider)}
            />
          </div>

          {/* Search Input */}
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search Consultant..."
              className="w-full rounded-full p-2 border focus:outline-none focus:border-blue-500"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>

          {/* Consultant List */}
          <div className="space-y-2 overflow-y-auto overflow-x-hidden max-h-48">
            {filteredCP.length > 0 ? (
              filteredCP.map((item) => (
                <h1
                  key={item.cp_id}
                  onClick={() =>
                    handlecp(
                      item.cp_id,
                      item.first_name,
                      item.middle_name,
                      item.last_name
                    )
                  }
                  className="border p-2 rounded-lg m-1 w-full bg-gray-100 hover:bg-gray-200 cursor-pointer"
                >
                  {item.first_name} {item.middle_name} {item.last_name}
                </h1>
              ))
            ) : (
              <p>No CPs found</p>
            )}
          </div>
        </div>
      </div>

      {videoRecord && (
        <div>
          <Video setVideoRecord={setVideoRecord} setVideourl={setVideourl} />
        </div>
      )}
      {audioRecord && (
        <div>
          <Audio setAudioRecord={setAudioRecord} setAudioUrl={setAudioUrl} />
        </div>
      )}
    </>
  );
};

export default EditNewconsultation;
