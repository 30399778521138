import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../../layout/Sidebar.jsx";
import DrugModal from "./DrugModal.jsx";
import EditDrugModal from "./EditDrugModal.jsx";
import { BASE_URL } from "../../../utils/urlConfig"

import {
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Box,
} from "@mui/material";

const DrugDetails = () => {
  const [drugs, setDrugs] = useState([]);
  const [filterType, setFilterType] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [selectedDrug, setSelectedDrug] = useState(null); // State for the selected drug
  const [openModal, setOpenModal] = useState(false); // State for modal visibility

  const fetchDrugs = async () => {
    const response = await axios.get(
      `${BASE_URL}/api/drug-details/`
    );
    setDrugs(response.data.drugDetails);
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/drug-categories`
      );
      setCategories(response.data.drugCategories || []);
    } catch (error) {
      console.error("Error Fetching Categories", error);
    }
  };

  const fetchTypes = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/drug-types`
      );
      setTypes(response.data.drugTypes || []);
    } catch (error) {
      console.error("Error Fetching Types", error);
    }
  };

  useEffect(() => {
    fetchDrugs();
    fetchCategories();
    fetchTypes();
  }, []);

  const handleDrugUpdate = () => {
    fetchDrugs();
  };

  const filteredDrugs = drugs.filter(
    (drug) =>
      (filterCategory === "" ||
        drug.category_name ===
          categories.find((cat) => cat.id === filterCategory)?.name) &&
      (filterType === "" ||
        drug.type_name ===
          types.find((type) => type.id === filterType)?.name) &&
      (searchQuery === "" ||
        drug.name.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const indexOfLastDrug = page * rowsPerPage;
  const indexOfFirstDrug = indexOfLastDrug - rowsPerPage;
  const currentDrugs = filteredDrugs.slice(indexOfFirstDrug, indexOfLastDrug);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowClick = (drug) => {
    setSelectedDrug(drug); // Set the selected drug data
    setOpenModal(true); // Open the modal
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal
    setSelectedDrug(null); // Clear the selected drug
  };

  return (
    <>
      <div>
        <Sidebar />
      </div>
      <Box sx={{ padding: "0 2%", margin: "0 20%" }}>
        <Box
          sx={{
            fontWeight: "600",
            color: "#454545",
            fontSize: "larger",
            marginLeft: "-2%",
          }}
        >
          Drug Details
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "3%",
            marginBottom: "20px",
            "& .MuiOutlinedInput-input": {
              backgroundColor: "#f5f5f5",
              height: "16px",
              fontWeight: "medium",
            },
            "& .MuiSelect-select ": {
              padding: "14px 32px 14px 14px",
            },
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ minWidth: 315, marginRight: 2 }}
          />

          <FormControl
            variant="outlined"
            sx={{ minWidth: 200, marginRight: 2 }}
          >
            <InputLabel>Category</InputLabel>
            <Select
              value={filterCategory}
              onChange={(e) => setFilterCategory(e.target.value)}
              label="Category"
            >
              <MenuItem value="">All Categories</MenuItem>
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            sx={{ minWidth: 200, marginRight: 2 }}
          >
            <InputLabel>Drug Type</InputLabel>
            <Select
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
              label="Drug Type"
            >
              <MenuItem value="">All Drug Types</MenuItem>
              {types.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* pass handleDrugUpdate to DrugModal */}
          <DrugModal onDrugUpdate={handleDrugUpdate} />
        </Box>

        <TableContainer
          component={Paper}
          sx={{ marginTop: "20px", minWidth: "70vw" }}
        >
          <Table>
            <TableHead
              sx={{
                border: "1px",
                cursor: "pointer",
                backgroundColor: "#5A79C9",
                color: "rgba(11 96 96)",
              }}
            >
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    color: "#ffffff",

                    cursor: "pointer",
                    fontWeight: "550",
                  },
                }}
              >
                <TableCell>Drug Type</TableCell>
                <TableCell>Medicine Name</TableCell>
                <TableCell>Schedule</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Composition</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Side Effects</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentDrugs.map((drug) => (
                <TableRow key={drug.id} onClick={() => handleRowClick(drug)} sx={{cursor:"pointer"}}>
                  <TableCell>{drug.type_name}</TableCell>
                  <TableCell>{drug.name}</TableCell>
                  <TableCell>{drug.schedule}</TableCell>
                  <TableCell>{drug.category_name}</TableCell>
                  <TableCell>{drug.composition}</TableCell>
                  <TableCell>{drug.price}</TableCell>
                  <TableCell>{drug.side_effects}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack
          spacing={2}
          style={{
            marginTop: "4vh",
            marginLeft: "50vw",
            marginRight: "-17vw",
            alignItems: "center",
          }}
        >
          <Pagination
            page={page}
            onChange={handlePageChange}
            sx={{
              "& .MuiPaginationItem-root": {
                borderRadius: "50%",
                marginBottom: "50px",
                color: "#0E044A",
                "&.Mui-selected": {
                  backgroundColor: "#0E044A",
                  color: "#fff",
                },
                "&:hover": {
                  backgroundColor: "#0E044A20",
                },
              },
            }}
          />
        </Stack>
      </Box>

      {/* DrugModal component */}
      {selectedDrug && (
        <EditDrugModal
          open={openModal}
          onClose={handleCloseModal}
          drug={selectedDrug} // Pass the selected drug
          onDrugUpdate={handleDrugUpdate}
        />
      )}
    </>
  );
};

export default DrugDetails;

// import React, { useState, useEffect } from "react"
// import axios from "axios"
// import { useNavigate } from "react-router-dom"
// import Sidebar from "../../layout/Sidebar"
// import DrugModal from "./../../components/DrugModal"
// import {
//   Pagination,
//   Stack,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Select,
//   MenuItem,
//   FormControl,
//   InputLabel,
//   TextField,
//   Box,
// } from "@mui/material"

// const DrugDetails = () => {
//   const [drugs, setDrugs] = useState([])
//   const [filterType, setFilterType] = useState("")
//   const [filterCategory, setFilterCategory] = useState("")
//   const [categories, setCategories] = useState([])
//   const [types, setTypes] = useState([])
//   const [searchQuery, setSearchQuery] = useState("")
//   const [page, setPage] = useState(1)
//   const [rowsPerPage] = useState(5)

//   const fetchDrugs = async () => {
//     const response = await axios.get(
//       "${BASE_URL}/api/drug-details/"
//     )
//     setDrugs(response.data.drugDetails)
//   }

//   const fetchCategories = async () => {
//     try {
//       const response = await axios.get(
//         "${BASE_URL}/api/drug-categories"
//       )
//       setCategories(response.data.drugCategories || [])
//     } catch (error) {
//       console.error("Error Fetching Categories", error)
//     }
//   }

//   const fetchTypes = async () => {
//     try {
//       const response = await axios.get(
//         "${BASE_URL}/api/drug-types"
//       )
//       setTypes(response.data.drugTypes || [])
//     } catch (error) {
//       console.error("Error Fetching Types", error)
//     }
//   }
//  const navigate =useNavigate()
//   useEffect(() => {
//     fetchDrugs()
//     fetchCategories()
//     fetchTypes()
//   }, [])

//   const handleDrugUpdate = () => {
//     fetchDrugs()
//   }
//   console.log(drugs)
//   const filteredDrugs = drugs.filter(
//     (drug) =>
//       (filterCategory === "" ||
//         drug.category_name ===
//           categories.find((cat) => cat.id === filterCategory)?.name) &&
//       (filterType === "" ||
//         drug.type_name ===
//           types.find((type) => type.id === filterType)?.name) &&
//       (searchQuery === "" ||
//         drug.name.toLowerCase().includes(searchQuery.toLowerCase()))
//   )

//   const indexOfLastDrug = page * rowsPerPage
//   const indexOfFirstDrug = indexOfLastDrug - rowsPerPage
//   const currentDrugs = filteredDrugs.slice(indexOfFirstDrug, indexOfLastDrug)

//   const handlePageChange = (event, value) => {
//     setPage(value)
//   }

//   return (
//     <>
//       <div>
//         <Sidebar />
//       </div>
//       <Box sx={{ padding: "0 2%", margin: "0 20%" }}>
//         <Box
//           sx={{
//             fontWeight: "600",
//             color: "#454545",
//             fontSize: "larger",
//             marginLeft: "-2%",
//           }}
//         >
//           Drug Details
//         </Box>
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             marginTop: "3%",
//             marginBottom: "20px",
//             "& .MuiOutlinedInput-input": {
//               backgroundColor: "#f5f5f5",
//               height: "16px",
//               fontWeight: "medium",
//             },
//             "& .MuiSelect-select ": {
//               padding: "14px 32px 14px 14px",
//             },
//           }}
//         >
//           <TextField
//             variant="outlined"
//             placeholder="Search..."
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)}
//             sx={{ minWidth: 345, marginRight: 2 }}
//           />

//           <FormControl
//             variant="outlined"
//             sx={{ minWidth: 200, marginRight: 2 }}
//           >
//             <InputLabel>Category</InputLabel>
//             <Select
//               value={filterCategory}
//               onChange={(e) => setFilterCategory(e.target.value)}
//               label="Category"
//             >
//               <MenuItem value="">All Categories</MenuItem>
//               {categories.map((category) => (
//                 <MenuItem
//                   key={category.id}
//                   value={category.id}
//                 >
//                   {category.name}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           <FormControl
//             variant="outlined"
//             sx={{ minWidth: 200, marginRight: 2 }}
//           >
//             <InputLabel>Drug Type</InputLabel>
//             <Select
//               value={filterType}
//               onChange={(e) => setFilterType(e.target.value)}
//               label="Drug Type"
//             >
//               <MenuItem value="">All Categories</MenuItem>
//               {types.map((type) => (
//                 <MenuItem
//                   key={type.id}
//                   value={type.id}
//                 >
//                   {type.name}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           {/* pass handleDrugUpdate to DrugModal */}
//           <DrugModal onDrugUpdate={handleDrugUpdate} />
//         </Box>

//         <TableContainer
//           component={Paper}
//           sx={{ marginTop: "20px", minWidth: "70vw" }}
//         >
//           <Table>
//             <TableHead
//               sx={{
//                 border: "1px",
//                 backgroundColor: "#5A79C9",
//                 color: "rgba(11 96 96)",
//               }}
//             >
//               <TableRow
//                 sx={{
//                   "& .MuiTableCell-root": {
//                     color: "#ffffff",
//                     fontWeight: "550",
//                   },
//                 }}
//               >
//                 <TableCell>Drug Type</TableCell>
//                 <TableCell>Medicine Name</TableCell>
//                 <TableCell>Schedule</TableCell>
//                 <TableCell>Category</TableCell>
//                 <TableCell>Composition</TableCell>
//                 <TableCell>Price</TableCell>
//                 <TableCell>Side Effects</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {currentDrugs.map((drug) => (
//                 <TableRow key={drug.id} onClick={()=>{navigate(`/drug-details/${drug.id}`)}}>
//                   <TableCell>{drug.type_name}</TableCell>
//                   <TableCell>{drug.name}</TableCell>
//                   <TableCell>{drug.schedule}</TableCell>
//                   <TableCell>{drug.category_name}</TableCell>
//                   <TableCell>{drug.composition}</TableCell>
//                   <TableCell>{drug.price}</TableCell>
//                   <TableCell>{drug.side_effects}</TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>

//         <Stack
//           spacing={2}
//           style={{
//             marginTop: "4vh",
//             marginLeft: "50vw",
//             marginRight: "-17vw",
//             alignItems: "center",
//           }}
//         >
//           <Pagination
//             // count={Math.ceil(filteredDrugs.length / rowsPerPage)}
//             page={page}
//             onChange={handlePageChange}
//             sx={{
//               "& .MuiPaginationItem-root": {
//                 borderRadius: "50%",
//                 marginBottom: "50px",
//                 color: "#0E044A",
//                 "&.Mui-selected": {
//                   backgroundColor: "#0E044A",
//                   color: "#fff",
//                 },
//                 "&:hover": {
//                   backgroundColor: "#0E044A20",
//                 },
//               },
//             }}
//           />
//         </Stack>
//       </Box>
//     </>
//   )
// }

// export default DrugDetails
