import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const localizer = momentLocalizer(moment);

function MyCalendar() {
    const [events, setEvents] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedCP, setSelectedCP] = useState('');
    const [selectedDepartment, setSelectedDepartment] = useState('');

    // Handles slot selection in big calendar
    const handleSelectSlot = ({ start, end }) => {
        const title = window.prompt('New Event Name');
        if (title) {
            setEvents([...events, { start, end, title }]);
        }
    };

    // Handle CP dropdown change
    const handleCPChange = (e) => {
        setSelectedCP(e.target.value);
    };

    // Handle Department dropdown change
    const handleDepartmentChange = (e) => {
        setSelectedDepartment(e.target.value);
    };

    return (
        <>
            <div className="flex justify-end">
                <span className="inline-block bg-blue-600 h-9 w-32 pt-1 rounded text-center text-white mb-1">
                    Available: 20
                </span>
            </div>

            <div className="shadow-lg bg-white rounded-lg p-4">
                {/* Select CP, Date Picker, and Department List */}
                <div className="flex flex-col sm:flex-row justify-between items-center mt-2 mb-4 space-y-2 sm:space-y-0 sm:space-x-4">
                    {/* Select CP Dropdown */}
                    <div className="w-full sm:w-auto">
                        <label className="mr-2">Select CP:</label>
                        <select
                            value={selectedCP}
                            onChange={handleCPChange}
                            className="border p-2 rounded w-full sm:w-auto"
                        >
                            <option value="">All</option>
                            <option value="CP1">CP1</option>
                            <option value="CP2">CP2</option>
                            <option value="CP3">CP3</option>
                        </select>
                    </div>

                    {/* Small Date Picker Calendar */}
                    <div className="w-full sm:w-auto">
                        <label className="mr-2">Select Date:</label>
                        <DatePicker
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            className="border p-2 rounded w-full sm:w-auto"
                        />
                    </div>

                    {/* Department List Dropdown */}
                    <div className="w-full sm:w-auto">
                        <label className="mr-2">Department:</label>
                        <select
                            value={selectedDepartment}
                            onChange={handleDepartmentChange}
                            className="border p-2 rounded w-full sm:w-auto"
                        >
                            <option value="">All</option>
                            <option value="Cardiology">Cardiology</option>
                            <option value="Neurology">Neurology</option>
                            <option value="Orthopedics">Orthopedics</option>
                            <option value="Pediatrics">Pediatrics</option>
                        </select>
                    </div>
                </div>

                {/* Responsive Big Calendar */}
                <div className="overflow-x-auto">
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 400, width: '100%' }}
                        selectable
                        onSelectSlot={handleSelectSlot}
                        onSelectEvent={(event) => alert(event.title)}
                    />
                </div>
            </div>
        </>
    );
}

export default MyCalendar;
