import React, { useState, useEffect, useRef } from "react";
import { Link, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  MenuRounded as MenuIcon,
  CloseRounded as CloseIcon,
  MoreVert as MoreVertIcon,
} from "@mui/icons-material";
import DashboardIcon from "../images/Ellipse 3.svg";
import MyOrderIcon from "../images/Ellipse 5 (1).svg";
import MyWalletIcon from "../images/Ellipse 6.svg";
import Logo from "../images/Logo.svg";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CrossIcon from "@mui/icons-material/Close";
import avatar from "../images/Avtar (1).svg";
import axios from "axios";
import { useSelector } from "react-redux";
import NDHReset from "./NDHReset";
import { BASE_URL } from "../../../utils/urlConfig"


export default function NDHMobileSidebar() {
  const navigate=useNavigate()
  const [idcard, setIdCard] = useState(false);
  const handleId = () => {
    setIdCard(!idcard);
  };

  const [setting, setSetting] = useState(false);
  const settingMenu = useRef(null);
  const handleSettingMenu = (event) => {
    if (settingMenu.current && !settingMenu.current.contains(event.target)) {
      setSetting(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleSettingMenu);
  });

  const handleSize = () => {
    setSetting(!setting);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const sidebarRef = useRef(null);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
      // setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const sidebarItems = [
    { text: "Dashboard", icon: DashboardIcon, url: "/NDHDeshbboard" },
    { text: "Orders", icon: MyOrderIcon, url: "/NDHOrder" },
    { text: "Nodal Drug House", icon: MyWalletIcon },
  ];
  const [active, setActive] = useState("/dasheclinic");
  const handleClick = (url) => {
    setActive(url);
  };

  const [currentTime, setCurrentTime] = useState(() => {
    const now = new Date();
    return `${now.toLocaleDateString()} | ${now.toLocaleTimeString()}`;
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      setCurrentTime(
        `${now.toLocaleDateString()} | ${now.toLocaleTimeString()}`
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);


  const [user, setUser] = useState([]);
  const[pic, setPic]=useState()


  const token = useSelector((state) => state.auth.token) || localStorage.getItem("token");
  useEffect(() => {
    axios
      .get(`${BASE_URL}/auth/user/data`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        const data = res.data;

        const calculateAge = (dob) => {
          const [day, month, year] = dob.split("/").map(Number);
          const birthDate = new Date(year, month - 1, day);
          const today = new Date();
          let age = today.getFullYear() - birthDate.getFullYear();
          const monthDiff = today.getMonth() - birthDate.getMonth();
  
          // Adjust age if the birthday hasn't occurred yet this year
          if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
          }
          return age;
        };
  
        setUser({
          ...data,
          age: calculateAge(data.dob), // Add calculated age
        });
          console.log()
        setPic(
          data.photo
            ? `${BASE_URL}/api/image/${
                data.photo.startsWith("/") ? data.photo.slice(1) : data.photo
              }`
            : ""
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [token]);

  
  const [reset, setReset] = useState(false);
  const [email, setEmail] = useState();
  const handleReset = () => {
    setEmail(user.email);
    setReset(true);
  };

  const HandleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <>
       <div
        className={`
      ${reset ? "blur-sm" : ""}
    `}
      >
      <div className={`bg-gray-300 flex flex-col h-screen overflow-hidden `}>
        {/* App Bar */}
        <div className="fixed top-0 left-0 w-full h-16 flex items-center px-4 bg-[#0E044A] z-30">
          <button onClick={toggleDrawer} className="text-white mr-4">
            {isOpen ? <CloseIcon /> : <MenuIcon />}
          </button>
          <h4 className="text-lg font-semibold text-white flex items-center">
            <img src={Logo} alt="Logo" className="h-6" />
          </h4>

          {/* important for setting */}

          {/* Add a flex-grow to push items to the right */}
          <div className="flex-grow"></div>
          <div className="flex flex-col items-end relative">
            <time
              dateTime={currentTime}
              className="text-white text-[10px] mb-4 md:text-sm lg:text-base underline decoration-1 decoration-gray-500"
            >
              {currentTime}
            </time>
            <div className="relative">
              <button
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="text-white flex items-center"
              >
                <MoreVertIcon />
              </button>
              {dropdownOpen && (
                <div
                  className={` absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-10 `}
                >
                  <ul className="list-none p-2">
                    <li
                      className="px-4 py-2 hover:bg-gray-200 cursor-pointer flex items-center space-x-2"
                      onClick={handleId}
                    >
                      <span className="text-gray-600">
                        <AccountCircleIcon />
                        Profile
                      </span>
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-200 cursor-pointer flex items-center space-x-2"
                      onClick={handleSize}
                    >
                      <span className="text-gray-600">
                        <SettingsIcon />
                        Settings
                      </span>
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer flex items-center space-x-2">
                      <span className="text-gray-600">
                        <NotificationsIcon />
                        Notifications
                      </span>
                    </li>
                    <CrossIcon
                      className="absolute top-2 right-2"
                      onClick={() => setDropdownOpen(!dropdownOpen)}
                    />
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-1 overflow-hidden">
          {/* Drawer */}
          <div
            ref={sidebarRef}
            className={`z-20 fixed top-0 left-0 h-full text-white transition-transform duration-300 ${
              isOpen ? "w-52" : "w-16"
            } ${isOpen ? "translate-x-0" : "-translate-x-full"} lg:w-64`}
            style={{ background: "#0E044A" }}
          >
            <div className="flex flex-col h-full">
              {/* Drawer Header */}
              <div className="h-16 flex items-center justify-center px-4">
                <h1 className="text-lg font-semibold text-white">
                  <img src={Logo} alt="Logo" className="h-8" />
                </h1>
              </div>

              {/* Drawer Content */}
              <div className="flex flex-col flex-1">
                <ul className="flex flex-col mt-8 space-y-2 font-sans font-medium">
                  {sidebarItems.map((item, index) => (
                    <li key={index} className="group cursor-pointer">
                      <div
                        className="flex items-center space-x-2 px-4 py-2 group-hover:bg-gray-700 hover:rounded-full transition-colors"
                        onClick={() => handleClick(item.url)}
                      >
                        <img
                          src={item.icon}
                          alt={item.text}
                          className="h-5 w-5"
                        />
                        <span
                          className={`transition-opacity duration-300 ${
                            isOpen ? "opacity-100" : "opacity-0"
                          } whitespace-nowrap`}
                        >
                          {/* {item.text} */}
                          <Link to={`${item.url}`}>{item.text}</Link>
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>

                {/* Footer */}
                <footer className="bg-[#0E044A] text-white text-xs py-4 mt-auto">
                  <div className="text-center">
                    {/* Available On */}
                    <div className="mb-1">Available On</div>
                    <div className="mb-1 flex justify-center flex-wrap space-x-4">
                      <a
                        href="https://play.google.com/store"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center space-x-1"
                      >
                        <i className="fab fa-google-play fa-2x"></i>
                        <span className="text-md p-1">Playstore</span>
                      </a>
                      <a
                        href="https://apps.apple.com/app"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center space-x-1"
                      >
                        <i className="fab fa-app-store fa-2x"></i>
                        <span className="text-md p-1">Appstore</span>
                      </a>
                    </div>

                    {/* Footer text */}
                    <div className="mb-1">Made with ❤️ in India</div>
                    <div>Copyright &copy; 2024 Astha</div>
                  </div>
                </footer>
              </div>
            </div>
          </div>

          {/* Content Area */}
          <div
            className={`bg-gray-300 transition-all duration-300 ${
              isOpen ? "ml-24" : "ml-2"
            } lg:ml-64`}
          >
            <div className="h-full pt-20 w-screen md:px-8 lg:px-16 overflow-auto">
              {/* Replace the content here as needed */}
              {/* <Dashboard /> */}
              {/* <MyConsultation /> */}
              {/* <PatientDashboard/> */}
              {/* <MyOrders /> */}
              <Outlet />
            </div>
          </div>
        </div>
      </div>

      {/* setting card */}
      <div
        ref={settingMenu}
        className={`  border border-b-gray-400  absolute top-72 right-20 bg-gray-100 w-[175px] h-[265px] rounded-lg shadow-sm shadow-red-900	
      ${setting ? "block  " : "hidden"} `}
      >
        <ul className="grid grid-rows text-center leading-9	text-[#0E044A] font-bold text-md">
          <li className="underline decoration-1 decoration-slate-400">
            About Us
          </li>
          <li className="underline decoration-1 decoration-slate-400"
                        onClick={handleReset}

          >
            Reset Password
          </li>
          <li className="underline decoration-1 decoration-slate-400">
            Terms & Conditions
          </li>
          <li className="underline decoration-1 decoration-slate-400">
            Privacy Policy
          </li>
          <li className="underline decoration-1 decoration-slate-400">
            Check For Update
          </li>
          <li className="underline decoration-1 decoration-slate-400">
            Send Feedback
          </li>
          <li className="underline decoration-1 decoration-slate-400" 
          onClick={HandleLogout}
          >
            Log Out
          </li>
        </ul>
      </div>

      <div
        className={` 320px:text-[13px] 500px:text-sm   hue-rotate-15 drop-shadow-md  fixed top-64 border border-gray-400 	 shadow-green-900  shadow-sm right-12 grid grid-cols-2 w-auto h-[160px] bg-white justify-center items-center rounded-xl 
          
       ${idcard ? "block" : "hidden"} }`}
      >
        <div>
          <img
            src={pic || avatar}
            alt=""
            className="ml-6 contrast-200 w-[130px]"          />
        </div>
        <div className="relative left-[-20px] ">
          <div className="font-semibold">
            <span>ID : </span>
            <span>{user.id}</span>
          </div>
          <div>            {user.first_name} {user.middle_name} {user.last_name}
          </div>
          <div>{user.gender} | {user.age}</div>
          <div>contacts:</div>
          <div className="font-medium text-gray-500">{user.mobile}</div>
          <div className="font-display text-gray-600 font-medium text-wrap">
          {user.email}
          </div>
          <CrossIcon
            className={`absolute top-1 right-[-6px]`}
            onClick={handleId}
          />
        </div>
      </div>

      </div>
      {reset && (
        <div>
          <NDHReset setReset={setReset} email={email} />
        </div>
      )}
    </>
  );
}
