import * as React from "react"
import { useState, useEffect } from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import { errorToast, successToast } from "../../../utils/toastConfig"
import axios from "axios"
import { BASE_URL } from "../../../utils/urlConfig"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  height: 410,
  bgcolor: "background.paper",
  borderRadius: "16px",
  boxShadow: 24,
  p: 4,
}

const group = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  input: {
    color: "#777",
    minWidth:"55%",
    height:"100%",
    backgroundColor: "#f5f5f5",
    border: "1px solid #f5f5f5",
    borderRadius: "5px",
  },
  label: {
    color: "#454545",
  },
  select: {
    minWidth:"55%",
    height:"100%",
    color: "#777",
    backgroundColor: "#f5f5f5",
    border: "1px solid #f5f5f5",
    borderRadius: "5px",
    overflowY: "hidden",
    maxHeight:"150px",
    display:"inline-block",
  },
  option:{
    maxHeight:"100px",  
    boxSizing:"border-box",
  }
}

export default function ZoneModal({onZoneUpdate}) {
  const [open, setOpen] = useState(false)
  const [zoneName, setZoneName] = useState("")
  const [selectedStateId, setSelectedStateId] = useState("") // To store selected state_id
  const [district, setDistrict] = useState("")
  const [zipcode, setZipcode] = useState("")
  const [states, setStates] = useState([]) // To store all states from API
  

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  // Fetch states from API
  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/states`)
        setStates(response.data)
      } catch (error) {
        console.error("Error fetching states:", error)
      }
    }

    fetchStates()
  }, [])

  const handleSubmit = async () => {
    // Validate form inputs
    if (!zoneName || !selectedStateId || !district || !zipcode) {
      errorToast("Please fill all the required fields.")
      return
    }
    else {

    // Convert selectedStateId to a number
    const stateIdNumber = Number(selectedStateId)

    // Check if the conversion was successful
    if (isNaN(stateIdNumber)) {
      console.error("Invalid stateId. It must be a number.")
      return
    }
    

    const zoneData = {
      zone_name: zoneName,
      state_id: stateIdNumber, // Use the converted number here
      district,
      zipcode,
    }

    console.log("Submitting zone data:", zoneData) // Debugging line

    try {
      const response = await axios.post(
        `${BASE_URL}/api/zones`,
        zoneData

       
      )
      
      successToast("Zone saved successfully:", response.data)
      
       // After successful update
       if (onZoneUpdate) {
        onZoneUpdate() // Trigger the refresh in ZoneDetails
      }
      handleClose() // Close modal after success
    } catch (error) {
      console.error(
        "Error saving zone data:",
        error.response ? error.response.data : error.message
      )
    }

    
  }}

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        sx={{
          backgroundColor: "#6bc6b0",
          "&:hover": {
            backgroundColor: "#6bc6b0",
          },
          minWidth: "171px",
          height: "50px",
          fontWeight: "550",
        }}
        onClick={handleOpen}
      >
        + Add Zone
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
          >
            Add Zone
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            <Box
              sx={{
                marginTop: "40px",
                display: "grid",
                gridTemplateColumns: "100%",
                rowGap: 4,
              }}
            >
              <div style={group}>
                <label
                  style={group.label}
                  htmlFor="state"
                >
                  State
                </label>
                <select
                  style={group.select}
                  value={selectedStateId}
                  onChange={(e) => setSelectedStateId(e.target.value)}
                >
                  <option value="">Select State</option>
                  {states.map((state) => (
                    <option
                    style={group.option}
                      key={state.id}
                      value={state.id}
                    >
                      {state.state}
                    </option>
                  ))}
                </select>
              </div>
              <div style={group}>
                <label
                  style={group.label}
                  htmlFor="district"
                >
                  District
                </label>
                <input
                  style={group.input}
                  type="text"
                  placeholder="District"
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                />
              </div>
              <div style={group}>
                <label
                  style={group.label}
                  htmlFor="zipcode"
                >
                  Zip Code
                </label>
                <input
                  style={group.input}
                  type="text"
                  placeholder="Zipcode"
                  value={zipcode}
                  onChange={(e) => setZipcode(e.target.value)}
                />
              </div>
              <div style={group}>
                <label
                  style={group.label}
                  htmlFor="zoneName"
                >
                  Zone Name
                </label>
                <input
                  style={group.input}
                  type="text"
                  placeholder="Zone Name"
                  value={zoneName}
                  onChange={(e) => setZoneName(e.target.value)}
                />
              </div>
            </Box>
            <Box
              sx={{
                width: "190px",
                display: "flex",
                margin: "15% 35%",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                sx={{ borderRadius: "50px", backgroundColor: "#3F6584" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  width: "95px",
                  fontWeight: "550",
                  borderRadius: "50px",
                  backgroundColor: "#a9fcf7",
                  color: "#454545",
                }}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Box>
          </Typography>
        </Box>
      </Modal>
    </div>
  )
}