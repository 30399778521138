
import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../../layout/Sidebar";
import ZoneModal from "./ZoneModal";
import EditZoneModal from "./EditZoneModal";
import editButton from "../../../assets/Astha web app/edit.svg";
import trashButton from "../../../assets/Astha web app/trash-2.svg";
import { BASE_URL } from "../../../utils/urlConfig"

import {
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  TextField,
  Box,
} from "@mui/material";

const ZoneDetails = () => {
  const [zones, setZones] = useState([]);
  const [filterState, setFilterState] = useState("");
  const [filterDistrict, setFilterDistrict] = useState("");
  const [filterStatus, setFilterStatus] = useState(""); // For isLocked
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [selectedZone, setSelectedZone] = useState(null);
  const [openModal, setOpenModal] = useState(false); // State for modal visibility

  // Fetching zone data from the API
  const fetchZones = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/zones`);
      setZones(response.data);
    } catch (error) {
      console.error("Error fetching zone data", error);
    }
  };

  useEffect(() => {
    fetchZones();
  }, []);

  // Handle refresh after data is updated in ZoneModal
  const handleZoneUpdate = () => {
    fetchZones();
  };

  // Filtering zones based on search, state, district, and isLocked status
  const filteredZones = zones.filter(
    (zone) =>
      (filterState === "" || zone.state_name === filterState) &&
      (filterDistrict === "" || zone.district === filterDistrict) &&
      (filterStatus === "" || zone.isLocked === (filterStatus === "Locked")) &&
      (searchQuery === "" ||
        zone.zone_name.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const indexOfLastZone = page * rowsPerPage;
  const indexOfFirstZone = indexOfLastZone - rowsPerPage;
  const currentZones = filteredZones.slice(indexOfFirstZone, indexOfLastZone);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const uniqueStates = {};
  zones.forEach((zone) => {
    uniqueStates[zone.state_name] = true; // Store unique states as keys
  });

  const uniqueDistricts = {};
  zones.forEach((zone) => {
    uniqueDistricts[zone.district] = true; // Store unique districts as keys
  });

  const handleRowClick = (zone) => {
    setSelectedZone(zone); // Set the selected drug data
    setOpenModal(true); // Open the modal
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal
    setSelectedZone(null); // Clear the selected zone
  };


  return (
    <>
      <div>
        <Sidebar />
      </div>
      <Box sx={{ padding: "0 2%", margin: "0 20%" }}>
        <Box
          sx={{
            fontWeight: "600",
            color: "#454545",
            fontSize: "larger",
            marginLeft: "-2%",
          }}
        >
          Zone Details
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "3%",
            marginBottom: "20px",
            "& .MuiOutlinedInput-input": {
              backgroundColor: "#f5f5f5",
              height: "16px",
              fontWeight: "medium",
            },
            "& .MuiSelect-select ": {
              padding: "14px 32px 14px 14px",
            },
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ minWidth: 255, marginRight: 2 }}
          />

          <FormControl
            variant="outlined"
            sx={{ minWidth: 200, marginRight: 2 }}
          >
            <InputLabel>State</InputLabel>
            <Select
              value={filterState}
              onChange={(e) => setFilterState(e.target.value)}
              label="State"
            >
              <MenuItem value="">All States</MenuItem>
              {Object.keys(uniqueStates).map((stateName) => (
                <MenuItem key={stateName} value={stateName}>
                  {stateName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* District Filter */}
          <FormControl
            variant="outlined"
            sx={{ minWidth: 150, marginRight: 2 }}
          >
            <InputLabel>District</InputLabel>
            <Select
              value={filterDistrict}
              onChange={(e) => setFilterDistrict(e.target.value)}
              label="District"
            >
              <MenuItem value="">All Districts</MenuItem>
              {Object.keys(uniqueDistricts).map((district) => (
                <MenuItem key={district} value={district}>
                  {district}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Status Filter (isLocked) */}
          <FormControl
            variant="outlined"
            sx={{ minWidth: 115, marginRight: 2 }}
          >
            <InputLabel>Status</InputLabel>
            <Select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              label="Status"
            >
              <MenuItem value="">All Status</MenuItem>
              <MenuItem value="Locked">Locked</MenuItem>
              <MenuItem value="Unlocked">Unlocked</MenuItem>
            </Select>
          </FormControl>

          {/* Pass handleZoneUpdate to ZoneModal */}
          <ZoneModal onZoneUpdate={handleZoneUpdate} />
        </Box>

        <TableContainer
          component={Paper}
          sx={{ marginTop: "20px", minWidth: "70vw" }}
        >
          <Table>
            <TableHead
              sx={{
                border: "1px",
                backgroundColor: "#5A79C9",
                color: "rgba(11 96 96)",
              }}
            >
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    color: "#ffffff",
                    fontWeight: "550",
                  },
                }}
              >
                <TableCell>ID</TableCell>
                <TableCell>Zone Name</TableCell>
                <TableCell>Zip Code</TableCell>
                <TableCell>State</TableCell>
                <TableCell>District</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Edit/Stop</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentZones.map((zone) => (
                <TableRow key={zone.zone_id} sx={{cursor:"pointer"}}>
                  <TableCell onClick={()=> handleRowClick(zone)} >{zone.zone_id}</TableCell>
                  <TableCell onClick={()=> handleRowClick(zone)} >{zone.zone_name}</TableCell>
                  <TableCell onClick={()=> handleRowClick(zone)} >{zone.zipcode}</TableCell>
                  <TableCell onClick={()=> handleRowClick(zone)} >{zone.state_name}</TableCell>
                  <TableCell onClick={()=> handleRowClick(zone)} >{zone.district}</TableCell>
                  <TableCell onClick={()=> handleRowClick(zone)} >{zone.isLocked ? "Locked" : "Unlocked"}</TableCell>
                  <TableCell   sx={{ paddingLeft: "2%" ,display:"flex" }}>
                    <img
                      src={editButton}
                      alt="Edit"
                      onClick={()=> handleRowClick(zone)}
                      style={{
                        cursor: "pointer",
                        minHeight: "15px",
                        marginLeft: "5px",
                        marginRight: "20px",
                      }}
                    />
                    <img
                      src={trashButton}
                      alt="Delete"
                      style={{ cursor: "pointer", minHeight: "15px" }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack
          spacing={2}
          style={{
            marginTop: "4vh",
            marginLeft: "50vw",
            marginRight: "-17vw",
            alignItems: "center",
          }}
        >
          <Pagination
            count={Math.ceil(filteredZones.length / rowsPerPage)}
            page={page}
            onChange={handlePageChange}
            sx={{
              "& .MuiPaginationItem-root": {
                borderRadius: "50%",
                marginBottom: "50px",
                color: "#0E044A",
                "&.Mui-selected": {
                  backgroundColor: "#0E044A",
                  color: "#fff",
                },
                "&:hover": {
                  backgroundColor: "#0E044A20",
                },
              },
            }}
          />
        </Stack>
      </Box>
      {selectedZone && (
        <EditZoneModal
          open={openModal}
          onClose={handleCloseModal}
          zone={selectedZone} // Pass the selected zone
          onZoneUpdate={handleZoneUpdate}
        />
      )}
    </>
  );
};

export default ZoneDetails;

