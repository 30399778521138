import React, { useState } from "react";
import { TextField, Button, Box } from "@mui/material";
import logo from "./../../assets/images/astha-brand-logo.png";
import "./LoginForm.css"; // Reusing the same styles as LoginForm
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { errorToast, successToast } from "../../utils/toastConfig";
import { BASE_URL } from "../../utils/urlConfig"

import EnterOtpForm from "./EnterOtpForm";
import ChangePasswordForm from "./ChangePasswordForm";

const ForgetPasswordForm = () => {
  const [showotp, setShowotp] = useState(false);
  const [showpassword, setPassword] = useState(false);
  const [otp, setOtp]=useState()

  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const jsonData = {
      email: email,
    };

    try {
      await axios.post(
        `${BASE_URL}/auth/forgetpassword`,
        jsonData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      successToast("Send OTP on your email!");
      setShowotp(true)
    } catch (error) {
      console.error(error);
      errorToast("Enter the right email!");
    }
  };
  const handlelogin = () => {
    navigate("/login");
  };
  return (
    <>
    <div
        className={`
          ${showotp ? "hidden" : ""}
          ${showpassword ? "	hidden" : ""}
        
        `}
      >

      <Box className="login-container">
        <Box className="login-form">
          <div className="logo">
            <img src={logo} alt="astha-logo" />
          </div>
          <form onSubmit={handleSubmit}>
            <TextField
              className="textarea"
              placeholder="Enter your email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              className="login-button"
            >
              Send OTP
            </Button>
            <Button className="back-to-login" onClick={handlelogin}>
              Back to Login
            </Button>
          </form>
        </Box>
      </Box>
              </div>

      {showotp && (
        <div>
          <EnterOtpForm setOtp={setOtp} setShowotp={setShowotp} setPassword={setPassword} />
        </div>
      )}
      {showpassword && (
        <div>
          <ChangePasswordForm otp={otp} email={email}/>
        </div>
      )}
    </>
  );
};

export default ForgetPasswordForm;
